/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid
} from '@material-ui/core'
import WarningIcon from '@mui/icons-material/Warning'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import { useSelector } from 'react-redux'
import axios from 'axios'

import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import Table from '../../components/Table/Table'
import styles from './MHI_PMT.module.css'
import { CustomFormGroup, CustomSelect } from '../../components/FormComponents'
import BackButton from '../../components/BackButton/BackButton'
import { MESSAGE_TYPE, RESOURCE_TYPE } from '../../constants'
import { withAllowedOperationsProvider } from '../../hocs'
import { CustomTab, Button, AuthChecker } from '../../atomicComponents'
import { usePopupManager } from '../../providers/PopupManager/PopupManager'
import { GridLoadingSpinner } from '../../components'

const MHIDataFormation = () => {
  const history = useHistory()
  const { showPopup } = usePopupManager()
  const baseUrl = useSelector(state => state.counter.baseUrl)
  const auth = useSelector(state => state.auth)
  const [flag, show] = useState(false)
  const [projectCodeArray, setProjectCodeArray] = useState([])
  const [isLatestProjectSelected, setIsLatestProjectSelected] = useState(false)
  const [selectedProjectCode, setSelectedProjectCode] = useState(null)
  const [latestProject, setLatestProject] = useState('')
  const [tableArray, setTableArray] = useState([])
  const [asnCount, setAsnCount] = useState(0)
  const [loading, setLoader] = useState(false)
  const [loader, setLoading] = useState(true)

  const [projectDescription, setProjectDescription] = useState('')
  const [selectedTableRows, setSelectedTableRows] = useState([])

  //
  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false)
  const handleOpenFormModal = () => {
    setPopupOpenFormModal(true)
  }
  const handleCloseFormModal = () => setPopupOpenFormModal(false)
  // const [isPopupOpen, setIsPopupOpen] = useState(false)

  const redirectToApplicationLandingPage = () => {
    history.push('/')
  }

  const setCount = count => {
    setAsnCount(count)
  }

  /* Checkbox changeHandler inside Table */
  const handleCheckboxChange = row => {
    setSelectedTableRows(prevSelected => {
      const exists = prevSelected.some(
        selectedRow =>
          selectedRow.Project_code === row.Project_code &&
          selectedRow.VC === row.VC
      )

      if (exists) {
        // Remove row from selected rows
        return prevSelected.filter(
          selectedRow =>
            selectedRow.Project_code !== row.Project_code ||
            selectedRow.VC !== row.VC
        )
      }
      // else {
      // Add row to selected rows
      return [...prevSelected, row]
      // }
    })
  }

  console.log('Checked value: ', selectedTableRows)

  const getRecentProjectCode = async projectCodeOptions => {
    try {
      const { data, status } = await axios.get(
        `${baseUrl}LatestProjectCodeforProductline/`
      )
      if (status !== 200) {
        throw new Error('Failed to fetch the latest project code')
      }
      const recentProj = {
        label: data?.Project_code,
        value: data?.Project_code
      }
      setLatestProject(recentProj)
      setSelectedProjectCode(recentProj) // latest project code
    } catch (error) {
      console.error('Error fetching recent project codes:', error)
    }
  }

  const getProjectDescription = async () => {
    try {
      const headerss = { 'Content-Type': 'application/json' }
      const body = { Project_code: selectedProjectCode?.value }
      const { data } = await axios.post(
        `${baseUrl}ProjectDescriptionView/`,
        body,
        { headers: headerss }
      )
      // console.log('pro desc: ', data)
      setProjectDescription(data?.Project_Description)
    } catch (error) {
      console.error('Error fetching project description:', error)
    }
  }

  useEffect(() => {
    getAllProjectCode()
    getRecentProjectCode()
  }, [])

  useEffect(() => {
    if (latestProject) {
      setSelectedProjectCode(latestProject)
    }
  }, [latestProject])

  // Check if the selected project code is the latest project code
  useEffect(() => {
    if (selectedProjectCode && latestProject) {
      setIsLatestProjectSelected(
        selectedProjectCode.value === latestProject.value
      )
    }
  }, [selectedProjectCode, latestProject])

  useEffect(() => {
    if (selectedProjectCode) {
      // getVcForProjectCode(selectedProjectCode)
      getProjectDescription()
    }
  }, [selectedProjectCode])

  const getVcForProjectCode = async selectedCode => {
    const headerss = {
      'Content-Type': 'application/json'
    }

    const apiBody = {
      // project_code: selectedProjectCode?.value || selectedCode?.value,
      Project_code: selectedProjectCode?.value || selectedCode?.value
      // Project_code: 'PC_001',
      // VC: '1234567'
      // spoc: preferredUsername
    }

    try {
      const response = await axios.post(
        // `${baseUrl}GetVCsByProjectAndSPOC/`,
        `${baseUrl}GetVCsByProject/`,
        apiBody,
        { headers: headerss }
      )

      if (response.status >= 200 && response.status < 300) {
        const resArray = response.data?.map(value => ({ value, label: value }))
        const vcArray = response.data?.map(value => ({
          Project_code: selectedProjectCode?.value,
          VC: value
        }))
        console.log('vcArray: ', vcArray)
        setTableArray(vcArray)
        console.log('resArray: ', resArray)
        setLoading(false)
        // setBcArray(resArray)
        setSelectedTableRows([])
      } else {
        console.error(
          `Error fetching VC: ${response.status} - ${response.statusText}`
        )
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Failed to fetch data. Error: ${response.status} - ${response.statusText}`
        })
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error(
          `Error fetching VC: ${error.response.status} - ${error.response.data}`
        )
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Failed to fetch data. Error: ${error.response.status} - ${error.response.data}`
        })
      } else if (error.request) {
        // Request was made but no response was received
        console.error('Error fetching VC: No response received', error.request)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Failed to fetch data. No response received from the server.'
        })
      } else {
        // Something else happened in setting up the request
        console.error('Error fetching VC:', error.message)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Failed to fetch data. Error: ${error.message}`
        })
      }
    }
  }

  const submitMHIDataPMT = async () => {
    const headerss = {
      'Content-Type': 'application/json'
    }
    const apiBody = selectedTableRows

    try {
      const response = await axios.post(`${baseUrl}SyncCK13ToMHI/`, apiBody, {
        headers: headerss
      })

      if (response.status >= 200 && response.status < 300) {
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: `VCs submitted successfully!`
        })
      } else {
        console.error(
          `Error fetching VC: ${response.status} - ${response.statusText}`
        )
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Failed to fetch data. Error: ${response.status} - ${response.statusText}`
        })
      }
    } catch (error) {
      if (error.response) {
        console.error(
          `Error fetching VC: ${error.response.status} - ${error.response.data}`
        )
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Failed to fetch data. Error: ${error.response.status} - ${error.response.data}`
        })
      } else if (error.request) {
        console.error('Error fetching VC: No response received', error.request)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Failed to fetch data. No response received from the server.'
        })
      } else {
        console.error('Error fetching VC:', error.message)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Failed to fetch data. Error: ${error.message}`
        })
      }
    }
  }

  const getAllProjectCode = async () => {
    try {
      // const { data } = await axios.get(`${baseUrl}AllProjectCodeMHIData/`);
      const { data } = await axios.get(
        `${baseUrl}AllProjectCodeforProductline/`
      )
      const allProjectCodes = data.map(({ Project_code }) => Project_code)
      const uniqueProjects = [...new Set(allProjectCodes)]
      const projectCodeOptions = uniqueProjects.map(value => ({
        value,
        label: value
      }))

      setProjectCodeArray(projectCodeOptions)
      return projectCodeOptions
    } catch (error) {
      console.error('Error fetching project codes:', error)
      return []
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await getAllProjectCode()
    }

    fetchData()
  }, [])

  const showTable = () => {
    if (selectedProjectCode === null || selectedProjectCode.value === '') {
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: 'Project Code is Mandatory'
      })
    } else {
      setLoading(true)
      setLoader(true)
      getVcForProjectCode()
      show(true)
      setLoader(false)
    }
  }

  const exportExcel = async excelData => {
    var fileTypel =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    var fileExt = '.xlsx'
    const ws = XLSX.utils.json_to_sheet(excelData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileTypel })
    FileSaver.saveAs(data, `MHI_Data${fileExt}`)
  }

  const baseDefaultColumns = [
    {
      width: 160,
      title: 'Project Code',
      field: 'Project_code',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 160,
    //   title: 'Project Type',
    //   field: 'Project_type',
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 130,
    //   title: 'Product Id',
    //   field: 'Product_Id',
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 130,
    //   title: 'Model',
    //   field: 'Model',
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 130,
    //   title: 'Financial Year',
    //   field: 'Financial_year',
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 130,
    //   title: 'Quarter',
    //   field: 'Quarter',
    //   enableSearch: true,
    //   enableFilter: true,
    // }, {
    //   width: 180,
    //   title: 'TML CIN No',
    //   field: 'TML_CIN_No',
    //   enableSearch: true,
    //   enableFilter: true,
    // }, {
    //   width: 180,
    //   title: 'From Date',
    //   field: 'From_Date',
    //   enableSearch: true,
    //   enableFilter: true,
    // }, {
    //   width: 180,
    //   title: 'To Date',
    //   field: 'To_Date',
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 180,
    //   title: 'Manufacturer BU CIN No',
    //   field: 'Manufacturer_BU_CIN_No',
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 130,
    //   title: 'Quantity',
    //   field: 'Quantity',
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 130,
      title: 'VC',
      field: 'VC',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 180,
    //   title: 'VC Description',
    //   field: 'VC_description',
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 130,
      title: 'Select VC',
      field: '',
      render: row => (
        <input
          type='checkbox'
          checked={row?.selected}
          // checked={selectedTableRows.some(
          //   selectedRow =>
          //     selectedRow.Project_code === row.Project_code &&
          //     selectedRow.VC === row.VC
          // )}
          onChange={() => handleCheckboxChange(row)}
        />
      ),
      enableSearch: true,
      enableFilter: true
    }
    // {
    //   width: 180,
    //   title: 'DVA Percentage',
    //   field: 'DVA_Percentage',
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 130,
    //   title: 'Ex Factory Cost',
    //   field: 'Ex_Factory_Cost',
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 130,
    //   title: 'CK13 Cost',
    //   field: 'CK_13_Cost',
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 180,
    //   title: 'Batch',
    //   field: 'Batch',
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 130,
    //   title: 'Unit of Quantity',
    //   field: 'unit_of_quantity',
    //   enableSearch: true,
    //   enableFilter: true,
    // },
  ]

  const renderHTML = () => (
    <div className={styles.formGroup}>
      {loading && (
        <div className={styles.gridLoader}>
          <GridLoadingSpinner />
        </div>
      )}
      <Grid
        container
        spacing={2}
        style={{ display: 'flex', justifyContent: 'left' }}
        columns={12}
      >
        <Grid item md={4} lg={4}>
          <div
            className={styles.rowDiv}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div
              className={clsx(styles.lableDiv)}
              style={{ width: '40%', display: 'flex', alignItems: 'center' }}
            >
              <label className={styles.label}>Project Code</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name='Project Code'
                options={projectCodeArray}
                className={clsx(styles.select, styles.sel1)}
                value={selectedProjectCode}
                isMulti={false}
                defaultValue={latestProject}
                isClearable
                isMandatory
                onChange={selectedOption => {
                  setSelectedTableRows([])
                  setSelectedProjectCode(selectedOption)
                  show(false)
                }}
              />
            </div>
          </div>
        </Grid>

        <Grid item md={4} lg={4}>
          <div
            style={{
              marginLeft: '5px',
              paddingRight: '10px',
              display: 'flex',
              justifyContent: 'left'
            }}
          >
            <AuthChecker operation='search'>
              {isAuthorized => (
                <Button
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  style={{ marginRight: '12px !important' }}
                  variant='primary'
                  onClick={showTable}
                  disabled={!isAuthorized}
                  data-testid='confirm-action'
                >
                  Show Data
                </Button>
              )}
            </AuthChecker>
          </div>
        </Grid>
      </Grid>
    </div>
  )

  const secondaryActions = [
    {
      name: 'Download',
      authOperation: 'download',
      shouldEnable: selected => true,
      actionFn: selected => {
        exportExcel(tableArray)
      },
      customClass: ''
    }
  ]

  return (
    <>
      <div className={styles.container} style={{ boxSizing: 'border-box' }}>
        <div>
          <div
            className={styles.backButtonLastRefreshedDateWrapper}
            style={{ marginTop: '10px' }}
          >
            <BackButton
              action='Back'
              handleClick={redirectToApplicationLandingPage}
              style={{ marginBottom: '0px' }}
            />
            <div
              style={{ width: '100%' }}
              // style={{ display: 'flex', flexDirection: 'row-reverse' }}
            >
              <div className={styles.topDiv}>
                <div
                  className={styles.processHeader}
                  style={{ display: 'flex', height: '3em' }}
                >
                  <div style={{ width: '100%', cursor: 'none' }}>
                    <CustomTab
                      title='MHI PMT Data'
                      withOutCount
                      isSelected
                      style={{ cursor: 'default' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup body={renderHTML()} />
          </div>
        </div>
        {flag && (
          <div
            className={styles.tableView}
            style={{ height: '86%', padding: '0 2em' }}
          >
            <Table
              columns={
                !auth.userRole.includes('Vendor')
                  ? [...baseDefaultColumns]
                  : [...baseDefaultColumns]
              }
              defaultColumns={[...baseDefaultColumns]}
              rowHeight={38}
              rows={tableArray}
              primaryAction={{
                name: 'Submit Data',
                authOperation: 'submit',
                shouldEnable: selected => true,
                actionFn: handleOpenFormModal
              }}
              secondaryActions={secondaryActions}
              setAsnCount={setCount}
              isDataLoading={loader}
              actionButtons
              onRowSelect={e => {
                console.log('e in table: ', e)
              }}
              removeRowSelection
            />
          </div>
        )}
      </div>

      <ResetModalF
        isPopupOpen={isPopupOpenFormModal}
        handleCloseFormModal={handleCloseFormModal}
        submitMHIDataPMT={submitMHIDataPMT}
      />
    </>
  )
}
function ResetModalF ({ isPopupOpen, handleCloseFormModal, submitMHIDataPMT }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm Submission
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>
              On confirmation, selected VC will be submitted for current project
              code.
            </p>

            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleCloseFormModal}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              handleCloseFormModal()
              submitMHIDataPMT()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

ResetModalF.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleCloseFormModal: PropTypes.func.isRequired,
  submitMHIDataPMT: PropTypes.func.isRequired
}

export default withAllowedOperationsProvider(
  MHIDataFormation,
  RESOURCE_TYPE.PROJECT
)
