/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Form,
  Box,
  TextField,
  Grid,
  FormGroup,
  IconButton,
} from "@material-ui/core";
import WarningIcon from "@mui/icons-material/Warning";
import FormLabel from "@mui/material/FormLabel";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
// for print user in consol
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";
import axios from "axios";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import Table from "../../components/Table/Table";
import styles from "./DVADeclarationMIS.module.css";
import { CustomFormGroup, CustomSelect, ValidatingTextField } from "../../components/FormComponents";
import BackButton from "../../components/BackButton/BackButton";
import {
  DATE_FORMAT,
  API_RESOURCE_URLS,
  DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  MESSAGE_TYPE_CERTIFICATION,
  MESSAGE_TYPE_ANNEXURE,
  RESOURCE_TYPE,
  USER_OPERATIONS,
  Regex,
} from "../../constants";
import { withAllowedOperationsProvider } from "../../hocs";
import { CustomTab, Button, AuthChecker } from "../../atomicComponents";
import { API } from "../../apis/api";
import { buildErrorMessage } from "../../apis/calls";
import { usePopupManager } from "../../providers/PopupManager/PopupManager";

import StepBar1 from "../../components/StepBar1/StepBar";

const DvaMis = () => {
  const history = useHistory();
  const { showPopup } = usePopupManager();
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector((state) => state.counter.baseUrl); // useSelector
  const auth = useSelector((state) => state.auth); // useSelector
  const { userRole, userID, preferredUsername, userName } = auth
  const [flag, show] = useState(false);
  // const [checkDisable, setDisable] = useState(false);
  const [projectCode, setProjectCode] = useState(null);
  const [projectCodeArray, setProjectCodeArray] = useState([])
  const [isLatestProjectSelected, setIsLatestProjectSelected] = useState(false) // New state for button enable/disable
  // const [projectCodeArray, setProjectCodeArray] = useState([]);
  const [selectedProjectCode, setSelectedProjectCode] = useState(null)
  const [latestProject, setLatestProject] = useState("")
  const [Bc, setBC] = useState(null);
  const [bcArray, setBcArray] = useState([])
  const [tableArray, setTableArray] = useState([])
  const [asnCount, setAsnCount] = useState(0)
  const [loading, setLoader] = useState(false)
  const [loader, setLoading] = useState(true)
  const [vcStatus, setVcStatus] = useState()
  const [projectDescription, setProjectDescription] = useState('')
  //
  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false)
  const handleOpenFormModal = () => {
    setPopupOpenFormModal(true);
  };
  const handleCloseFormModal = () => setPopupOpenFormModal(false);

  //

  // const ROW_HEIGHT = 38;

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleOpen = () => setIsPopupOpen(true);
  const handleClose = () => setIsPopupOpen(false);
  const goNext = () => {
    uploadFileData();
  };

  const redirectToApplicationLandingPage = () => {
    history.push("/");
  };

  const setCount = (count) => {
    setAsnCount(count);
  };

  useEffect(() => {
    getRecentProjectCode()
    getAllProjectCode()
  }, [])

  const isAdminUser = userRole?.includes('admin')
  const isGDCUser = userRole?.includes('TMLBSL')
  const isPurchaseUser = userRole?.includes('Purchase')
  const isVendorUser = userRole?.includes('Vendor')
  const isPMTUser = userRole?.includes('PMT')
  const isProductLineUser = userRole?.includes('Product-line')
  const isBOEUser = userRole?.includes('BOE')

  function submitButtonFlag() {
    let buttonFlag
    if (vcStatus?.ProductLine_status === 'Submitted' && isLatestProjectSelected) {
      buttonFlag = false
    }
    else if (vcStatus?.ProductLine_status !== 'Submitted' && isLatestProjectSelected) {
      buttonFlag = true
    }
    return buttonFlag
  }


  const handleDropdownChange = (selectedOption, name) => {
    // console.log('selectedOption: ', selectedOption)
    if (name === 'Project_Code') {
      setSelectedProjectCode(selectedOption)
      getProjectDescription()
       }
    }



  useEffect(() => {
    if (selectedProjectCode) {
      getVcForProjectCode(selectedProjectCode)
      getProjectDescription()
    }
  }, [selectedProjectCode]);


  const showTable = () => {
    if (
      selectedProjectCode === null ||
      selectedProjectCode.value === "" ||
      selectedProjectCode.value === null
    ) {
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: "Project Code is Mandatory",
      });
    } 
    else {
      getProjectDescription();
      getTableData();
    //   getPLStatusAPIViewforVCAndPCAPI();
      show(true);
    }
};

 
  

  const getTableData = async () => {
    // console.log("Selected Project Code:", selectedProjectCode?.value)
    // console.log("Selected VC:", Bc?.value)
    // const apiFormattedData = {
    //   Project_code: selectedProjectCode?.value,
    // //   VC: Bc?.value,
    // }
    try {
      const response = await axios.get(
        `${baseUrl}SupplierDVAPLIMISData/`, {
            params: {
                Project_code: selectedProjectCode?.value
            }
        },
        // apiFormattedData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      // console.log("API Response:", response.data)
      if (response.data.error) {
        // alert(`API Error: ${response.data.error}`)
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.INFORMATION,
          info: response?.data?.error,
        })
        setLoading(false);
      } else if (Array.isArray(response.data)) {
        // console.log("Table Data:", response.data);
        const arr = response.data.map((item) => ({
          ...item,
          Send_to_vendor: item?.Send_to_vendor === true ? "True" : "False",
          is_deleted: item?.is_deleted === true ? "True" : "False",
          AppliedForPLI: item?.AppliedForPLI === true ? "True" : "False",
        }));
        setTableArray(arr)
        setLoading(false)
      } else {
        // Unexpected format
        // alert("Unexpected API response format.")
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.INFORMATION,
          info: "Unexpected API response format.",
        })
        // console.log("Unexpected API response format:", response.data);
        setLoading(false)
      }
    } catch (error) {
      // console.error("Fetch error:", error);
      // alert("Error fetching data, please try again.")
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: "Error fetching data, please try again.",
      })
      setLoading(false)
    }
  }

  const getAllProjectCode = async () => {

    const url = (isPurchaseUser || isVendorUser) ? 'AllProjectCodeforVendor' : 'AllProjectCodeforProductline'

    try {
      // const { data } = await axios.get(`${baseUrl}AllProjectCode/`);
      // const { data } = await axios.get(`${baseUrl}AllProjectCodeforProductline/`);
      const { data } = await axios.get(`${baseUrl}${url}/`)
      console.log('data in getall', data)
      
      const allProjectCodes = data.map(({ Project_code }) => Project_code);
      const uniqueProjects = [...new Set(allProjectCodes)];
      const projectCodeOptions = uniqueProjects.map((value) => ({
        value,
        label: value,
      }))
      setProjectCodeArray(projectCodeOptions)
    } catch (error) {
      console.error("Error fetching project codes:", error)
    }
  };

  const getRecentProjectCode = async (projectCodeOptions) => {
    try {
      // const { data, status } = await axios.get(`${baseUrl}LatestProjectCode/`);
      const { data, status } = await axios.get(`${baseUrl}LatestProjectCodeforProductline/`)
      if (status !== 200) {
        throw new Error("Failed to fetch the latest project code");
      }
      // console.log('data in Project_code: ', data)
      const recentProj = {
        label: data?.Project_code,
        value: data?.Project_code,
      };
      setLatestProject(recentProj);
      setSelectedProjectCode(recentProj); // Automatically select the latest project code
      getVcForProjectCode(recentProj)
      // const recentProjectCodeDescription = 
    } catch (error) {
      // console.error("Error fetching recent project codes:", error);
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'Could not fetch Project Code!',
      });
    }
  }

  // Fetch project description based on project code
  const getProjectDescription = async () => {
    try {
      const headerss = { 'Content-Type': 'application/json' }
      const body = { Project_code: selectedProjectCode?.value }
      const { data } = await axios.post(
        `${baseUrl}ProjectDescriptionView/`,
        body,
        { headers: headerss }
      )
      // console.log('pro desc: ', data)
      setProjectDescription(data?.Project_Description)
    } catch (error) {
      console.error('Error fetching project description:', error)
    }
  }

 useEffect(() => {
    if (latestProject) {
      setSelectedProjectCode(latestProject);
    }
  }, [latestProject]);

  // Check if the selected project code is the latest project code
  useEffect(() => {
    if (selectedProjectCode && latestProject) {
      setIsLatestProjectSelected(selectedProjectCode.value === latestProject.value)
    }
  }, [selectedProjectCode, latestProject])

  const getVcForProjectCode = async (selectedCode) => {
    console.log('selectedCode: ', selectedCode)
    // console.log('selectedCode ?.Project_code: ', selectedCode?.Project_code)
    console.log('selectedProjectCode', selectedProjectCode)

    const headerss = {
      'Content-Type': 'application/json'
    }

    const apiBody = {
      // project_code: selectedProjectCode?.value || selectedCode?.value,
      Project_code: selectedProjectCode?.value || selectedCode?.value,
      SPOC: preferredUsername
    }
    console.log('PL body: ', apiBody, preferredUsername);
    
    try {
      const response = await axios.post(
        // `${baseUrl}GetVCsByProject/`,
        `${baseUrl}GetVCsByProjectAndSPOCForPL/`,
        apiBody,
        { headers: headerss }
      )

      if (response.status >= 200 && response.status < 300) {
        const resArray = response.data?.map(value => ({ value, label: value }))
        console.log('resArray: ', resArray)
        setBcArray(resArray)
      } else {
        console.error(
          `Error fetching VC: ${response.status} - ${response.statusText}`
        )
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Failed to fetch data. Error: ${response.status} - ${response.statusText}`
        })
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error(
          `Error fetching VC: ${error.response.status} - ${error.response.data}`
        )
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Failed to fetch data. Error: ${error.response.status} - ${error.response.data}`
        })
      } else if (error.request) {
        // Request was made but no response was received
        console.error('Error fetching VC: No response received', error.request)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Failed to fetch data. No response received from the server.'
        })
      } else {
        // Something else happened in setting up the request
        console.error('Error fetching VC:', error.message)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Failed to fetch data. Error: ${error.message}`
        })
      }
    }
  }

  
  const uploadFileData = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.id = "file";
    input.setAttribute(
      "accept",
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    );
    input.click();
    input.onchange = (e) => {
      setLoader(true);
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      fetch(`${baseUrl}SupplierDataaddition/`, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          // eslint-disable-next-line radix
          let status1 = parseInt(response.status);
          if (status1 == "201") {
            //   document.removeChild(input);
            getTableData();
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded sucessfully",
            });
          } else {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded failed",
            });
          }
        })

        .catch((error) => {});
    };
  };

  const exportExcel = async (excelData) => {
    // const newExcelData = excelData?.map(
    //   (
    //     {
    //       Project_code,
    //       Project_type,
    //       Project_type_details,
    //       // Financial_year,
    //       // Quarter,
    //       VC,
    //       VC_description,
    //       Plant_code,
    //       // Supplier_code,
    //       // Supplier_name,
    //       Part_no,
    //       Part_description,
    //       // Run_version,
    //       // Operation,
    //       // Bom_item_no,
    //       // Supplier_pli,
    //       // Part_uom,
    //       Assembly,
    //       // Sr_no,

    //       Group_material,
    //       Group_material_quantity,
    //       Reference_material,
    //       Followup_material,
    //       // Cost_run_date,
    //       Bom_quantity,
    //       Bom_uom,
    //       // Total_cost,
    //       // Grn_sob,
    //       // Bom_sob,
    //       // Gr_no,
    //       // Gr_date,
    //       // Gr_entry_date,
    //       // Total_gr_quantity,
    //       // Final_quantity,
    //       // Percentage_check,
    //       // Po_no,
    //       // Po_type,
    //       // Purchase_group,
    //       // Import_domestic,
    //       // Contract_no,
    //       // Invoice_no_r,
    //       // Invoice_date_r,
    //       // Invoice_uom_r,
    //       // Invoive_no_s,
    //       // Invoice_date_s,
    //       // Invoice_uom_s,
    //       // Sap_invoice_quantity,
    //       // Net_price,
    //       // Supplementary_price,
    //       // Gross_price,
    //       // Total_net_price,
    //       // Total_gross_price,
    //       // HSN_code,
    //       // Currency,
    //       // PAN,
    //       // Exchange_rate,
    //       // Exchange_rate_date,
    //       // Irn_no,
    //       // Deleted,
    //       // Deletion_remark,
    //       // Addition_remark,
    //       System_Remark,
    //       PL_Remark,
    //       Created_date
    //     },
    //     id
    //   ) => {
    //     let obj = {
    //       id,
    //       Project_code,
    //       Project_type,
    //       Project_type_details,
    //       // Financial_year,
    //       // Quarter,
    //       VC,
    //       VC_description,
    //       Plant_code,
    //       // Supplier_code,
    //       // Supplier_name,
    //       Part_no,
    //       Part_description,
    //       // Run_version,
    //       // Operation,
    //       // Bom_item_no,
    //       // Supplier_pli,
    //       // Part_uom,
    //       Assembly,
    //       // Sr_no,

    //       Group_material,
    //       Group_material_quantity,
    //       Reference_material,
    //       Followup_material,
    //       // Cost_run_date,
    //       Bom_quantity,
    //       Bom_uom,
    //       // Total_cost,
    //       // Grn_sob,
    //       // Bom_sob,
    //       // Gr_no,
    //       // Gr_date,
    //       // Gr_entry_date,
    //       // Total_gr_quantity,
    //       // Final_quantity,
    //       // Percentage_check,
    //       // Po_no,
    //       // Po_type,
    //       // Purchase_group,
    //       // Import_domestic,
    //       // Contract_no,
    //       // Invoice_no_r,
    //       // Invoice_date_r,
    //       // Invoice_uom_r,
    //       // Invoive_no_s,
    //       // Invoice_date_s,
    //       // Invoice_uom_s,
    //       // Sap_invoice_quantity,
    //       // Net_price,
    //       // Supplementary_price,
    //       // Gross_price,
    //       // Total_net_price,
    //       // Total_gross_price,
    //       // HSN_code,
    //       // Currency,
    //       // PAN,
    //       // Exchange_rate,
    //       // Exchange_rate_date,
    //       // Irn_no,
    //       // Deleted,
    //       // Deletion_remark,
    //       // Addition_remark,
    //       System_Remark,
    //       PL_Remark,
    //       Created_date
    //     }
    //     return obj
    //   }
    // )
    console.log('new excel array: ', tableArray)
    var fileTypel =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    var fileExt = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(tableArray)
    // const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { booktype: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileTypel });
    FileSaver.saveAs(data, `DVA_Declaration_MIS${fileExt}`);
  };


    const baseDefaultColumns = [
        {
            width: 130,
            title: "Project Code",
            field: "Project_code",
            enableSearch: true,
            enableFilter: true,
          },
      {
        width: 130,
        title: "Supplier Code",
        field: "Supplier_code",
        enableSearch: true,
        enableFilter: true,
      },
      // {
      //   width: 130,
      //   title: "Plant Code",
      //   field: "Plant_code",
      //   enableSearch: true,
      //   enableFilter: true,
      // },
      {
        width: 130,
        title: "PO Plant",
        field: "Po_Plant",
        enableSearch: true,
        enableFilter: true,
      },
      {
        width: 170,
        title: "Supplier Name",
        field: "Supplier_name",
        enableSearch: true,
        enableFilter: true,
      },
      {
        width: 130,
        title: "Vendor Submitted Status",
        field: "Vendor_submitted_status",
        enableSearch: true,
        enableFilter: true,
      },
      {
        width: 130,
        title: "Vendor Submitted Date",
        field: "Vendor_submitted_Date",
        enableSearch: true,
        enableFilter: true,
      },
      {
        width: 150,
        title: "TMLBSL Submitted Status",
        field: "TMLBSL_submitted_status",
        enableSearch: true,
        enableFilter: true,
      },
      {
        width: 180,
        title: "TMLBSL Submitted Date",
        field: "TMLBSL_submitted_Date",
        enableSearch: true,
        enableFilter: true,
      },
      {
        width: 120,
        title: "TMLBSL Submitted Name",
        field: "TMLBSL_submitted_Name",
        enableSearch: true,
        enableFilter: true,
      },
      {
        width: 100,
        title: "Purchase Submitted Status",
        field: "Purchase_submitted_status",
        enableSearch: true,
        enableFilter: true,
      },
      {
        width: 100,
        title: "Purchase Submitted Date",
        field: "Purchase_submitted_Date",
        enableSearch: true,
        enableFilter: true,
      },
      {
        width: 100,
        title: "Purchase Submitted Name",
        field: "Purchase_submitted_Name",
        enableSearch: true,
        enableFilter: true,
      },
      
      {
        width: 100,
        title: "SupplierDVA Overall status",
        field: "SupplierDVA_Overall_status",
        enableSearch: true,
        enableFilter: true,
      },
      {
        width: 100,
        title: "PMT Submitted Status",
        field: "PMT_submitted_status",
        enableSearch: true,
        enableFilter: true,
      },
      {
        width: 100,
        title: "PMT Submitted Date",
        field: "PMT_submitted_Date",
        enableSearch: true,
        enableFilter: true,
      },

      {
        width: 100,
        title: "PMT Submitted Name",
        field: "PMT_submitted_Name",
        enableSearch: true,
        enableFilter: true,
      },
      
    ]

  /* New axios way */
  const formattedCurrentDate = new Date().toLocaleDateString('en-GB').split('/').join('/');
  const BOMvalidationAPI = () => {
    const apiBody = {
      Project_code: selectedProjectCode?.value,
      // email_to: 'dp928042.ttl@tatamotors.com'
      VC: Bc?.value,
      ProductLine_validation_date: formattedCurrentDate,
      ProductLine_validater_name: userName
    }

    console.log('Submit body: ', apiBody)

    axios
      // .post(`${baseUrl}UpdateStatus/`, apiBody, {
      // .post(`${baseUrl}UpdateProductLineStatus/`, apiBody, {
      .post(`${baseUrl}UpdatePLStatus/`, apiBody, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        const responseJson = response.data
        console.log('responseJson', responseJson)
        if (responseJson?.message) {
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: 'BOM Validation Successful!'
          })
          history.push('/')
        } else {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: responseJson?.error
          })
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log('Error response:', error.response)
          if (error.response.status === 400) {
            showPopup({
              type: MESSAGE_TYPE.FAILURE,
              contextText: MESSAGE_TYPE.FAILURE,
              info: 'Bad Request: Please check your input!'
            })
          } else if (error.response.status === 404) {
            showPopup({
              type: MESSAGE_TYPE.FAILURE,
              contextText: MESSAGE_TYPE.FAILURE,
              info: 'Validation Failed'
            })
          } else {
            showPopup({
              type: MESSAGE_TYPE.FAILURE,
              contextText: MESSAGE_TYPE.FAILURE,
              info: `Error: ${error.response.statusText}`
            })
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Error request:', error.request)
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'No response received from server!'
          })
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error message:', error.message)
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: `Error: ${error.message}`
          })
        }
        console.log('error in submit: ', error)
      })
  }

  // const redirectToCreateProjectPage = () => history.push("/");

  const renderHTML = () => (
    <div className={styles.formGroup} >
      <Grid
        container
        spacing={1}
        style={{ display: "flex", justifyContent: "left" }}
        columns={12}
      >
        <Grid
          item
          // sm={12}
          md={3}
          lg={3}
          xs={3}
          style={{ marginTop: "5px", marginBottom: "5px", display: 'flex', justifyContent: 'center' }}
        >
          {/* <div className={styles.rowDiv}> */}
            <div className={clsx(styles.lableDiv)} style={{ width: "36%" }}>
              <label className={styles.label} style={{ width: '' }}>Project Code</label>
            </div>
            <div className={styles.formDiv} style={{ width: '54%' }}>
              <CustomSelect
                name="Project_Code"
                options={projectCodeArray}
                className={clsx(styles.select, styles.sel1)}
                value={selectedProjectCode}
                isMulti={false}
                defaultValue={latestProject}
                isClearable
                isMandatory
                onChange={(selectedOption) => {
                  handleDropdownChange(selectedOption)
                  setSelectedProjectCode(selectedOption)
                }}
                style={{ width: '100% !important' }}
              />
            </div>
          {/* </div> */}
        </Grid>

        {/* Project Desc. View */}
         <Grid
          item
          xs={4}
          md={4}
          lg={4}
          // sm={12}
          style={{ marginTop: "5px", marginBottom: "5px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: "40%" }}>
              <label className={styles.label}>Project Desc.</label>
            </div>
                <div className={styles.formDiv}>
                  <ValidatingTextField
                    variant='outlined'
                    size='small'
                    fullWidth
                    value={projectDescription && projectDescription}
                    name='projDesc'
                    inputProps={{
                      'data-testid': 'remark-input'
                    }}
                    onChange={(selectedOption) => handleDropdownChange(selectedOption)}
                    // eslint-disable-next-line react/jsx-boolean-value
                    isDisabled={true}
                  />
                </div>
          </div>
        </Grid>


        {/* Show Data Button Grid */}
        <Grid
          it2m
          xs={2}
          md={2}
          // lg={4}
          // sm={12}
          style={{ marginTop: "5px", marginBottom: "5px", display: 'flex', justifyContent: 'end', alignItems: 'center' }}
        >
          <div
            style={{
              marginLeft: "5px",
              paddingRight: "10px",
              marginTop: "1px",
              display: "flex",
              justifyContent: "left",
            }}
          >
            <AuthChecker operation="search">
              {(isAuthorized) => (
                <Button
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  style={{ marginRight: "12px !important" }}
                  variant="primary"
                  onClick={showTable}
                  disabled={!isAuthorized}
                  data-testid="confirm-action"
                >
                  Show Data
                </Button>
              )}
            </AuthChecker>
            </div>
        </Grid>
    </Grid>
    </div>
  );

  
  const uploadDocument = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.id = "file";
    input.setAttribute("accept", ".xlsx");
    input.click();
  
    input.onchange = (e) => {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      // formData.append("Supplier_Code", Bc?.value);
      // formData.append('Project_code', projectCode?.value)
      // formData.append('')
  
      axios.post(`${baseUrl}UploadPLIdata/`, formData)
        .then((response) => {
          // console.log('file upload res: ', response);
          if (response.status === 200 || response.status === 201) {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              // contextText: getUrl(certificateUrl),
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "Document Uploaded Successfully",
            });
            getTableData();
          } else if (response.status === 400) {
            showPopup({
              type: MESSAGE_TYPE.FAILURE,
              contextText: MESSAGE_TYPE.FAILURE,
              info: `${response.data ? response.data.status : 'Bad Request'}`,
            });
          } else {
            showPopup({
              type: MESSAGE_TYPE.FAILURE,
              contextText: MESSAGE_TYPE.FAILURE,
              info: `Server error: ${response.data ? response.data.status : response.status}`,
            });
          }
        })
        .catch((error) => {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: error.message,
          });
        });
    };
  };

  const secondaryActions = [
    // {
    //   name: "Upload",
    //   authOperation: "upload",
    //   // shouldEnable: () => isLatestProjectSelected, // Enable only if the latest project is selected
    //   shouldEnable: () => submitButtonFlag(),
    //   actionFn: () => {
    //     uploadDocument();
    //   },
    //   customClass: "",
    // },

    {
      name: "Download",
      authOperation: "download",
      shouldEnable: (selected) => true,
      actionFn: (selected) => {
        // getDataForExcel()
        exportExcel(tableArray);
      },
      customClass: "",
    }
  ];

  const handleSecondaryActionOnRole = () => {
    if (auth.userRole.includes("admin")) {
      return secondaryActions;
    }
    if (auth.userRole.includes("GDC") || auth.userRole.includes("TMLBSL")) {
      return secondaryActions.filter(
        (item) =>
          item.name === "Download Documents" ||
          item.name === "Download Certificate" ||
          item.name === "Upload"
      );
    }
    if (auth.userRole.includes("Vendor")) {
      return secondaryActions.filter(
        (item) =>
          item.name !== "Download Documents" &&
          item.name !== "Download Certificate"
      );
    }
    if (auth.userRole.includes("Purchase")) {
      return secondaryActions.filter(
        (item) =>
          item.name === "Download Documents" ||
          item.name === "Download Certificate"
      );
    }
    return secondaryActions;
  };

  return (
    <>
      <div className={styles.container} style={{ boxSizing: "border-box" }}>
        <div>
          <div
            className={styles.backButtonLastRefreshedDateWrapper}
            style={{ marginTop: "10px" }}
          >
            <BackButton
              action="Back"
              handleClick={redirectToApplicationLandingPage}
              style={{ marginBottom: "0px" }}
            />
            <div
              style={{ width: "100%" }}
              // style={{ display: 'flex', flexDirection: 'row-reverse' }}
            >
              <div className={styles.topDiv}>
                <div
                  className={styles.processHeader}
                  style={{ display: "flex", height: "3em" }}
                >
                  <div style={{ width: "100%" }}>
                    <CustomTab title="DVA Declaration Report" withOutCount isSelected />
                  </div>

                  {/* <StepBar1 enable={5} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup body={renderHTML()} />
          </div>
        </div>
        {flag && (
          <div
            className={styles.tableView}
            style={{ height: "86%", padding: "0 2em" }}
          >
             <Table
        columns={
          !auth.userRole.includes("Vendor")
            ? [...baseDefaultColumns]
            : [...baseDefaultColumns]
        }
        defaultColumns={[...baseDefaultColumns]}
        rowHeight={38}
        rows={tableArray}
        // primaryAction={{
        //   name: "Submit Data",
        //   authOperation: "submit",
        //   // shouldEnable: () => isLatestProjectSelected, // Enable only if the latest project is selected
        //   shouldEnable: () => submitButtonFlag(),
        //   actionFn: handleOpenFormModal,
        // }}
        secondaryActions={secondaryActions}
        setAsnCount={setCount}
        isDataLoading={loader}
        actionButtons
        onRowSelect={(e) => {}}
        removeRowSelection
      />

          </div>
        )}
      </div>
      {/* <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            // onClick={onDelete}
          >
            Yes
          </Button> */}
      <ResetModalF
        isPopupOpen={isPopupOpenFormModal}
        handleCloseFormModal={handleCloseFormModal}
        BOMvalidationAPI={BOMvalidationAPI}
      />

      <ResetModal
        isPopupOpen={isPopupOpen}
        handleClose={handleClose}
        goNext={goNext}
      />
    </>
  );
};
function ResetModalF({ isPopupOpen, handleCloseFormModal, BOMvalidationAPI }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: "18px", width: "18px" }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>
              Confirm Validation
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation, BOM will be validated</p>

            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleCloseFormModal}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant="primary"
            onClick={(e) => {
              e.preventDefault()
              handleCloseFormModal()
              BOMvalidationAPI()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}
function ResetModal({ isPopupOpen, handleClose, goNext }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: "18px", width: "18px" }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>
              Confirm file Upload
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p> On confirmation ,Records will be appended.</p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
              goNext();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}
ResetModalF.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleCloseFormModal: PropTypes.func.isRequired,
  BOMvalidationAPI: PropTypes.func.isRequired,
};

ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
};

export default withAllowedOperationsProvider(DvaMis, RESOURCE_TYPE.PROJECT);