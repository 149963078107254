/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'

import { Switch, Route, Routes } from 'react-router-dom';
import PrivateRoute from './routing/PrivateRoute';
import HeaderAndFooter from './layouts/HeaderAndFooter/HeaderAndFooter';
import { overrideConsole } from './utils/loggingErrors';
//  import { MENU } from './constants';

import { useAuthorizationContext } from './providers/AuthorizationHandler/AuthorizationHandler';

import Test from './pages/Test/Test';
import LandingPage from './pages/LandingPage/LandingPage';
import milestone from './pages/Milestones/Milestones';
import Vendordva from './pages/Vendordva/Vendordva';
import PLIList from './pages/PLIList/PLIList';
import ColourVcMIS from './pages/ColourVCMIS/ColourVCMIS'
import ColourVcMISPV from './pages/ColourVCMISPV/ColourVCMISPV'
import VendorMIS from './pages/VendorMIS/VendorMIS';
import VendorMISPV from './pages/VendorMISPV/VendorMISPV';
import PLIListPV from './pages/PLIListPV/PLIListPV';
import Test2 from './pages/Test2/Test2';
import VcBomData from './pages/VcBomData/VcBomData';
import VcBomDataPV from './pages/VcBomDataPV/VcBomDataPV';
import VendarData from './pages/VendarData/VendarData';
import VendarDataPV from './pages/VendarDataPV/VendarDataPV';
import VendorCertificate from './pages/VendorCertificate/VendorCertificate';
import VendorCertiticatePV from './pages/VendorCertificatePV/VendorCertificatePV';
// import certificateupload from './pages/CertificateUpload/certificateupload';
import addVCBC from './pages/AddVCBCPage/AddVCBCPage';
import AddVCBCPagePV from './pages/AddVCBCPagePV/AddVCBCPagePV';
import DVADeclaration from './pages/DVADeclaration/DVADeclaration'
import DVACalculationCV from './pages/DVACalculationCV/DVACalculationCV'
import MHIDataCV from './pages/MHIDataCV/MHIDataCV'
import MHIDataPV from './pages/MHIDataPV/MHIDataPV'
import DVACalculationPV from './pages/DVACalculationPV/DVACalculationPV'

import AuditReport from './pages/AuditReport/AuditReport';
import MilesStone from './pages/MilesStonePage/MilesStonePage';
import MilesStonePV from './pages/MilesStonePagePV/MilesStonePagePV';
import SupplierImportDetails from './pages/SupplierImportDetails/SupplierImportDetails'
import SupplierImportDetailsPV from './pages/SupplierImportDetailsPV/SupplierImportDetailsPV'
import SupplierImportDetailsForTierII from './pages/SupplierImportDetailsForTierII/SupplierImportDetailsForTierII'
import SupplierImportDetailsPVForTierII from './pages/SupplierImportDetailsPVForTierII/SupplierImportDetailsPVForTierII'
import SupplierData from './pages/SupplierData/SupplierData'
import SupplierDataPV from './pages/SupplierDataPV/SupplierDataPV'
import SupplierDataPurchase from './pages/SupplierDataPurchase/SupplierDataPurchase'
import SupplierDataPurchasePV from './pages/SupplierDataPurchasePV/SupplierDataPurchasePV'
import VendorMapping from './pages/VendorMapping/VendorMapping';
import VCMapping from './pages/VCMapping/VCMapping';
import VcBomDataInfo from './pages/VcBomDataInfo/VcBomDataInfo';
import ProcessForm from './pages/ProcessForm/ProcessForm';
import AFSMIS from './pages/ASFMIS/AFSMIS'
import MHIForm from './pages/MHIDataCV/MHIForm';
import AnnualFinancialStatement from './pages/AnnualFinancialStatement/AnnualFinancialStatement';
import BaseSheetData from './pages/BaseSheetData/BaseSheetData';
import BaseSheetDataPV from './pages/BaseSheetDataPV/BaseSheetDataPV';
import TMLDataSheet from './pages/TMLDataSheet/TMLDataSheet';
import TMLDataSheetPV from './pages/TMLDataSheetPV/TMLDataSheetPV';
import PartLevelData from './pages/PartLevelData/PartLevelData';
import PartLevelDataPV from './pages/PartLevelDataPV/PartLevelDataPV';
import VendorPartData from './pages/VendorPartData/VendorPartData';
import AFSpurchase from './pages/AFSPurchase/AFSpurchase';
import SupplierDataPurchasePage3 from './pages/SupplierDataPurchasePage3/SupplierDataPurchasePage3';

import SupplierMpfdCV from './pages/SupplierMPFD-CV/SupplierMpfdCV';
import SupplierMpfdPV from './pages/SupplierMPFD-PV/SupplierMpfdPV';
import AFSMaster from './pages/AdminControl/AFSMaster/AFSMaster';
import BuyerMaster from './pages/AdminControl/BuyerMaster/BuyerMaster';
import MPFDMaster from './pages/AdminControl/MPFDMaster/MPFDMaster';
import AFSMasterData from './pages/AFSMasterData/AFSMasterData';

import SupplierDVAReport from './pages/SupplierDVAReport/SupplierDVAReport';
import BOMProductLine from './pages/BOMProductLine/BOMProductLine'
import BOM_PMT from './pages/BOM_PMT/BOM_PMT'
import BOM_BOE from './pages/BOM_BOE/BOM_BOE'
import BOMMaster from './pages/BOMMaster/BOMMaster'
import MHIDataFormation from './pages/MHIScreens/MHIDataFormation'
import MHIDataSubmission from './pages/MHIScreens/MHIDataSubmission'
import BOMProductLine2 from './pages/BOMProductLine2/BOMProductLine2'
import VCSPOCMapping from './pages/AdminControl/VCSPOCMapping/VCSPOCMapping'
import PLReportMIS from './pages/PLReportMIS/PLReportMIS';
import PLReportMIS2 from './pages/PLReportMIS2/PLReportMIS2';
import PMTBOMReportMIS from './pages/PMTBOMReportMIS/PMTBOMReportMIS';
import BOM_BOE_ReportMIS from './pages/BOM_BOE_ReportMIS/BOM_BOE_ReportMIS';
import SupplierUnderPLIDeclarationTMLBSL from './pages/SupplierUnderPLIDeclarationTMLBSL/SupplierUnderPLIDeclarationTMLBSL';
import SupplierUnderPLIDeclarationPMT from './pages/SupplierUnderPLIDeclarationPMT/SupplierUnderPLIDeclarationPMT';
import PartBuyerMapping from './pages/PartBuyerMapping/PartBuyerMapping'
import SupplierUnderPLIDeclarationMIS from './pages/SupplierUnderPLIDeclarationMIS/SupplierUnderPLIDeclarationMIS';
import DVADeclarationMIS from './pages/DVADeclarationMIS/DVADeclarationMIS';
import VCWiseDVACalculation from './pages/VCWiseDVACalculation/VCWiseDVACalculation';
import FinanceMaster from './pages/FinanceMaster/FinanceMaster';
import ExemptedData from './pages/ExemptedData/ExemptedData';
import Annexures from './pages/Annexures/Annexures';
import SupplierUnderPLIVendor from './pages/SupplierUnderPLIVendor/SupplierUnderPLIVendor';
import Mpfd from './pages/Mpfd/Mpfd';
import MpfdPurchaseTmlbsl from './pages/MpfdPurchaseTmlbsl/MpfdPurchaseTmlbsl';
import MpfdSupplier from './pages/MpfdSupplier/MpfdSupplier';
import AuditFile from './pages/AuditFile/AuditFile';
import DashBoard from './pages/DashBoard/DashBoard';
import VendorMaster from './pages/VendorMaster/VendorMaster';
import InvoiceUpload from './pages/InvoiceUpload/InvoiceUpload';
import UserManual from './pages/UserManual/UserManual';
import BOEUpload from './pages/BOEUpload/BOEUpload';
import SupplierDVAadminVendor from './pages/SupplierDVAadminVendor/SupplierDVAadminVendor';
import SupplierDVAAdminTMLBSL from './pages/SuplierDVAAdminTMLBSL/SupplierDVAAdminTMLBSL';
import FreezeUnFreeze from './pages/FreezeUnFreeze/FreezeUnFreeze';
import CreateDemoProject from './pages/CreateDemoProject/CreateDemoProject';
import MHI_PMT from './pages/MHI_PMT/MHI_PMT';

const MENU = [
  {

    rolePLI: "admin",
    pageDetails: [
      // {
      //   component: SupplierImportDetails,
      //   url: '/supplier_import_details-cv',
      //   label: "Supplier Import Details",
      // },
      {
        component: SupplierDVAReport,
        url: '/supplier-dva-report',
        label: "Supplier DVA Report"
      },
      {
        component: AFSMasterData,
        url: '/afs_master_data-pv',
        label: "AFS Master Data",
      },
      {
        component: SupplierImportDetailsForTierII,
        url: '/supplier_import_details-cv-For_Tier_II',
        label: "Supplier Import Details-CV-For Tier II & III",
      },
      {
        component: SupplierImportDetailsPV,
        url: '/supplier_import_details-pv',
        label: "Supplier Import Details",
      },
      {
        component: SupplierImportDetailsPVForTierII,
        url: '/supplier_import_details-pv-For_Tier_II',
        label: "Supplier Import Details-PVFor Tier II & III",
      },

      // {
      //   component: SupplierImportDetails,
      //   url: '/supplier_import_details-ev',
      //   label: "Supplier Import Details ",
      // }
      {
        component: SupplierData,
        url: '/Supplier-DVA-Collection-CV',
        label: "Supplier DVA"

      },
      {
        component: SupplierDataPV,
        url: '/Supplier-DVA-Collection-pv',
        label: "Supplier DVA"
      },
      {
        component: SupplierDVAadminVendor,
        url: '/supplier-dva-vendor-admin',
        label: "Supplier DVA - Vendor",
      },
      {
        component: SupplierData,
        url: '/Supplier-DVA-Collection-ev',
        label: "Supplier DVA"
      },
      {
        component: SupplierDVAAdminTMLBSL,
        // url: '/Supplier-DVA-Collection-CV-Purchase',
        url: '/supplier-dva-admin-tmlbsl',
        label: "Supplier DVA TMLBSL"
      },
      {
        component: SupplierDataPurchasePV,
        url: '/Supplier-DVA-Collection-pv-Purchase',
        label: "Supplier DVA"
      },

      /* New components */
      {
        component: SupplierDataPurchasePage3,
        url: '/Supplier-DVA-Collection-CV-Purchase-Page3',
        label: "Supplier DVA Page3"
      },
      {
        component: SupplierUnderPLIVendor,
        // url: '/Supplier-DVA-Collection-pv-Purchase-Page3',
        url: '/supplier-dva-declaration-for-pli',
        label: "Supplier DVA Page3"
      },
      {
        component: SupplierDataPurchase,
        url: '/Supplier-DVA-Collection-ev-Purchase',
        label: "Supplier DVA"
      },
      {
        component: AuditReport,
        url: '/audit-report-cv',
        label: "Audit"
      },
      {
        component: AuditReport,
        url: '/audit-report-pv',
        label: "Audit"
      },
      {
        component: AuditReport,
        url: '/audit-report-ev',
        label: "Audit"
      },
      {
        component: VendorCertificate,
        url: '/vendor-certificate',
        label: "VendorCertificate"
      },
      {
        component: VendorCertiticatePV,
        url: '/vendor-certificate-pv',
        label: "VendorCertificate"
      },
      {
        component: MilesStone,
        url: '/milestones-calendar-cv',
        label: "Milestone Calendar"
      },
      {
        component: MilesStonePV,
        url: '/milestones-calendar-pv',
        label: "Milestone Calendar"
      },
      {
        component: 'MilesStoneEV',
        url: '/milestones-calendar-ev',
        label: "Milestone Calendar"
      },
      {
        component: addVCBC,
        url: '/add-vcbc-cv',
        label: "VC Declaration"
      },
      {
        component: AddVCBCPagePV,
        url: '/add-vcbc-pv',
        label: "VC Declaration",
      },
      {
        component: 'AddVCBCPageEV',
        url: '/add-vcbc-ev',
        label: "VC Declaration"
      },
      {
        component: PLIList,
        url: '/vcbc-list-cv',
        label: "Mass VC Declaration"
      },
      {
        component: PLIListPV,
        url: '/vcbc-list-pv',
        label: "Mass VC Declaration"
      },
      {
        component: 'PLIListEV',
        url: '/vcbc-list-ev',
        label: "Mass VC Declaration"
      },
      {
        component: VcBomData,
        url: '/vcbom-data',
        label: "VC BoM Data"
      },
      {
        component: VcBomDataPV,
        url: '/vcbom-data-pv',
        label: "VC BoM Data"
      },
      {
        component: 'VcBomDataEV',
        url: '/vcbom-data-ev',
        label: "VC BoM Data"
      },
      {
        component: VendarData,
        url: '/vendor-data',
        label: "Vendor DVA Declaration"
      },
      {
        component: VendarDataPV,
        url: '/vendor-data-pv',
        label: "Vendor DVA Declaration"
      },
      {
        component: 'VendarDataEV',
        url: '/vendor-data-ev',
        label: "Vendor DVA Declaration"
      },
      {
        component: ColourVcMIS,
        url: '/colour-vc-mis',
        label: 'VC MIS-CV'
      },
      {
        component: ColourVcMISPV,
        url: '/colour-vc-mis-pv',
        label: 'VC MIS-CV'
      },
      {
        component: 'ColourVcMISEV',
        url: '/colour-vc-mis-ev',
        label: 'VC MIS-CV'
      },
      {
        component: VendorMIS,
        url: '/vendor-mis',
        label: "Vendor MIS"
      },
      {
        component: VendorMISPV,
        url: '/vendor-mis-pv',
        label: "Vendor MIS",
      },
      {
        component: 'VendorMISEV',
        url: '/vendor-mis-ev',
        label: "Vendor MIS"
      },
      {
        component: DVACalculationCV,
        url: '/dva-calculation-cv',
        label: "DVA Calculation"
      },
      {
        component: DVACalculationPV,
        url: '/dva-calculation-pv',
        label: "DVA Calculation"
      },
      {
        component: 'DVACalculationEV',
        url: '/dva-calculation-ev',
        label: "DVA Calculation"
      },
      // {
      //   component: MHIDataCV,
      //   url: '/mhi-data-cv',
      //   label: "MHI Data"
      // },
      {
        component: MHIDataFormation,
        url: '/mhi-data-formation',
        label: "MHI Data Formation"
      },
      {
        component: MHIDataSubmission,
        url: '/mhi-data-submission',
        label: "MHI Data Submission"
      },
      {
        component: MHIDataPV,
        url: '/mhi-data-pv',
        label: "MHI Data"
      },
      {
        component: 'MHIDataEV',
        url: '/mhi-data-ev',
        label: "MHI Data"
      },
      {
        component: MHIForm,
        url: '/mhi-form-cv',
        label: "MHI Data"
      },
      {
        component: VendorMapping,
        url: '/Vendor_mapping',
        label: "VendorMapping"
      },
      {
        component: VCMapping,
        url: '/VC_mapping',
        label: "VCMapping"
      },
      {
        component: VcBomDataInfo,
        url: '/VcBomDataInfo',
        label: "VcBomDataInfo"
      },
      {
        component: ProcessForm,
        url: '/ProcessForm',
        label: "ProcessForm"
      },
      {
        component: AFSMIS,
        url: '/AFSMIS',
        label: "AFSMIS"
      },
      {
        component: AnnualFinancialStatement,
        url: '/annual_financial_statement',
        label: "Annual Financial Statement"
      },
      // 29/12/2023
      {
        component: BaseSheetData,
        url: '/BaseSheetData',
        label: "Base Sheet Data"
      },
      {
        component: BaseSheetDataPV,
        url: '/BaseSheetDataPV',
        label: "Base Sheet Data (PV)"
      },
      {
        component: TMLDataSheet,
        url: '/TMLDataSheet',
        label: "TML Data Sheet"
      },
      {
        component: TMLDataSheetPV,
        url: '/TMLDataSheetPV',
        label: "TML Data Sheet (PV)"
      },
      {
        component: PartLevelData,
        url: '/PartLevelData',
        label: "Part Level Data"
      },
      {
        component: PartLevelDataPV,
        url: '/PartLevelDataPV',
        label: "Part Level Data (PV)"
      },
      {
        component: VendorPartData,
        url: '/VendorPartData',
        label: "Vendor Part Data"
      },

      /* --------------------- Admin control URLs and component --------------------- */
      {
        component: AFSMaster,
        url: '/afs-master',
        label: "AFS Master",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component: VCSPOCMapping,
        url: '/vc-spoc-mapping',
        label: "VC SPOC Mapping",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component: PLReportMIS,
        url: '/pl-report',
        label: 'PL Report',
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },


      {
        component: BuyerMaster,
        url: '/buyer-master',
        label: "Buyer Master"
      },
      {
        component: MPFDMaster,
        url: '/mpfd-master',
        label: "MPFD Master"
      },
      {
        component : BOMMaster,
        url : "/bom-master-data",
        label : "BOM Validation",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component: BOMProductLine,
        url: '/bom-validation',
        label: "BOM Validation"
      },
      {
        component: BOMProductLine2,
        url: '/bom-validation_2',
        label: "BOM Validation"
      },
      {
        component : Mpfd,
        url : "/mpfd",
        label : "MPFD",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
    ]
  },

  // Newly added TMLBSL role
  {
    rolePLI: "TMLBSL",
    pageDetails: [
      {
        component: AFSMIS,
        url: '/AFSMIS',
        label: 'AFSMIS'
      },

      {
        component: AFSpurchase,
        url: '/AFSpurchase',
        label: "AFS Purchase Validation"
      },

      {
        component: AnnualFinancialStatement,
        url: '/annual_financial_statement',
        label: "Annual Financial Statement",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]
      },
      {
        component: AFSMasterData,
        url: '/afs_master_data-pv',
        label: 'AFS Master Data'
      },
      {
        component: SupplierDataPurchase,
        url: '/supplier-dva-collection',
        label: "Supplier DVA",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]
      },
      {
        component: SupplierDataPurchasePage3,
        url: '/Supplier-DVA-Collection-CV-Purchase-Page3',
        label: "Supplier DVA Page3",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]
      },
      {
        component: SupplierDataPurchasePV,
        url: '/Supplier-DVA-Collection-PV-Purchase',
        label: "Supplier DVA",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

      },
      {
        component: SupplierUnderPLIVendor,
        // url: '/Supplier-DVA-Collection-PV-Purchase-Page3',
        url: '/supplier-dva-declaration-for-pli',
        label: "Supplier DVA Page3",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]
      },   
      {
        component: SupplierUnderPLIDeclarationTMLBSL,
        url: '/supplier-dva-declaration-for-pli_tmlbsl',
        label: "Supplier Under PLI Declaration TMLBSL",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]
      },
      {
        component: SupplierMpfdCV,
        url: '/SupplierMPFD-CV',
        label: "Supplier MPFD",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]
      },
      {
        component: SupplierMpfdPV,
        url: '/SupplierMPFD-PV',
        label: "Supplier MPFD",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]
      },
      {
        component: SupplierUnderPLIDeclarationMIS,
        url: '/supplier-pli-declaration-mis',
        label: 'Supplier Under PLI Declaration MIS'
      },
      {
        component: DVADeclarationMIS,
        url: '/supplier-dva-declaration-mis',
        label: 'DVA Declaration MIS'
      },
      {
        component : BOMMaster,
        url : "/bom-master-data",
        label : "DVA Master Sheet Data",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component : MpfdPurchaseTmlbsl,
        url : "/mpfd-purchase-tmlbsl",
        label : "MPFD",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component : AuditFile,
        url : "/audit-file",
        label : "Audit File",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component : DashBoard,
        url : "/dashboard",
        label : "Dashboard",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component : InvoiceUpload,
        url : "/invoice-upload",
        label : "Invoice Upload",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component : VendorMaster,
        url : "/vendor-master",
        label : "Vendor Master",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component: BOM_BOE,
        url: '/bom-boe-data',
        label: "BOM BOE Data"
      },
      {
        component: BOM_BOE_ReportMIS,
        url: '/bom-boe-report',
        label: "BOM BOE Report"
      },
      {
        component: BOEUpload,
        url: '/boe-upload',
        label: "BOE Upload",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
    ]
  },

  {
    rolePLI: "Vendor-CV",
    pageDetails: [
      {
        component: VendarData,
        url: '/vendor-data',
        label: "Vendor DVA Declaration",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

      },
    ]
  },
  {
    rolePLI: "Vendor-PV",
    pageDetails: [
      {
        component: VendarDataPV,
        url: '/vendor-data-pv',
        label: "Vendor DVA Declaration",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

      },


    ]
  },

  /* Role: Vendor */
  {
    rolePLI: "Vendor",
    pageDetails: [
      {
        component: VendarDataPV,
        url: '/vendor-data-pv',
        label: "Vendor DVA Declaration",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

      },
      {
        component: VendarData,
        url: '/vendor-data',
        label: "Vendor DVA Declaration",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

      },
      {
        component: SupplierImportDetails,
        url: '/supplier_import_details-cv',
        label: "Supplier Import Details",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]
      },
      {
        component: AnnualFinancialStatement,
        url: '/annual_financial_statement',
        label: "Annual Financial Statement",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]
      },
      {
        component: SupplierDataPurchase,
        // url: '/Supplier-DVA-Collection-CV-Purchase',
        url: '/supplier-dva-collection',
        label: "Supplier DVA",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

      },
     {
        component: MpfdSupplier,
        // url: '/Supplier-DVA-Collection-CV-Purchase',
        url: '/mpfd-supplier',
        label: "MPFD",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

      },
      {
        component : InvoiceUpload,
        url : "/invoice-upload",
        label : "Invoice Upload",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component : UserManual,
        url : "/user-manual",
        label : "User Manuals and General Guidelines",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      // {
      //   component: SupplierDataPurchasePage3,
      //   url: '/Supplier-DVA-Collection-CV-Purchase-Page3',
      //   label: "Supplier DVA Page3",
      //   resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

      // },
      {
        component: SupplierDataPurchasePV,
        url: '/Supplier-DVA-Collection-PV-Purchase',
        label: "Supplier DVA",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

      },
      {
        component: SupplierUnderPLIVendor,
        // url: '/Supplier-DVA-Collection-PV-Purchase-Page3',
        url: '/supplier-dva-declaration-for-pli',
        label: "Supplier DVA Page3",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

      },
      {
        component: SupplierMpfdCV,
        url: '/SupplierMPFD-CV',
        label: "Supplier MPFD",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]
      },
      {
        component: SupplierMpfdPV,
        url: '/SupplierMPFD-PV',
        label: "Supplier MPFD",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]
      }
    ]
  },

  // {
  //   rolePLI: "GDC",
  //   pageDetails: [
  //     {
  //       component: MilesStone,
  //       url: '/milestones-calendar-cv',
  //       label: "Milestone Calendar"

  //     },

  //     {
  //       component: MilesStonePV,
  //       url: '/milestones-calendar-pv',
  //       label: "Milestone Calendar"

  //     },
  //     {
  //       component: 'MilesStoneEV',
  //       url: '/milestones-calendar-ev',
  //       label: "Milestone Calendar"

  //     },
  //     {
  //       component: PLIList,
  //       url: '/vcbc-list-cv',
  //       label: "Mass VC Declaration"
  //     },
  //     {
  //       component: PLIListPV,
  //       url: '/vcbc-list-pv',
  //       label: "Mass VC Declaration"
  //     },
  //     {
  //       component: 'PLIListEV',
  //       url: '/vcbc-list-ev',
  //       label: "Mass VC Declaration"
  //     },
  //     {
  //       component: VcBomData,
  //       url: '/vcbom-data',
  //       label: "VC BoM Data",
  //       resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

  //     },

  //     {
  //       component: VcBomDataPV,
  //       url: '/vcbom-data-pv',
  //       label: "VC BoM Data",
  //       resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

  //     },
  //     {
  //       component: 'VcBomDataEV',
  //       url: '/vcbom-data-ev',
  //       label: "VC BoM Data",
  //       resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

  //     },
  //     {
  //       component: VendarData,
  //       url: '/vendor-data',
  //       label: "Vendor DVA Declaration",
  //       resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

  //     },
  //     {
  //       component: VendarDataPV,
  //       url: '/vendor-data-pv',
  //       label: "Vendor DVA Declaration",
  //       resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

  //     },

  //     {
  //       component: VendorCertificate,
  //       url: '/vendor-certificate',
  //       label: "Vendor DVA Declaration",
  //       resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

  //     },
  //     {
  //       component: VendorCertiticatePV,
  //       url: '/vendor-certificate-pv',
  //       label: "Vendor DVA Declaration",
  //       resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

  //     },



  //   ]
  // },

  /* Role: Product-line */
  {
    rolePLI: "Product-line",
    pageDetails: [
      {
        component: BOMProductLine,
        url: '/bom-validation',
        label: "BOM Validation"
      },
      // {
      //   component: MilesStone,
      //   url: '/milestones-calendar-cv',
      //   label: "Milestone Calendar"

      // },

      // {
      //   component: MilesStonePV,
      //   url: '/milestones-calendar-pv',
      //   label: "Milestone Calendar"

      // },
      // {
      //   component: 'MilesStoneEV',
      //   url: '/milestones-calendar-ev',
      //   label: "Milestone Calendar"

      // },

      // {
      //   component: addVCBC,
      //   url: '/add-vcbc-cv',
      //   label: "VC Declaration",
      //   resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

      // },

      // {
      //   component: AddVCBCPagePV,
      //   url: '/add-vcbc-pv',
      //   label: "VC Declaration",
      //   resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

      // },
      // {
      //   component: 'AddVCBCPageEV',
      //   url: '/add-vcbc-ev',
      //   label: "VC Declaration",
      //   resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

      // },
      // {
      //   component: PLIList,
      //   url: '/vcbc-list-cv',
      //   label: "Mass VC Declaration",
      //   resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

      // },
      // {
      //   component: PLIListPV,
      //   url: '/vcbc-list-pv',
      //   label: "Mass VC Declaration",
      //   resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

      // },
      // {
      //   component: 'PLIListEV',
      //   url: '/vcbc-list-ev',
      //   label: "Mass VC Declaration",
      //   resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

      // },
      // {
      //   component: VcBomData,
      //   url: '/vcbom-data',
      //   label: "VC BoM Data"
      // },

      // {
      //   component: VcBomDataPV,
      //   url: '/vcbom-data-pv',
      //   label: "VC BoM Data"
      // },
      // {
      //   component: 'VcBomDataEV',
      //   url: '/vcbom-data-ev',
      //   label: "VC BoM Data"
      // },

      // {
      //   component: DVACalculationCV,
      //   url: '/dva-calculation-cv',
      //   label: "DVA Calculation"
      // },
      // {
      //   component: DVACalculationPV,
      //   url: '/dva-calculation-pv',
      //   label: "DVA Calculation"
      // },
      // {
      //   component: 'DVACalculationEV',
      //   url: '/dva-calculation-ev',
      //   label: "DVA Calculation"
      // },
      // {
      //   component: MHIDataCV,
      //   url: '/mhi-data-cv',
      //   label: "MHI Data"
      // },
      // {
      //   component: MHIDataPV,
      //   url: '/mhi-data-pv',
      //   label: "MHI Data"
      // },
      // {
      //   component: 'MHIDataEV',
      //   url: '/mhi-data-ev',
      //   label: "MHI Data"
      // },
      // {
      //   component: MHIForm,
      //   url: '/mhi-data-form',
      //   label: "MHI Data Form"
      // },
      // {
      //   component: MHIForm,
      //   url: '/mhi-form-cv',
      //   label: 'MHI Form CV'
      // }
    ]
  },

  /* PMT Role */
  {
    rolePLI: "PMT",
    pageDetails: [
      {
        component: MHI_PMT,
        url: '/mhi-data-pmt',
        label: "MHI PMT Data",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]
      },
      {
        component: CreateDemoProject,
        url: '/new-demo-project',
        label: "Create Demo Project",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]
      },
      {
        component: FreezeUnFreeze,
        url: '/freeze-unfreeze',
        label: "Freeze Unfreeze",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]
      },
      {
        component: AnnualFinancialStatement,
        url: '/annual_financial_statement',
        label: "Annual Financial Statement",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]
      },
      {
        component: AFSMIS,
        url: '/AFSMIS',
        label: "AFS Report"
      },
      {
        component: AFSMasterData,
        url: '/afs_master_data-pv',
        label: "AFS Master Data",
      },
      {
        component: BOMProductLine2,
        url: '/bom-validation_2',
        label: "BOM Validation"
      },
      {
        component: PLReportMIS,
        url: '/pl-report',
        label: 'PL Report',
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component: PLReportMIS2,
        url: '/pl2-report',
        label: 'PL Report',
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component: PMTBOMReportMIS,
        url: '/pmt-bom-report',
        label: 'PMT BOM Report',
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component: BOM_PMT,
        url: '/unique-part-selection',
        label: "Unique Part Selection"
      },
      {
        component: SupplierDataPurchase,
        // url: '/Supplier-DVA-Collection-CV-Purchase',
        url: '/supplier-dva-collection',
        label: "Supplier DVA",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]
      },
      {
        component : BOMMaster,
        url : "/bom-master-data",
        label : "DVA Master Sheet Data",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component : VCWiseDVACalculation,
        url : "/vcwise-dva-calculation",
        label : "VC Wise DVA Calculation",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component : FinanceMaster,
        url : "/finance-master",
        label : "Finance Master",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component : ExemptedData,
        url : "/exempted-data",
        label : "Exempted Data",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component : Annexures,
        url : "/annexures",
        label : "Annexures",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component: SupplierDataPurchasePage3,
        url: '/Supplier-DVA-Collection-CV-Purchase-Page3',
        label: "Supplier DVA Page3",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]
      },
      {
        component: SupplierDataPurchasePV,
        url: '/Supplier-DVA-Collection-PV-Purchase',
        label: "Supplier DVA",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]
      },
      {
        component: SupplierUnderPLIDeclarationPMT,
        url: '/supplier-dva-declaration-for-pli_pmt',
        label: "Supplier Under PLI Declaration",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]
      },
      {
        component: PartBuyerMapping,
        url: '/part-buyer-mapping',
        label: "Part Buying Mapping",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component: VCSPOCMapping,
        url: '/vc-spoc-mapping',
        label: "VC SPOC Mapping",
      },
      {
        component: SupplierUnderPLIDeclarationMIS,
        url: '/supplier-pli-declaration-mis',
        label: 'Supplier Under PLI Declaration MIS'
      },
      {
        component: DVADeclarationMIS,
        url: '/supplier-dva-declaration-mis',
        label: 'DVA Declaration MIS'
      },
      {
        component : MpfdPurchaseTmlbsl,
        url : "/mpfd-purchase-tmlbsl",
        label : "MPFD",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component : Mpfd,
        url : "/mpfd",
        label : "MPFD",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component : AuditFile,
        url : "/audit-file",
        label : "Audit File",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component : DashBoard,
        url : "/dashboard",
        label : "Dashboard",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component : VendorMaster,
        url : "/vendor-master",
        label : "Vendor Master",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component : InvoiceUpload,
        url : "/invoice-upload",
        label : "Invoice Upload",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component: BOEUpload,
        url: '/boe-upload',
        label: "BOE Upload",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
    ]
  },

    /* Dashboard Role */
  {
    rolePLI: "Dashboard",
    pageDetails: [
      {
        component : DashBoard,
        url : "/dashboard",
        label : "Dashboard",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component: AFSMIS,
        url: '/AFSMIS',
        label: "AFS Report"
      },
      {
        component: PLReportMIS,
        url: '/pl-report',
        label: 'PL Report',
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component: PLReportMIS2,
        url: '/pl2-report',
        label: 'PL Report',
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component: PMTBOMReportMIS,
        url: '/pmt-bom-report',
        label: 'PMT BOM Report',
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
      {
        component: DVADeclarationMIS,
        url: '/supplier-dva-declaration-mis',
        label: 'DVA Declaration MIS'
      },
      {
        component: SupplierUnderPLIDeclarationMIS,
        url: '/supplier-pli-declaration-mis',
        label: 'Supplier Under PLI Declaration MIS'
      },
      {
        component: AFSMasterData,
        url: '/afs_master_data-pv',
        label: "AFS Master Data",
      },
      {
        component : BOMMaster,
        url : "/bom-master-data",
        label : "DVA Master Sheet Data",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },

    ]
  },
  /* BOE Role */
  {
    rolePLI: "BOE",
    pageDetails: [
      {
        component: BOM_BOE,
        url: '/bom-boe-data',
        label: "BOM BOE Data"
      },
      {
        component: BOM_BOE_ReportMIS,
        url: '/bom-boe-report',
        label: "BOM BOE Report"
      },
      {
        component: BOEUpload,
        url: '/boe-upload',
        label: "BOE Upload",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
    ]
  },
  /* GPA Role */
  {
    rolePLI: "GPA",
    pageDetails: [
      {
        component: MHIDataFormation,
        url: '/mhi-data-formation',
        label: "MHI Data Formation"
      },
      {
        component: MHIDataSubmission,
        url: '/mhi-data-submission',
        label: "MHI Data Submission"
      },
      {
        component : FinanceMaster,
        url : "/finance-master",
        label : "Finance Master",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
    ]
  },

  /* Finance Role */
  {
    rolePLI: "Finance",
    pageDetails: [
      {
        component : FinanceMaster,
        url : "/finance-master",
        label : "Finance Master",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      },
    ]
  },

  /* Role: Purchase */
  {
    rolePLI: "Purchase",
    pageDetails: [
      {
        component: AnnualFinancialStatement,
        url: '/annual_financial_statement',
        label: "Annual Financial Statement",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]
      },
      {
        component: AFSMIS,
        url: '/AFSMIS',
        label: "AFS Report"
      },
      {
        component: AFSMasterData,
        url: '/afs_master_data-pv',
        label: "AFS Master Data",
      },
      // {
      //   component: BOMProductLine,
      //   url: '/bom-validation',
      //   label: "BOM Validation"
      // },
      {
        component: BOM_PMT,
        url: '/unique-part-selection',
        label: "Unique Part Selection"
      },
      // {
      //   component: SupplierUnderPLIDeclarationPurchase,
      //   url: '/supplier-dva-declaration-for-pli_purchase',
      //   label: "Supplier Under PLI Declaration Purchase"
      // },
      {
        component: SupplierDataPurchase,
        // url: '/Supplier-DVA-Collection-CV-Purchase',
        url: '/supplier-dva-collection',
        label: "Supplier DVA",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]
      },
      // {
      //   component : BOMMaster,
      //   url : "/bom-master-data",
      //   label : "DVA Master Sheet Data",
      //   resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "sendWelcomeEmail", "sendSummaryEmail"]
      // },
      
      // {
      //   component: MilesStone,
      //   url: '/milestones-calendar-cv',
      //   label: "Milestone Calendar"
      // },

      // {
      //   component: MilesStonePV,
      //   url: '/milestones-calendar-pv',
      //   label: "Milestone Calendar"

      // },
      // {
      //   component: 'MilesStoneEV',
      //   url: '/milestones-calendar-ev',
      //   label: "Milestone Calendar"

      // },
      // {
      //   component: PLIList,
      //   url: '/vcbc-list-cv',
      //   label: "Mass VC Declaration"
      // },
      // {
      //   component: PLIListPV,
      //   url: '/vcbc-list-pv',
      //   label: "Mass VC Declaration"
      // },
      // {
      //   component: 'PLIListEV',
      //   url: '/vcbc-list-ev',
      //   label: "Mass VC Declaration"
      // },
      // {
      //   component: VcBomData,
      //   url: '/vcbom-data',
      //   label: "VC BoM Data",
      //   resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

      // },

      // {
      //   component: VcBomDataPV,
      //   url: '/vcbom-data-pv',
      //   label: "VC BoM Data",
      //   resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

      // },
      // {
      //   component: 'VcBomDataEV',
      //   url: '/vcbom-data-ev',
      //   label: "VC BoM Data",
      //   resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

      // },


      // {
      //   component: VendorCertificate,
      //   url: '/vendor-certificate',
      //   label: "Vendor DVA Declaration",
      //   resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

      // },
      // {
      //   component: VendorCertiticatePV,
      //   url: '/vendor-certificate-pv',
      //   label: "Vendor DVA Declaration",
      //   resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

      // },

      {
        component: AFSpurchase,
        url: '/AFSpurchase',
        label: "AFS Purchase Validation"
      },



      {
        component: SupplierDataPurchase,
        url: '/Supplier-DVA-Collection-CV-Purchase',
        label: "Supplier DVA",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

      },
      // {
      //   component: SupplierDataPurchasePage3,
      //   // url: '/Supplier-DVA-Collection-CV-Purchase-Page3',
      //   url: '/supplier-dva-declaration-for-pli',
      //   label: "Supplier DVA Page3",
      //   resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

      // },
      {
        component: SupplierDataPurchasePV,
        url: '/Supplier-DVA-Collection-PV-Purchase',
        label: "Supplier DVA",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

      },
      {
        component: SupplierUnderPLIVendor,
        // url: '/Supplier-DVA-Collection-PV-Purchase-Page3',
        url: '/supplier-dva-declaration-for-pli',
        label: "Supplier DVA Page3",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]
      },
      {
        component: SupplierUnderPLIDeclarationMIS,
        url: '/supplier-pli-declaration-mis',
        label: 'Supplier Under PLI Declaration MIS'
      },
      {
        component: DVADeclarationMIS,
        url: '/supplier-dva-declaration-mis',
        label: 'DVA Declaration MIS'
      },
    ]
  },

  /* Role: Purchase-CV */
  // {
  //   rolePLI: "Purchase-CV",
  //   pageDetails: [
  //     {
  //       component: MilesStone,
  //       url: '/milestones-calendar-cv',
  //       label: "Milestone Calendar"

  //     },

  //     {
  //       component: PLIList,
  //       url: '/vcbc-list-cv',
  //       label: "Mass VC Declaration"
  //     },

  //     {
  //       component: VcBomData,
  //       url: '/vcbom-data',
  //       label: "VC BoM Data",
  //       resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

  //     },


  //     {
  //       component: VendorCertificate,
  //       url: '/vendor-certificate',
  //       label: "Vendor DVA Declaration",
  //       resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

  //     },

  //   ]
  // },
  // {
  //   rolePLI: "Purchase-PV",
  //   pageDetails: [

  //     {
  //       component: MilesStonePV,
  //       url: '/milestones-calendar-pv',
  //       label: "Milestone Calendar"

  //     },

  //     {
  //       component: PLIListPV,
  //       url: '/vcbc-list-pv',
  //       label: "Mass VC Declaration"
  //     },
  //     {
  //       component: VcBomDataPV,
  //       url: '/vcbom-data-pv',
  //       label: "VC BoM Data",
  //       resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

  //     },


  //     {
  //       component: VendorCertiticatePV,
  //       url: '/vendor-certificate-pv',
  //       label: "Vendor DVA Declaration",
  //       resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

  //     },



  //   ]
  // }

]





function App() {
  const { isAuthenticated, user } = useAuthorizationContext();
  const [valideRoute, setValideRoute] = useState([]);

  const auth = useSelector(state => state.auth);

  const addLoggingHooks = () => {
    window.onerror = (message, source, lineno, colno, error) => {
      if (error.stack) {
        const sourceFunction = error.stack.split('\n')[1];
        console.error(new Error(message + sourceFunction));
      } else {
        console.error(error);
      }
    };
    const newConsole = overrideConsole(window.console, user?.userId);
    window.console = newConsole;
  };

  useEffect(() => {
    if (isAuthenticated) {
      addLoggingHooks();
    }
  }, [user]);

  const userPage = () => {
    if (auth.userRole.length >= 1) {
      const role = auth.userRole[0]
      console.log('page role: ', role);
      const validePath = MENU.filter(menu => menu.rolePLI === role);
        console.log(validePath[0].pageDetails,"pages",role)
      setValideRoute(validePath[0].pageDetails)
    }

  }
  useEffect(() => {
    userPage();
  }, [auth.userRole]);

  return (
    <div className="App">
      <Switch>
        <HeaderAndFooter>
          {valideRoute.map(({ component, url }) => (< PrivateRoute key={url} exact path={url} component={component} />))}

          <PrivateRoute exact path="/pli" component={Test} />


          {/* <PrivateRoute exact path="/add-vcbc-cv" component={addVCBC} />
          <PrivateRoute exact path="/add-vcbc-pv" component={AddVCBCPagePV} />
          <PrivateRoute exact path="/vcbc-list-cv" component={PLIList} />
          <PrivateRoute exact path="/vcbc-list-pv" component={PLIListPV} /> 
          <PrivateRoute exact path="/colour-vc-mis" component={ColourVcMIS} />
          <PrivateRoute exact path="/colour-vc-mis-pv" component={ColourVcMISPV} /> 
          <PrivateRoute exact path="/vendor-mis" component={VendorMIS} />
          <PrivateRoute exact path="/vendor-mis-pv" component={VendorMISPV} />
          <PrivateRoute exact path="/aa" component={Test} />
          <PrivateRoute exact path="/milestone1" component={milestone} />
          <PrivateRoute exact path="/milestones-calendar-cv" component={MilesStone} />
          <PrivateRoute exact path="/milestones-calendar-pv" component={MilesStonePV} />
          <PrivateRoute exact path="/vendor-dva-declaration" component={Vendordva} />
          <PrivateRoute exact path="/pli" component={Test2} />
          <PrivateRoute exact path="/vcbom-data" component={VcBomData} />
          <PrivateRoute exact path="/vcbom-data-pv" component={VcBomDataPV} />
          <PrivateRoute exact path="/vendor-data" component={VendarData} />
          <PrivateRoute exact path="/vendor-data-pv" component={VendarDataPV} />
          <PrivateRoute exact path="/dva-declaration" component={DVADeclaration} />
          <PrivateRoute exact path="/dva-calculation-cv" component={DVACalculationCV} />
          <PrivateRoute exact path="/dva-calculation-pv" component={DVACalculationPV} />
          <PrivateRoute exact path="/mhi-data-cv" component={MHIDataCV} />
          <PrivateRoute exact path="/mhi-data-pv" component={MHIDataPV} />
          <PrivateRoute exact path="/" component={LandingPage} /> */}
          <PrivateRoute exact path="/" component={LandingPage} />
          {/* <Route path='*' component={NotFoundPage}/> */}
          {/* <PrivateRoute  path='*' component={LandingPage}/>  */}

        </HeaderAndFooter>
      </Switch>

    </div>
  );
}

export default App;













// /* eslint-disable array-callback-return */
// /* eslint-disable no-unused-vars */
// import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux'

// import { Switch, Route, Routes } from 'react-router-dom';
// import PrivateRoute from './routing/PrivateRoute';
// import HeaderAndFooter from './layouts/HeaderAndFooter/HeaderAndFooter';
// import { overrideConsole } from './utils/loggingErrors';
// //  import { MENU } from './constants';

// import { useAuthorizationContext } from './providers/AuthorizationHandler/AuthorizationHandler';

// import Test from './pages/Test/Test';
// import LandingPage from './pages/LandingPage/LandingPage';
// import milestone from './pages/Milestones/Milestones';
// import Vendordva from './pages/Vendordva/Vendordva';
// import PLIList from './pages/PLIList/PLIList';
// import ColourVcMIS from './pages/ColourVCMIS/ColourVCMIS'
// import ColourVcMISPV from './pages/ColourVCMISPV/ColourVCMISPV'
// import VendorMIS from './pages/VendorMIS/VendorMIS';
// import VendorMISPV from './pages/VendorMISPV/VendorMISPV';
// import PLIListPV from './pages/PLIListPV/PLIListPV';
// import Test2 from './pages/Test2/Test2';
// import VcBomData from './pages/VcBomData/VcBomData';
// import VcBomDataPV from './pages/VcBomDataPV/VcBomDataPV';
// import VendarData from './pages/VendarData/VendarData';
// import VendarDataPV from './pages/VendarDataPV/VendarDataPV';
// import VendorCertificate from './pages/VendorCertificate/VendorCertificate';
// import VendorCertiticatePV from './pages/VendorCertificatePV/VendorCertificatePV';
// // import certificateupload from './pages/CertificateUpload/certificateupload';
// import addVCBC from './pages/AddVCBCPage/AddVCBCPage';
// import AddVCBCPagePV from './pages/AddVCBCPagePV/AddVCBCPagePV';
// import DVADeclaration from './pages/DVADeclaration/DVADeclaration'
// import DVACalculationCV from './pages/DVACalculationCV/DVACalculationCV'
// import MHIDataCV from './pages/MHIDataCV/MHIDataCV'
// import MHIDataPV from './pages/MHIDataPV/MHIDataPV'
// import DVACalculationPV from './pages/DVACalculationPV/DVACalculationPV'

// import AuditReport from './pages/AuditReport/AuditReport';
// import MilesStone from './pages/MilesStonePage/MilesStonePage';
// import MilesStonePV from './pages/MilesStonePagePV/MilesStonePagePV';





// const MENU = [
//   {
//     rolePLI: "admin",
//     pageDetails: [
//       {
//         component: AuditReport,
//         url: '/audit-report-cv',
//         label: "Audit"

//       },
//       {
//         component: AuditReport,
//         url: '/audit-report-pv',
//         label: "Audit"

//       },
//       {
//         component: AuditReport,
//         url: '/audit-report-ev',
//         label: "Audit"

//       },

//       {
//         component: VendorCertificate,
//         url: '/vendor-certificate',
//         label: "VendorCertificate"

//       },
//       {
//         component: VendorCertiticatePV,
//         url: '/vendor-certificate-pv',
//         label: "VendorCertificate"

//       },
//       {
//         component: MilesStone,
//         url: '/milestones-calendar-cv',
//         label: "Milestone Calendar"

//       },

//       {
//         component: MilesStonePV,
//         url: '/milestones-calendar-pv',
//         label: "Milestone Calendar"

//       },
//       {
//         component: 'MilesStoneEV',
//         url: '/milestones-calendar-ev',
//         label: "Milestone Calendar"

//       },
//       {
//         component: addVCBC,
//         url: '/add-vcbc-cv',
//         label: "VC Declaration"
//       },

//       {
//         component: AddVCBCPagePV,
//         url: '/add-vcbc-pv',
//         label: "VC Declaration",
//       },
//       {
//         component: 'AddVCBCPageEV',
//         url: '/add-vcbc-ev',
//         label: "VC Declaration"
//       },
//       {
//         component: PLIList,
//         url: '/vcbc-list-cv',
//         label: "Mass VC Declaration"
//       },
//       {
//         component: PLIListPV,
//         url: '/vcbc-list-pv',
//         label: "Mass VC Declaration"
//       },
//       {
//         component: 'PLIListEV',
//         url: '/vcbc-list-ev',
//         label: "Mass VC Declaration"
//       },
//       {
//         component: VcBomData,
//         url: '/vcbom-data',
//         label: "VC BoM Data"
//       },

//       {
//         component: VcBomDataPV,
//         url: '/vcbom-data-pv',
//         label: "VC BoM Data"
//       },
//       {
//         component: 'VcBomDataEV',
//         url: '/vcbom-data-ev',
//         label: "VC BoM Data"
//       },


//       {
//         component: VendarData,
//         url: '/vendor-data',
//         label: "Vendor DVA Declaration"
//       },
//       {
//         component: VendarDataPV,
//         url: '/vendor-data-pv',
//         label: "Vendor DVA Declaration"
//       },

//       {
//         component: 'VendarDataEV',
//         url: '/vendor-data-ev',
//         label: "Vendor DVA Declaration"
//       },
//       {
//         component: ColourVcMIS,
//         url: '/colour-vc-mis',
//         label: 'VC MIS-CV'
//       },
//       {
//         component: ColourVcMISPV,
//         url: '/colour-vc-mis-pv',
//         label: 'VC MIS-CV'
//       },
//       {
//         component: 'ColourVcMISEV',
//         url: '/colour-vc-mis-ev',
//         label: 'VC MIS-CV'
//       },
//       {
//         component: VendorMIS,
//         url: '/vendor-mis',
//         label: "Vendor MIS"
//       },
//       {
//         component: VendorMISPV,
//         url: '/vendor-mis-pv',
//         label: "Vendor MIS",
//       },
//       {
//         component: 'VendorMISEV',
//         url: '/vendor-mis-ev',
//         label: "Vendor MIS"
//       },

//       {
//         component: DVACalculationCV,
//         url: '/dva-calculation-cv',
//         label: "DVA Calculation"
//       },
//       {
//         component: DVACalculationPV,
//         url: '/dva-calculation-pv',
//         label: "DVA Calculation"
//       },
//       {
//         component: 'DVACalculationEV',
//         url: '/dva-calculation-ev',
//         label: "DVA Calculation"
//       },
//       {
//         component: MHIDataCV,
//         url: '/mhi-data-cv',
//         label: "MHI Data"
//       },
//       {
//         component: MHIDataPV,
//         url: '/mhi-data-pv',
//         label: "MHI Data"
//       },
//       {
//         component: 'MHIDataEV',
//         url: '/mhi-data-ev',
//         label: "MHI Data"
//       }
//     ]
//   },
//   {
//     rolePLI: "Vendor-CV",
//     pageDetails: [
//       {
//         component: VendarData,
//         url: '/vendor-data',
//         label: "Vendor DVA Declaration",
//         resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

//       },



//     ]
//   },
//   {
//     rolePLI: "Vendor-PV",
//     pageDetails: [
//       {
//         component: VendarDataPV,
//         url: '/vendor-data-pv',
//         label: "Vendor DVA Declaration",
//         resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

//       },


//     ]
//   },
//   {
//     rolePLI: "Vendor",
//     pageDetails: [
//       {
//         component: VendarDataPV,
//         url: '/vendor-data-pv',
//         label: "Vendor DVA Declaration",
//         resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

//       },
//       {
//         component: VendarData,
//         url: '/vendor-data',
//         label: "Vendor DVA Declaration",
//         resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

//       },


//     ]
//   },

//   {
//     rolePLI: "GDC",
//     pageDetails: [
//       {
//         component: MilesStone,
//         url: '/milestones-calendar-cv',
//         label: "Milestone Calendar"

//       },

//       {
//         component: MilesStonePV,
//         url: '/milestones-calendar-pv',
//         label: "Milestone Calendar"

//       },
//       {
//         component: 'MilesStoneEV',
//         url: '/milestones-calendar-ev',
//         label: "Milestone Calendar"

//       },
//       {
//         component: PLIList,
//         url: '/vcbc-list-cv',
//         label: "Mass VC Declaration"
//       },
//       {
//         component: PLIListPV,
//         url: '/vcbc-list-pv',
//         label: "Mass VC Declaration"
//       },
//       {
//         component: 'PLIListEV',
//         url: '/vcbc-list-ev',
//         label: "Mass VC Declaration"
//       },
//       {
//         component: VcBomData,
//         url: '/vcbom-data',
//         label: "VC BoM Data",
//         resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

//       },

//       {
//         component: VcBomDataPV,
//         url: '/vcbom-data-pv',
//         label: "VC BoM Data",
//         resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

//       },
//       {
//         component: 'VcBomDataEV',
//         url: '/vcbom-data-ev',
//         label: "VC BoM Data",
//         resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

//       },
//       {
//         component: VendarData,
//         url: '/vendor-data',
//         label: "Vendor DVA Declaration",
//         resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

//       },
//       {
//         component: VendarDataPV,
//         url: '/vendor-data-pv',
//         label: "Vendor DVA Declaration",
//         resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

//       },

//       {
//         component: VendorCertificate,
//         url: '/vendor-certificate',
//         label: "Vendor DVA Declaration",
//         resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

//       },
//       {
//         component: VendorCertiticatePV,
//         url: '/vendor-certificate-pv',
//         label: "Vendor DVA Declaration",
//         resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

//       },



//     ]
//   }
//   ,
//   {
//     rolePLI: "Product-line",
//     pageDetails: [
//       {
//         component: MilesStone,
//         url: '/milestones-calendar-cv',
//         label: "Milestone Calendar"

//       },

//       {
//         component: MilesStonePV,
//         url: '/milestones-calendar-pv',
//         label: "Milestone Calendar"

//       },
//       {
//         component: 'MilesStoneEV',
//         url: '/milestones-calendar-ev',
//         label: "Milestone Calendar"

//       },

//       {
//         component: addVCBC,
//         url: '/add-vcbc-cv',
//         label: "VC Declaration",
//         resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

//       },

//       {
//         component: AddVCBCPagePV,
//         url: '/add-vcbc-pv',
//         label: "VC Declaration",
//         resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

//       },
//       {
//         component: 'AddVCBCPageEV',
//         url: '/add-vcbc-ev',
//         label: "VC Declaration",
//         resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

//       },
//       {
//         component: PLIList,
//         url: '/vcbc-list-cv',
//         label: "Mass VC Declaration",
//         resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

//       },
//       {
//         component: PLIListPV,
//         url: '/vcbc-list-pv',
//         label: "Mass VC Declaration",
//         resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

//       },
//       {
//         component: 'PLIListEV',
//         url: '/vcbc-list-ev',
//         label: "Mass VC Declaration",
//         resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

//       },
//       {
//         component: VcBomData,
//         url: '/vcbom-data',
//         label: "VC BoM Data"
//       },

//       {
//         component: VcBomDataPV,
//         url: '/vcbom-data-pv',
//         label: "VC BoM Data"
//       },
//       {
//         component: 'VcBomDataEV',
//         url: '/vcbom-data-ev',
//         label: "VC BoM Data"
//       },

//       {
//         component: DVACalculationCV,
//         url: '/dva-calculation-cv',
//         label: "DVA Calculation"
//       },
//       {
//         component: DVACalculationPV,
//         url: '/dva-calculation-pv',
//         label: "DVA Calculation"
//       },
//       {
//         component: 'DVACalculationEV',
//         url: '/dva-calculation-ev',
//         label: "DVA Calculation"
//       },
//       {
//         component: MHIDataCV,
//         url: '/mhi-data-cv',
//         label: "MHI Data"
//       },
//       {
//         component: MHIDataPV,
//         url: '/mhi-data-pv',
//         label: "MHI Data"
//       },
//       {
//         component: 'MHIDataEV',
//         url: '/mhi-data-ev',
//         label: "MHI Data"
//       }

//     ]
//   },
//   {
//     rolePLI: "Purchase",
//     pageDetails: [
//       {
//         component: MilesStone,
//         url: '/milestones-calendar-cv',
//         label: "Milestone Calendar"

//       },

//       {
//         component: MilesStonePV,
//         url: '/milestones-calendar-pv',
//         label: "Milestone Calendar"

//       },
//       {
//         component: 'MilesStoneEV',
//         url: '/milestones-calendar-ev',
//         label: "Milestone Calendar"

//       },
//       {
//         component: PLIList,
//         url: '/vcbc-list-cv',
//         label: "Mass VC Declaration"
//       },
//       {
//         component: PLIListPV,
//         url: '/vcbc-list-pv',
//         label: "Mass VC Declaration"
//       },
//       {
//         component: 'PLIListEV',
//         url: '/vcbc-list-ev',
//         label: "Mass VC Declaration"
//       },
//       {
//         component: VcBomData,
//         url: '/vcbom-data',
//         label: "VC BoM Data",
//         resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

//       },

//       {
//         component: VcBomDataPV,
//         url: '/vcbom-data-pv',
//         label: "VC BoM Data",
//         resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

//       },
//       {
//         component: 'VcBomDataEV',
//         url: '/vcbom-data-ev',
//         label: "VC BoM Data",
//         resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

//       },


//       {
//         component: VendorCertificate,
//         url: '/vendor-certificate',
//         label: "Vendor DVA Declaration",
//         resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

//       },
//       {
//         component: VendorCertiticatePV,
//         url: '/vendor-certificate-pv',
//         label: "Vendor DVA Declaration",
//         resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

//       },
//     ]
//   },
//   {
//     rolePLI: "Purchase-CV",
//     pageDetails: [
//       {
//         component: MilesStone,
//         url: '/milestones-calendar-cv',
//         label: "Milestone Calendar"

//       },

//       {
//         component: PLIList,
//         url: '/vcbc-list-cv',
//         label: "Mass VC Declaration"
//       },

//       {
//         component: VcBomData,
//         url: '/vcbom-data',
//         label: "VC BoM Data",
//         resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

//       },


//       {
//         component: VendorCertificate,
//         url: '/vendor-certificate',
//         label: "Vendor DVA Declaration",
//         resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

//       },

//     ]
//   },
//   {
//     rolePLI: "Purchase-PV",
//     pageDetails: [

//       {
//         component: MilesStonePV,
//         url: '/milestones-calendar-pv',
//         label: "Milestone Calendar"

//       },

//       {
//         component: PLIListPV,
//         url: '/vcbc-list-pv',
//         label: "Mass VC Declaration"
//       }, 
//       {
//         component: VcBomDataPV,
//         url: '/vcbom-data-pv',
//         label: "VC BoM Data",
//         resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

//       },


//       {
//         component: VendorCertiticatePV,
//         url: '/vendor-certificate-pv',
//         label: "Vendor DVA Declaration",
//         resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach"]

//       },



//     ]
//   }

// ]





// function App() {
//   const { isAuthenticated, user } = useAuthorizationContext();
//   const [valideRoute, setValideRoute] = useState([]);

//   const auth = useSelector(state => state.auth);

//   const addLoggingHooks = () => {
//     window.onerror = (message, source, lineno, colno, error) => {
//       if (error.stack) {
//         const sourceFunction = error.stack.split('\n')[1];
//         console.error(new Error(message + sourceFunction));
//       } else {
//         console.error(error);
//       }
//     };
//     const newConsole = overrideConsole(window.console, user?.userId);
//     window.console = newConsole;
//   };

//   useEffect(() => {
//     if (isAuthenticated) {
//       addLoggingHooks();
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [user]);

//   const userPage = () => {
//     if (auth.userRole.length >= 1) {
//       const role = auth.userRole[0];
//       const validePath = MENU.filter(menu => menu.rolePLI === role);
//       //   console.log(validePath[0].pageDetails,"pages",role)
//       setValideRoute(validePath[0].pageDetails)
//     }

//   }
//   useEffect(() => {
//     userPage();
//   }, [auth.userRole]);

//   return (
//     <div className="App">
//       <Switch>
//         <HeaderAndFooter>
//           {valideRoute.map(({ component, url }) => (< PrivateRoute key={url} exact path={url} component={component} />))}

//           <PrivateRoute exact path="/pli" component={Test} />


//           {/* <PrivateRoute exact path="/add-vcbc-cv" component={addVCBC} />
//           <PrivateRoute exact path="/add-vcbc-pv" component={AddVCBCPagePV} />
//           <PrivateRoute exact path="/vcbc-list-cv" component={PLIList} />
//           <PrivateRoute exact path="/vcbc-list-pv" component={PLIListPV} /> 
//           <PrivateRoute exact path="/colour-vc-mis" component={ColourVcMIS} />
//           <PrivateRoute exact path="/colour-vc-mis-pv" component={ColourVcMISPV} /> 
//           <PrivateRoute exact path="/vendor-mis" component={VendorMIS} />
//           <PrivateRoute exact path="/vendor-mis-pv" component={VendorMISPV} />
//           <PrivateRoute exact path="/aa" component={Test} />
//           <PrivateRoute exact path="/milestone1" component={milestone} />
//           <PrivateRoute exact path="/milestones-calendar-cv" component={MilesStone} />
//           <PrivateRoute exact path="/milestones-calendar-pv" component={MilesStonePV} />
//           <PrivateRoute exact path="/vendor-dva-declaration" component={Vendordva} />
//           <PrivateRoute exact path="/pli" component={Test2} />
//           <PrivateRoute exact path="/vcbom-data" component={VcBomData} />
//           <PrivateRoute exact path="/vcbom-data-pv" component={VcBomDataPV} />
//           <PrivateRoute exact path="/vendor-data" component={VendarData} />
//           <PrivateRoute exact path="/vendor-data-pv" component={VendarDataPV} />
//           <PrivateRoute exact path="/dva-declaration" component={DVADeclaration} />
//           <PrivateRoute exact path="/dva-calculation-cv" component={DVACalculationCV} />
//           <PrivateRoute exact path="/dva-calculation-pv" component={DVACalculationPV} />
//           <PrivateRoute exact path="/mhi-data-cv" component={MHIDataCV} />
//           <PrivateRoute exact path="/mhi-data-pv" component={MHIDataPV} />
//           <PrivateRoute exact path="/" component={LandingPage} /> */}
//           <PrivateRoute exact path="/" component={LandingPage} />
//           {/* <PrivateRoute  path='*' component={LandingPage}/>  */}

//         </HeaderAndFooter>
//       </Switch>

//     </div>
//   );
// }

// export default App;
