/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unneeded-ternary */

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Form,
  Box,
  TextField,
  Grid,
  FormGroup,
  IconButton
} from '@material-ui/core'
import WarningIcon from '@mui/icons-material/Warning'
// import FormLabel from '@mui/material/FormLabel'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
// for print user in consol
import jwt_decode from 'jwt-decode'
import { useSelector } from 'react-redux'
// for print user in consol
import axios from 'axios'

import clsx from 'clsx'
// import $ from 'jquery';
import { useHistory } from 'react-router-dom'
import Table from '../../components/Table1/Table1'
import styles from './FreezeUnFreeze.module.css'
import {
  CustomFormGroup,
  CustomSelect,
  ValidatingTextField
} from '../../components/FormComponents'
import BackButton from '../../components/BackButton/BackButton'
import {
  DATE_FORMAT,
  API_RESOURCE_URLS,
  DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  MESSAGE_TYPE_CERTIFICATION,
  MESSAGE_TYPE_ANNEXURE,
  RESOURCE_TYPE,
  USER_OPERATIONS,
  Regex
} from '../../constants'
import { withAllowedOperationsProvider } from '../../hocs'
import { CustomTab, Button, AuthChecker } from '../../atomicComponents'
import { usePopupManager } from '../../providers/PopupManager/PopupManager'
import { GridLoadingSpinner } from '../../components'

const PartBuyerMapping = () => {
  const history = useHistory()
  const { showPopup } = usePopupManager()
  const baseUrl = useSelector(state => state.counter.baseUrl)
  const auth = useSelector(state => state.auth)
  console.log('auth in Supplier: ', auth)
  // const [dynamicColumns, setDynamicColumns] = useState([])
  // const [dynamicColumns1, setDynamicColumns1] = useState([])
  const [plant, setPlant] = useState(null)
  const [flag, show] = useState(false)
  // const [checkDisable, setDisable] = useState(true)
  // const [projectCode, setProjectCode] = useState(null)
  const [projectCodeArray, setProjectCodeArray] = useState([])
  const [isLatestProjectSelected, setIsLatestProjectSelected] = useState(false) // New state for button enable/disable
  // const [projectCodeArray, setProjectCodeArray] = useState([]);
  const [selectedProjectCode, setSelectedProjectCode] = useState(null)
  console.log('Project state: ', selectedProjectCode)
  const [latestProject, setLatestProject] = useState('')
  // const [Bc, setBC] = useState(null)
  // const [bcArray, setBcArray] = useState([])
  // const [plantArray, setPlantArray] = useState([])
  const [tableArray, setTableArray] = useState([])
  const [freezeUnFreezeStatus, setFreezeUnFreezeStatus] = useState({})
  // const [fyArray, setFyArray] = useState([])
  // const [Fy, setFY] = useState(null)
  // const [quarterArray, setQuarterArray] = useState([])
  // const [quarter, setQuarter] = useState(null)
  const [asnCount, setAsnCount] = useState(0)
  const [loading, setLoader] = useState(false)
  const [loader, setLoading] = useState(true)
  const [projectDescription, setProjectDescription] = useState('')

  //
  // const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false)
  // const handleOpenFormModal = () => {
  //   setPopupOpenFormModal(true)
  // }
  // const handleCloseFormModal = () => setPopupOpenFormModal(false)

  //

  // const [confirmWelcomeEmailPopup, setConfirmWelcomeEmailPopup] =
  //   useState(false)
  const [confirmFreezePopup, setConfirmFreezePopup] = useState(false)
  const handleOpenConfirmFreezePopup = () => setConfirmFreezePopup(true)
  const handleCloseFreezeModal = () => setConfirmFreezePopup(false)

  const [confirmUnFreezePopup, setConfirmUnFreezePopup] = useState(false)
  const handleOpenConfirmUnFreezePopup = () => setConfirmUnFreezePopup(true)
  const handleCloseUnFreezeModal = () => setConfirmUnFreezePopup(false)

  // const handleOpenConfirmEmailPopup = () => setConfirmWelcomeEmailPopup(true)
  // const handleCloseConfirmEmailPopup = () => setConfirmWelcomeEmailPopup(false)

  const [reason, setReason] = useState('')

  // const ROW_HEIGHT = 38

  // const [isPopupOpen, setIsPopupOpen] = useState(false)
  // const handleOpen = () => setIsPopupOpen(true)
  // const handleClose = () => setIsPopupOpen(false)
  // const goNext = () => {
  //   uploadFileData()
  // }

  const redirectToApplicationLandingPage = () => {
    history.push('/')
  }

  const setCount = count => {
    setAsnCount(count)
  }

  //   const getTableData = async (param) => {
  //     const payload = { Project_code: param };

  //     try {
  //         const response = await axios.post(`${baseUrl}PurchaseBuyerMapping/`, payload, {
  //             headers: {
  //                 'Content-Type': 'application/json'
  //             }
  //         });

  //         console.log("Response JSON:", response.data);

  //         if (Array.isArray(response.data)) {
  //             setTableArray(response.data);
  //         } else {
  //             console.error('Response is not an array:', response.data);
  //             setTableArray([]);
  //         }
  //     } catch (error) {
  //         if (error.response) {
  //             const { status, data } = error.response;
  //             if (status === 400) {
  //                 console.error('Bad Request:', data);
  //             } else if (status === 404) {
  //                 console.error('Not Found:', data);
  //             } else if (status === 500) {
  //                 console.error('Internal Server Error:', data);
  //             } else {
  //                 console.error('Error fetching table data:', data);
  //             }
  //         } else {
  //             console.error('Network or other error:', error.message);
  //         }
  //         setTableArray([]);
  //     }
  // };

  const convertToIST = utcDateString => {
    const date = new Date(utcDateString)

    // Convert to IST (UTC+5:30)
    const istOffset = 5.5 * 60 * 60 * 1000 // 5 hours 30 minutes in milliseconds
    const istDate = new Date(date.getTime() + istOffset)

    // Format the date as DD-MM-YYYY HH:MM
    const day = String(istDate.getDate()).padStart(2, '0')
    const month = String(istDate.getMonth() + 1).padStart(2, '0') // Months are 0-based
    const year = istDate.getFullYear()
    const hours = String(istDate.getHours()).padStart(2, '0')
    const minutes = String(istDate.getMinutes()).padStart(2, '0')

    return `${day}-${month}-${year} ${hours}:${minutes}`
  }

  // Example Usage
  const utcDate = '2024-09-13 13:25:48.097000+00:00'
  console.log(convertToIST(utcDate)) // Output: "13-09-2024 18:55"

  const getTableData = async param => {
    const payload = { Project_code: param }

    try {
      setLoader(true)
      const [tableDataResponse, RetrieveLatestFreezeUnfreezeStatusResponse] =
        await Promise.all([
          // axios.post(`${baseUrl}PurchaseBuyerMapping/`, payload, {
          axios.post(`${baseUrl}ProjectFreezRetrieveView/`, payload, {
            headers: {
              'Content-Type': 'application/json'
            }
          }),
          axios.post(`${baseUrl}RetrieveLatestFreezeUnfreezeStatus/`, payload, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
        ])

      console.log('Table Data Response JSON:', tableDataResponse.data?.records)
      console.log('Table status:', tableDataResponse.data?.status)
      console.log(
        'Buyer Part Mapping Response JSON:',
        RetrieveLatestFreezeUnfreezeStatusResponse.data
      )

      const formattedData = tableDataResponse.data?.records?.map(
        ({
          id,
          Project_code,
          Project_Description,
          Creation_date,
          Freeze_date,
          Freeze_reason,
          Current_freeze_unfreeze_status,
          Unfreeze_date,
          Unfreeze_reason
        }) => ({
          id,
          Project_code,
          Project_Description,
          Creation_date: convertToIST(Creation_date),
          Freeze_date: convertToIST(Freeze_date),
          Freeze_reason,
          Current_freeze_unfreeze_status,
          Unfreeze_date: convertToIST(Unfreeze_date),
          Unfreeze_reason
        })
      )
      console.log('newExcel: ', formattedData)

      // if (Array.isArray(tableDataResponse.data?.records)) {
      //   setTableArray(tableDataResponse.data?.records)
      if (Array.isArray(formattedData)) {
        setTableArray(formattedData)
        show(true)
        setLoading(false)
      } else {
        console.error(
          `Table data response is not an array: ${JSON.stringify(
            tableDataResponse.data
          )}`
        )
        setTableArray([])
        show(false)
      }

      // if (Array.isArray(RetrieveLatestFreezeUnfreezeStatusResponse.data)) {
      if (typeof RetrieveLatestFreezeUnfreezeStatusResponse.data === 'object') {
        setFreezeUnFreezeStatus(RetrieveLatestFreezeUnfreezeStatusResponse.data)
      } else {
        console.error(
          `Buyer part mapping response is not an array: ${JSON.stringify(
            RetrieveLatestFreezeUnfreezeStatusResponse.data
          )}`
        )
        setFreezeUnFreezeStatus({})
      }
      setLoader(false)
    } catch (error) {
      setLoader(false)
      let errorMessage
      if (error.response) {
        const { status, data } = error.response
        switch (status) {
          case 400:
            errorMessage = `Bad Request: ${
              data.message || 'The server could not understand the request.'
            }`
            break
          case 404:
            errorMessage = `Not Found: ${
              data.message || 'The requested resource could not be found.'
            }`
            break
          case 500:
            errorMessage = `Internal Server Error: ${
              data.message || 'The server encountered an error.'
            }`
            break
          default:
            errorMessage = `Error fetching data: ${
              data.message || 'An unexpected error occurred.'
            }`
        }
        console.error(errorMessage)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: data?.error
        })
      } else {
        errorMessage = `Network or other error: ${
          error.message || 'An unknown error occurred.'
        }`
        console.error(errorMessage)
      }
      setTableArray([])
      setFreezeUnFreezeStatus([])
    }
  }

  const getAllProjectCode = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}AllProjectCodeforProductline/`
        // `${baseUrl}AllProjectCodeforVendor/`
      )
      const allProjectCodes = data.map(({ Project_code }) => Project_code)
      const uniqueProjects = [...new Set(allProjectCodes)]
      const projectCodeOptions = uniqueProjects.map(value => ({
        value,
        label: value
      }))

      setProjectCodeArray(projectCodeOptions)
      await getRecentProjectCode(projectCodeOptions)
    } catch (error) {
      console.error('Error fetching project codes:', error)
    }
  }

  const getRecentProjectCode = async projectCodeOptions => {
    try {
      const { data, status } = await axios.get(
        `${baseUrl}LatestProjectCodeforProductline/`
      )
      if (status !== 200) {
        throw new Error('Failed to fetch the latest project code')
      }
      const recentProj = {
        label: data?.Project_code,
        value: data?.Project_code
      }
      setLatestProject(recentProj)
      setSelectedProjectCode(recentProj) // latest project code
    } catch (error) {
      console.error('Error fetching recent project codes:', error)
    }
  }

  useEffect(() => {
    getAllProjectCode()
  }, [])

  useEffect(() => {
    if (latestProject) {
      setSelectedProjectCode(latestProject)
    }
  }, [latestProject])

  // Check if the selected project code is the latest project code
  useEffect(() => {
    if (selectedProjectCode && latestProject) {
      setIsLatestProjectSelected(
        selectedProjectCode.value === latestProject.value
      )
    }
  }, [selectedProjectCode, latestProject])

  useEffect(() => {
    if (selectedProjectCode) {
      // getVcForProjectCode(selectedProjectCode)
      getProjectDescription()
    }
  }, [selectedProjectCode])

  const handleDropdownChange = (selectedOption, name) => {
    if (name === 'Project_Code') {
      setSelectedProjectCode(selectedOption)
      getProjectDescription()
    }
  }

  const getProjectDescription = async () => {
    try {
      const headerss = { 'Content-Type': 'application/json' }
      const body = { Project_code: selectedProjectCode?.value }
      const { data } = await axios.post(
        `${baseUrl}ProjectDescriptionView/`,
        body,
        { headers: headerss }
      )
      // console.log('pro desc: ', data)
      setProjectDescription(data?.Project_Description)
    } catch (error) {
      console.error('Error fetching project description:', error)
    }
  }

  const showTable = () => {
    if (selectedProjectCode === null || selectedProjectCode.value === '') {
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: 'Project Code is Mandatory'
      })
    } else {
      getTableData(selectedProjectCode.value)
      // show(true)
      // setLoading(false)
    }
  }

  // const uploadFileData = () => {
  //   const input = document.createElement('input')
  //   input.type = 'file'
  //   input.id = 'file'
  //   input.setAttribute(
  //     'accept',
  //     '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
  //   )
  //   input.click()

  //   input.onchange = async e => {
  //     setLoader(true)
  //     const formData = new FormData()
  //     formData.append('file', e.target.files[0])

  //     setLoader(true)
  //     try {
  //       const response = await axios.post(
  //         `${baseUrl}UploadPurchaseBuyerMapping/`,
  //         formData
  //       )

  //       // Extract message from response data
  //       const message = response.data.status || 'File uploaded successfully'

  //       if (response.status === 200 || response.status === 201) {
  //         showPopup({
  //           type: MESSAGE_TYPE.SUCCESS,
  //           contextText: MESSAGE_TYPE.SUCCESS,
  //           info: message
  //         })
  //         getTableData(selectedProjectCode.value)
  //       } else {
  //         showPopup({
  //           type: MESSAGE_TYPE.FAILURE,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: message
  //         })
  //       }
  //     } catch (error) {
  //       console.error('Error during file upload:', error)

  //       // Extract error message from response if available
  //       const errorMessage =
  //         error?.response &&
  //         error?.response?.data &&
  //         error?.response?.data?.error
  //           ? error?.response.data.error
  //           : error?.message || 'File upload failed due to an error'

  //       // Show failure message in popup
  //       showPopup({
  //         type: MESSAGE_TYPE.FAILURE,
  //         contextText: MESSAGE_TYPE.FAILURE,
  //         info: errorMessage
  //       })
  //       console.log('error MSg: ', errorMessage)

  //       // Optionally throw the error message if needed for further handling
  //       // throw new Error(errorMessage)
  //     } finally {
  //       setLoader(false)
  //     }
  //     setLoader(false)
  //   }
  // }

  // const exportExcel = async excelData => {
  //   var fileTypel =
  //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  //   var fileExt = '.xlsx'
  //   const ws = XLSX.utils.json_to_sheet(excelData)
  //   const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
  //   const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' })
  //   const data = new Blob([excelBuffer], { type: fileTypel })
  //   FileSaver.saveAs(data, `Part_Buyer_Mapping${fileExt}`)
  // }

  const exportExcel = async excelData => {
    setLoader(true)

    // Map the data and include the "Send E-mail" field correctly
    const newExcelData = excelData?.map(
      ({
        Project_code,
        Project_Description,
        Creation_date,
        Freeze_date,
        Freeze_reason,
        Current_freeze_unfreeze_status
      }) => ({
        Project_code,
        Project_Description,
        Creation_date,
        Freeze_Unfreeze_Date: Freeze_date,
        Freeze_Unfreeze_Reason: Freeze_reason,
        Current_freeze_unfreeze_status
      })
    )

    console.log('new excel: ', newExcelData)

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExt = '.xlsx'

    // Generate the Excel sheet
    const ws = XLSX.utils.json_to_sheet(newExcelData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' })

    // Create a Blob and trigger the download
    const data = new Blob([excelBuffer], { type: fileType })
    setLoader(false)
    FileSaver.saveAs(data, `Freeze_UnFreeze_Project_Code_List${fileExt}`)
  }

  const baseDefaultColumns = [
    {
      width: 150,
      title: 'Project Code',
      field: 'Project_code',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'Project Description',
      field: 'Project_Description',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'Create Date',
      field: 'Creation_date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      title: 'Freeze/Unfreeze Date',
      field: 'Freeze_date',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 150,
    //   title: 'Unfreeze Date',
    //   field: 'Part_no',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    {
      width: 230,
      title: 'Freeze/Unfreeze Reason',
      field: 'Freeze_reason',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Status',
      field: 'Current_freeze_unfreeze_status',
      enableSearch: true,
      enableFilter: true
    }
    // {
    //   width: 170,
    //   title: 'Unfreeze Reason',
    //   field: 'Buyer_ID',
    //   enableSearch: true,
    //   enableFilter: true
    // }
    // {
    //   width: 150,
    //   title: 'SPOC',
    //   field: 'SPOC',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    // {
    //   width: 130,
    //   title: 'CTL',
    //   field: 'CTL',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    // {
    //   width: 130,
    //   title: 'Send E-mail(Yes/No)',
    //   field: 'Test4',
    //   enableSearch: true,
    //   enableFilter: true
    // }
  ]

  // const BOMvalidationAPI = () => {
  //   console.log('inside submit api: ', selectedProjectCode?.value)

  //   if (!selectedProjectCode?.value) {
  //     console.error('Project code is missing.')
  //     showPopup({
  //       type: MESSAGE_TYPE.FAILURE,
  //       contextText: MESSAGE_TYPE.FAILURE,
  //       info: 'Project code is required.'
  //     })
  //     return
  //   }

  //   const apiBody = {
  //     Project_code: selectedProjectCode.value
  //   }
  //   setLoader(true)
  //   axios
  //     .post(`${baseUrl}BuyerPartMappingSubmissionstatus/`, apiBody, {
  //       headers: {
  //         'Content-Type': 'application/json'
  //       }
  //     })
  //     .then(response => {
  //       console.log('responseJson', response.data)
  //       if (response.data.error) {
  //         throw new Error(response.data.error)
  //       }
  //       showPopup({
  //         type: MESSAGE_TYPE.SUCCESS,
  //         contextText: MESSAGE_TYPE.SUCCESS,
  //         info: 'Part Buyer Mapping Submitted Successfully'
  //       })
  //       history.push('/')
  //     })
  //     .catch(error => {
  //       let errorMessage = error.response?.data?.details
  //         ? JSON.parse(error.response.data.details).messages
  //         : error.response?.data?.error || error.message
  //       console.error('Error in submit:', errorMessage)
  //       showPopup({
  //         type: MESSAGE_TYPE.FAILURE,
  //         contextText: MESSAGE_TYPE.FAILURE,
  //         info: `Error: ${errorMessage}`
  //       })
  //     })
  //   setLoader(false)
  // }

  // const SendWelcomeEmail = submitDate => {
  //   let headerss = new Headers()
  //   headerss.append('Content-Type', 'application/json')

  //   const payload = {
  //     Project_code: selectedProjectCode.value, // Use the selected project code
  //     submit_date: submitDate // Use the date from the input
  //   }

  //   fetch(`${baseUrl}SendEmailToAllVendors_PV_New_change/`, {
  //     method: 'POST',
  //     headers: headerss,
  //     body: JSON.stringify(payload) // Add payload to the request body
  //   })
  //     .then(response =>
  //       response.json().then(jsonRes => ({
  //         status: response.status,
  //         body: jsonRes
  //       }))
  //     )
  //     .then(responseJson => {
  //       if (responseJson?.status < 400) {
  //         showPopup({
  //           type: MESSAGE_TYPE.SUCCESS,
  //           contextText: MESSAGE_TYPE.SUCCESS,
  //           info: 'Email sent successfully!'
  //         })
  //       } else {
  //         showPopup({
  //           type: MESSAGE_TYPE.FAILURE,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: 'Email trigger failed!'
  //         })
  //       }
  //     })
  //     .catch(error => {
  //       showPopup({
  //         type: MESSAGE_TYPE.FAILURE,
  //         contextText: MESSAGE_TYPE.FAILURE,
  //         info: error
  //       })
  //     })
  // }

  const onChangeHandler = event => {
    console.log('event name: ', event?.target?.value)

    const { name, value } = event.target
    const regex = /^[A-Za-z0-9 .,']*$/
    if (regex.test(value)) {
      setReason(value)
    } else {
      console.log('Invalid character entered!', value)
    }
  }

  const renderHTML = () => (
    <div className={styles.formGroup}>
      {loading && (
        <div className={styles.gridLoader}>
          <GridLoadingSpinner />
        </div>
      )}
      <Grid
        container
        spacing={2}
        style={{ display: 'flex', justifyContent: 'left' }}
        columns={12}
      >
        <Grid
          item
          // sm={12}
          md={4}
          lg={4}
          // style={{ marginTop: "5px", marginBottom: "5px" }}
        >
          <div
            className={styles.rowDiv}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div
              className={clsx(styles.lableDiv)}
              style={{ width: '40%', display: 'flex', alignItems: 'center' }}
            >
              <label className={styles.label}>Project Code</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name='Project Code'
                options={projectCodeArray}
                className={clsx(styles.select, styles.sel1)}
                value={selectedProjectCode}
                isMulti={false}
                defaultValue={latestProject}
                isClearable
                isMandatory
                onChange={selectedOption => {
                  setSelectedProjectCode(selectedOption)
                  //   getVcForProjectCode(selectedOption); // Fetch VC for selected project code
                }}
              />
            </div>
          </div>
        </Grid>

        <Grid
          item
          xs={5}
          md={5}
          lg={5}
          style={{
            // marginTop: '5px',
            display: 'flex',
            // justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div
            className={styles.rowDiv}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div className={clsx(styles.lableDiv)} style={{ width: '26%' }}>
              <label className={styles.label}>Project Desc.</label>
            </div>
            {/* <div className={styles.formDiv}>
                  <Grid item xs={3} className={styles.alignCenter}> */}
            <div className={styles.formDiv}>
              <ValidatingTextField
                variant='outlined'
                size='small'
                fullWidth
                // value={vendorCode?.vendorCode}
                // value={recentProjectCodeDescription && recentProjectCodeDescription}
                value={projectDescription && projectDescription}
                name='projDesc'
                inputProps={{
                  'data-testid': 'remark-input'
                }}
                onChange={selectedOption =>
                  handleDropdownChange(selectedOption)
                }
                // eslint-disable-next-line react/jsx-boolean-value
                isDisabled={true}
              />
            </div>
            {/* <span className={styles.symbolCSS}>&#x20b9;</span> */}
            {/* </Grid> */}
            {/* </div> */}
          </div>
        </Grid>

        <Grid
          item
          md={3}
          lg={3}
          // sm={12}
          // style={{ marginTop: "5px", marginBottom: "5px" }}
        >
          <div
            style={{
              marginLeft: '5px',
              paddingRight: '10px',
              marginTop: '1px',
              display: 'flex',
              justifyContent: 'right'
            }}
          >
            <AuthChecker operation='search'>
              {isAuthorized => (
                <Button
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  style={{ marginRight: '12px !important' }}
                  variant='primary'
                  onClick={showTable}
                  disabled={!isAuthorized}
                  data-testid='confirm-action'
                >
                  Show Data
                </Button>
              )}
            </AuthChecker>
          </div>
        </Grid>

        {/* Reason */}
        <Grid item md={5} lg={5}>
          <div className={styles.rowDiv} style={{ gap: '10px' }}>
            <label className={styles.label} style={{ margin: '0px 5px' }}>
              Reason
            </label>
            <ValidatingTextField
              variant='outlined'
              size='small'
              fullWidth
              value={reason || ''}
              name='reason'
              inputProps={{
                'data-testid': 'part-desc-input',
                style: { color: '#707070' }
              }}
              onChange={event => onChangeHandler(event)}
              isDisabled={!flag}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )

  const freezeFuntionPopup = action => {
    console.log('action in popup: ', action)

    if (
      reason?.length === 0 ||
      reason === undefined ||
      reason === '' ||
      reason === null
    ) {
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: 'Reason is required.'
      })
      return false
    }

    if (reason.length < 2) {
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: 'Reason should have at least 2 characters.'
      })
      return false
    }

    if (action === 'Freeze') {
      if (
        freezeUnFreezeStatus?.Current_freeze_unfreeze_status === 'Unfreezed'
      ) {
        handleOpenConfirmFreezePopup()
      } else {
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.INFORMATION,
          info: 'Cannot freeze Project Code twice!'
        })
      }
    } else if (action === 'Unfreeze') {
      if (freezeUnFreezeStatus?.Current_freeze_unfreeze_status === 'Freezed') {
        handleOpenConfirmUnFreezePopup()
      } else {
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.INFORMATION,
          info: 'Cannot unfreeze Project Code twice!'
        })
      }
    }
    return true
  }

  const confirmFreezeAPI = async action => {
    console.log('confirmFreezeAPI function called')

    const body = {
      Project_code: selectedProjectCode?.value,
      Project_Description: projectDescription,
      Current_freeze_unfreeze_status:
        action === 'Freeze' ? 'Freezed' : 'Unfreezed',
      Freeze_reason: reason,
      // Unfreeze_reason: '',
      Freeze_date: new Date()
      // Unfreeze_date: new Date(),
    }

    try {
      const headerss = { 'Content-Type': 'application/json' }
      // const body = { Project_code: selectedProjectCode?.value }
      const { data, status } = await axios.post(
        `${baseUrl}ProjectFreezUpdateView/`,
        body,
        { headers: headerss }
      )
      console.log('Freeze: ', status)
      if (status === 200 || status === 201) {
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: `Project Code ${
            action === 'Freeze' ? 'Freezed' : 'Unfreezed'
          } successfully!`
        })
      }
      getTableData(selectedProjectCode.value)
      // setProjectDescription(data?.Project_Description)
    } catch (error) {
      console.error('Error fetching project description:', error)
      let errorMessage
      if (error.response) {
        const { status, data } = error.response

        switch (status) {
          case 400:
            errorMessage = `Bad Request: ${
              data.message || 'The server could not understand the request.'
            }`
            break
          case 404:
            errorMessage = `Not Found: ${
              data.message || 'The requested resource could not be found.'
            }`
            break
          case 500:
            errorMessage = `Internal Server Error: ${
              data.message || 'The server encountered an error.'
            }`
            break
          default:
            errorMessage = `Error fetching data: ${
              data.message || 'An unexpected error occurred.'
            }`
        }

        console.error(errorMessage)
      } else {
        errorMessage = `Network or other error: ${
          error.message || 'An unknown error occurred.'
        }`
        console.error(errorMessage)
      }
    }
  }

  const primaryAction = [
    {
      name: 'Freeze',
      authOperation: 'upload',
      shouldEnable: selected =>
        freezeUnFreezeStatus?.Current_freeze_unfreeze_status === 'Freezed'
          ? false
          : true,
      actionFn: () => {
        freezeFuntionPopup('Freeze')
      },
      customClass: ''
    },
    {
      name: 'Unfreeze',
      authOperation: 'download',
      shouldEnable: selected =>
        freezeUnFreezeStatus?.Current_freeze_unfreeze_status === 'Unfreezed'
          ? false
          : true,
      actionFn: selected => {
        freezeFuntionPopup('Unfreeze')
      },
      customClass: ''
    }
  ]

  const secondaryActions = [
    {
      name: 'Download',
      authOperation: 'download',
      shouldEnable: selected => true,
      actionFn: selected => {
        //  getDataForExcel()
        exportExcel(tableArray)
      },
      customClass: ''
    }
  ]

  return (
    <>
      <div className={styles.container} style={{ boxSizing: 'border-box' }}>
        <div>
          <div
            className={styles.backButtonLastRefreshedDateWrapper}
            style={{ marginTop: '10px' }}
          >
            <BackButton
              action='Back'
              handleClick={redirectToApplicationLandingPage}
              style={{ marginBottom: '0px' }}
            />
            <div
              style={{ width: '100%' }}
              // style={{ display: 'flex', flexDirection: 'row-reverse' }}
            >
              <div className={styles.topDiv}>
                <div
                  className={styles.processHeader}
                  style={{ display: 'flex', height: '3em' }}
                >
                  <div style={{ width: '100%' }}>
                    <CustomTab
                      title='Freeze - Unfreeze Project'
                      withOutCount
                      isSelected
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup body={renderHTML()} />
          </div>
        </div>
        {flag && (
          <div
            className={styles.tableView}
            style={{ height: '86%', padding: '0 2em' }}
          >
            <Table
              columns={
                !auth.userRole.includes('Vendor')
                  ? [...baseDefaultColumns]
                  : [...baseDefaultColumns]
              }
              defaultColumns={[...baseDefaultColumns]}
              rowHeight={38}
              rows={tableArray}
              primaryAction={primaryAction}
              secondaryActions={secondaryActions}
              setAsnCount={setCount}
              isDataLoading={loader}
              actionButtons
              onRowSelect={e => {}}
              removeRowSelection
            />
          </div>
        )}
      </div>
      {/* <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            // onClick={onDelete}
          >
            Yes
          </Button> */}
      <FreezeModal
        confirmFreezePopup={confirmFreezePopup}
        handleCloseFreezeModal={handleCloseFreezeModal}
        confirmFreezeAPI={confirmFreezeAPI}
      />
      <UnFreezeModal
        confirmUnFreezePopup={confirmUnFreezePopup}
        handleCloseUnFreezeModal={handleCloseUnFreezeModal}
        confirmFreezeAPI={confirmFreezeAPI}
      />
    </>
  )
}

function FreezeModal ({
  confirmFreezePopup,
  handleCloseFreezeModal,
  confirmFreezeAPI
}) {
  return (
    <Dialog
      open={confirmFreezePopup}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm Freezing Project Code
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p>
              On confirmation, Project Code and its related activities will be
              freezed.
            </p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleCloseFreezeModal}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              confirmFreezeAPI('Freeze')
              handleCloseFreezeModal()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function UnFreezeModal ({
  confirmUnFreezePopup,
  handleCloseUnFreezeModal,
  confirmFreezeAPI
}) {
  return (
    <Dialog
      open={confirmUnFreezePopup}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm UnFreezing Project Code
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p>
              On confirmation, Project Code and its related activities will be
              unfreezed.
            </p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleCloseUnFreezeModal}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              confirmFreezeAPI('Unfreeze')
              handleCloseUnFreezeModal()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

FreezeModal.propTypes = {
  confirmFreezePopup: PropTypes.bool.isRequired,
  handleCloseFreezeModal: PropTypes.func.isRequired,
  confirmFreezeAPI: PropTypes.func.isRequired
}

UnFreezeModal.propTypes = {
  confirmUnFreezePopup: PropTypes.bool.isRequired,
  handleCloseUnFreezeModal: PropTypes.func.isRequired,
  confirmFreezeAPI: PropTypes.func.isRequired
}

export default withAllowedOperationsProvider(
  PartBuyerMapping,
  RESOURCE_TYPE.PROJECT
)
