/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

import {
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Form,
  Box,
  TextField,
  Grid,
  FormGroup,
  IconButton
} from '@material-ui/core'
import WarningIcon from '@mui/icons-material/Warning'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteForeverRounded'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded'
// import FormLabel from '@mui/material/FormLabel'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
// for print user in consol
import jwt_decode from 'jwt-decode'
import { useSelector } from 'react-redux'
// for print user in consol
import axios from 'axios'

import clsx from 'clsx'
// import $ from 'jquery';
import { useDropzone } from 'react-dropzone'
import { useHistory } from 'react-router-dom'
import { Link } from '@mui/material'
import Table from '../../components/Table1/Table1'
import styles from './CreateDemoProject.module.css'
import {
  CustomFormGroup,
  CustomSelect,
  ValidatingTextField
} from '../../components/FormComponents'
import BackButton from '../../components/BackButton/BackButton'
import {
  DATE_FORMAT,
  API_RESOURCE_URLS,
  DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  MESSAGE_TYPE_CERTIFICATION,
  MESSAGE_TYPE_ANNEXURE,
  RESOURCE_TYPE,
  USER_OPERATIONS,
  Regex
} from '../../constants'
import { withAllowedOperationsProvider } from '../../hocs'
import { CustomTab, Button, AuthChecker } from '../../atomicComponents'
import { usePopupManager } from '../../providers/PopupManager/PopupManager'
import { GridLoadingSpinner } from '../../components'
import DemoProjectFile from './SampleDemoProjectCode.xlsx'

const PartBuyerMapping = () => {
  const history = useHistory()
  const { showPopup } = usePopupManager()
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector(state => state.counter.baseUrl) // useSelector
  const auth = useSelector(state => state.auth) // useSelector
  console.log('auth in Supplier: ', auth)
  const [dynamicColumns, setDynamicColumns] = useState([])
  const [dynamicColumns1, setDynamicColumns1] = useState([])
  const [plant, setPlant] = useState(null)
  const [flag, show] = useState(false)
  const [checkDisable, setDisable] = useState(true)
  const [projectCode, setProjectCode] = useState(null)
  const [projectCodeArray, setProjectCodeArray] = useState([])
  console.log('projectCodeArray: ', projectCodeArray);
  
  const [projectTypeArray, setProjectTypeArray] = useState([
    { label: 'NVC', value: 'NVC' },
    { label: 'QRR', value: 'QRR' }
  ])

  const [demoForm, setDemoForm] = useState({
    projectCode: '',
    projectDescription: '',
    projectType: ''
  })

  console.log('demoForm: ', demoForm);
  
  const [isLatestProjectSelected, setIsLatestProjectSelected] = useState(false) // New state for button enable/disable
  // const [projectCodeArray, setProjectCodeArray] = useState([]);
  const [selectedProjectCode, setSelectedProjectCode] = useState(null)
  console.log('Project state: ', selectedProjectCode)
  const [latestProject, setLatestProject] = useState('')
  const [Bc, setBC] = useState(null)
  const [bcArray, setBcArray] = useState([])
  const [plantArray, setPlantArray] = useState([])
  const [tableArray, setTableArray] = useState([])
  const [buyerPartMappingArray, setBuyerPartMappingArray] = useState([])
  const [fyArray, setFyArray] = useState([])
  const [Fy, setFY] = useState(null)
  const [quarterArray, setQuarterArray] = useState([])
  const [quarter, setQuarter] = useState(null)
  const [asnCount, setAsnCount] = useState(0)
  const [loading, setLoader] = useState(false)
  const [loader, setLoading] = useState(true)
  const [projectDescription, setProjectDescription] = useState('')
  const [fileContent, setFileContent] = useState('')
  console.log('fileContent: ', fileContent);
  
  const [errorFiles, setError] = useState('')
  const [fileInfo, setFileInfo] = useState({
    name: '',
    size: ''
  })
  const [fileSuccessFlag, setFileSuccessFlag] = useState(false)

  //
  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false)
  const handleOpenFormModal = () => {
    setPopupOpenFormModal(true)
  }
  const handleCloseFormModal = () => setPopupOpenFormModal(false)

  //

  const [confirmWelcomeEmailPopup, setConfirmWelcomeEmailPopup] =
    useState(false)
  const [confirmFreezePopup, setConfirmFreezePopup] = useState(false)
  const handleOpenConfirmFreezePopup = () => setConfirmFreezePopup(true)
  const handleCloseFreezeModal = () => setConfirmFreezePopup(false)

  const handleOpenConfirmEmailPopup = () => setConfirmWelcomeEmailPopup(true)
  const handleCloseConfirmEmailPopup = () => setConfirmWelcomeEmailPopup(false)

  const [reason, setReason] = useState('')

  const ROW_HEIGHT = 38

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  // const handleOpen = () => setIsPopupOpen(true)
  // const handleClose = () => setIsPopupOpen(false)
  // const goNext = () => {
  //   uploadFileData()
  // }

  const redirectToApplicationLandingPage = () => {
    history.push('/')
  }

  const setCount = count => {
    setAsnCount(count)
  }

  //   const getTableData = async (param) => {
  //     const payload = { Project_code: param };

  //     try {
  //         const response = await axios.post(`${baseUrl}PurchaseBuyerMapping/`, payload, {
  //             headers: {
  //                 'Content-Type': 'application/json'
  //             }
  //         });

  //         console.log("Response JSON:", response.data);

  //         if (Array.isArray(response.data)) {
  //             setTableArray(response.data);
  //         } else {
  //             console.error('Response is not an array:', response.data);
  //             setTableArray([]);
  //         }
  //     } catch (error) {
  //         if (error.response) {
  //             const { status, data } = error.response;
  //             if (status === 400) {
  //                 console.error('Bad Request:', data);
  //             } else if (status === 404) {
  //                 console.error('Not Found:', data);
  //             } else if (status === 500) {
  //                 console.error('Internal Server Error:', data);
  //             } else {
  //                 console.error('Error fetching table data:', data);
  //             }
  //         } else {
  //             console.error('Network or other error:', error.message);
  //         }
  //         setTableArray([]);
  //     }
  // };

  const getTableData = async param => {
    const payload = { Project_code: param }

    try {
      console.log('Sending payload:', payload)

      const [tableDataResponse, buyerPartMappingResponse] = await Promise.all([
        axios.post(`${baseUrl}PurchaseBuyerMapping/`, payload, {
          headers: {
            'Content-Type': 'application/json'
          }
        }),
        axios.post(`${baseUrl}BuyerPartMappingForbutton/`, payload, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
      ])

      console.log('Table Data Response JSON:', tableDataResponse.data)
      console.log(
        'Buyer Part Mapping Response JSON:',
        buyerPartMappingResponse.data
      )

      if (Array.isArray(tableDataResponse.data)) {
        setTableArray(tableDataResponse.data)
      } else {
        console.error(
          `Table data response is not an array: ${JSON.stringify(
            tableDataResponse.data
          )}`
        )
        setTableArray([])
      }

      if (Array.isArray(buyerPartMappingResponse.data)) {
        setBuyerPartMappingArray(buyerPartMappingResponse.data)
      } else {
        console.error(
          `Buyer part mapping response is not an array: ${JSON.stringify(
            buyerPartMappingResponse.data
          )}`
        )
        setBuyerPartMappingArray([])
      }
    } catch (error) {
      console.error('Error during data fetching:', error)

      let errorMessage

      if (error.response) {
        const { status, data } = error.response

        switch (status) {
          case 400:
            errorMessage = `Bad Request: ${
              data.message || 'The server could not understand the request.'
            }`
            break
          case 404:
            errorMessage = `Not Found: ${
              data.message || 'The requested resource could not be found.'
            }`
            break
          case 500:
            errorMessage = `Internal Server Error: ${
              data.message || 'The server encountered an error.'
            }`
            break
          default:
            errorMessage = `Error fetching data: ${
              data.message || 'An unexpected error occurred.'
            }`
        }

        console.error(errorMessage)
      } else {
        errorMessage = `Network or other error: ${
          error.message || 'An unknown error occurred.'
        }`
        console.error(errorMessage)
      }

      setTableArray([])
      setBuyerPartMappingArray([])
    }
  }

  const getAllProjectCode = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}AllProjectCodeforProductline/`
      )
      console.log('data api: ', data);
      
      const allProjectCodes = data.map(({ Project_code }) => Project_code)
      const uniqueProjects = [...new Set(allProjectCodes)]
      const projectCodeOptions = uniqueProjects.map(value => ({
        value,
        label: value
      }))

      // setProjectCodeArray(projectCodeOptions)
      setProjectCodeArray(data)
      await getRecentProjectCode(projectCodeOptions)
    } catch (error) {
      console.error('Error fetching project codes:', error)
    }
  }

  const getRecentProjectCode = async projectCodeOptions => {
    try {
      const { data, status } = await axios.get(
        `${baseUrl}LatestProjectCodeforProductline/`
      )
      if (status !== 200) {
        throw new Error('Failed to fetch the latest project code')
      }
      const recentProj = {
        label: data?.Project_code,
        value: data?.Project_code
      }
      setLatestProject(recentProj)
      // setSelectedProjectCode(recentProj) // latest project code
    } catch (error) {
      console.error('Error fetching recent project codes:', error)
    }
  }

  useEffect(() => {
    getAllProjectCode()
  }, [])

  useEffect(() => {
    if (latestProject) {
      // setSelectedProjectCode(latestProject)
    }
  }, [latestProject])

  // Check if the selected project code is the latest project code
  useEffect(() => {
    if (selectedProjectCode && latestProject) {
      setIsLatestProjectSelected(
        selectedProjectCode.value === latestProject.value
      )
    }
  }, [selectedProjectCode, latestProject])

  useEffect(() => {
    if (selectedProjectCode) {
      // getVcForProjectCode(selectedProjectCode)
      getProjectDescription()
    }
  }, [selectedProjectCode])

  const onChangeHandler = (event, selectedOption = null) => {
    if (selectedOption) {
      // Handle dropdown change
      setDemoForm(prevData => ({
        ...prevData,
        projectType: selectedOption.value
      }));
    } else {
      // Handle text field change
      const { name, value } = event.target;
      setDemoForm(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const getProjectDescription = async () => {
    try {
      const headerss = { 'Content-Type': 'application/json' }
      const body = { Project_code: selectedProjectCode?.value }
      const { data } = await axios.post(
        `${baseUrl}ProjectDescriptionView/`,
        body,
        { headers: headerss }
      )
      // console.log('pro desc: ', data)
      setProjectDescription(data?.Project_Description)
    } catch (error) {
      console.error('Error fetching project description:', error)
    }
  }

  const showTable = () => {
    if (selectedProjectCode === null || selectedProjectCode.value === '') {
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: 'Project Code is Mandatory'
      })
    } else {
      getTableData(selectedProjectCode.value)
      show(true)
      setLoading(false)
    }
  }

  const UploadDemoProjectFuntion = async () => {
    setLoader(true)
    const formData = new FormData()
    formData.append('Project_code', demoForm?.projectCode)
    formData.append('Project_Description', demoForm?.projectDescription)
    formData.append('Project_type', demoForm?.projectType)
    formData.append('file', fileContent)

    setLoader(true)
    try {
      const response = await axios.post(
        `${baseUrl}UploadDemoProject/`,
        formData
      )

      console.log('response data: ', response?.data);
      
      // Extract message from response data
      const message = response?.data?.message
      const ErrorMessage = response?.data?.error

      if (response.status === 200 || response.status === 201) {
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: message
        })
        getAllProjectCode()
        // getTableData(selectedProjectCode.value)
      } else {
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: ErrorMessage
        })
      }
    } catch (error) {
      console.error('Error during file upload:', error)

      // Extract error message from response if available
      const errorMessage =
        error?.response && error?.response?.data && error?.response?.data?.error
          ? error?.response.data.error
          : error?.message || 'File upload failed due to an error'

      // Show failure message in popup
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: errorMessage
      })
      console.log('error MSg: ', errorMessage)

      // Optionally throw the error message if needed for further handling
      // throw new Error(errorMessage)
    } finally {
      setLoader(false)
    }
    setLoader(false)
  }

  // const exportExcel = async excelData => {
  //   var fileTypel =
  //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  //   var fileExt = '.xlsx'
  //   const ws = XLSX.utils.json_to_sheet(excelData)
  //   const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
  //   const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' })
  //   const data = new Blob([excelBuffer], { type: fileTypel })
  //   FileSaver.saveAs(data, `Part_Buyer_Mapping${fileExt}`)
  // }

  const downloadFile = () => {
    // const fileUrl = "SampleDemoProjectCode.xlsx"; // Path inside `public/`
    const link = document.createElement('a')
    link.href = DemoProjectFile
    link.download = 'SampleDemoProjectCode.xlsx' // Specify the download filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const exportExcel = async excelData => {
    setLoader(true)

    // Map the data and include the "Send E-mail" field correctly
    const newExcelData = excelData?.map(
      (
        {
          Plant_code,
          Po_Plant,
          Part_no,
          Part_description,
          Supplier_code,
          Supplier_name
        },
        id
      ) => ({
        id,
        Plant_code,
        Po_Plant,
        Part_no,
        Part_description,
        Supplier_code,
        Supplier_name
      })
    )

    console.log('new excel: ', newExcelData)

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExt = '.xlsx'

    // Generate the Excel sheet
    const ws = XLSX.utils.json_to_sheet(newExcelData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' })

    // Create a Blob and trigger the download
    const data = new Blob([excelBuffer], { type: fileType })
    setLoader(false)
    FileSaver.saveAs(data, `Part_Buyer_Mapping${fileExt}`)
  }

  const baseDefaultColumns = [
    {
      width: 150,
      title: 'Project Code',
      field: 'Project_code',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 170,
      title: 'Project Description',
      field: 'Plant_code',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Create Date',
      field: 'Po_Plant',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Freeze Date',
      field: 'Supplier_code',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Unfreeze Date',
      field: 'Part_no',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 170,
      title: 'Freeze Reason',
      field: 'Purchase_group',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 170,
      title: 'Unfreeze Reason',
      field: 'Buyer_ID',
      enableSearch: true,
      enableFilter: true
    }
    // {
    //   width: 150,
    //   title: 'SPOC',
    //   field: 'SPOC',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    // {
    //   width: 130,
    //   title: 'CTL',
    //   field: 'CTL',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    // {
    //   width: 130,
    //   title: 'Send E-mail(Yes/No)',
    //   field: 'Test4',
    //   enableSearch: true,
    //   enableFilter: true
    // }
  ]

  const BOMvalidationAPI = () => {
    console.log('inside submit api: ', selectedProjectCode?.value)

    if (!selectedProjectCode?.value) {
      console.error('Project code is missing.')
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'Project code is required.'
      })
      return
    }

    const apiBody = {
      Project_code: selectedProjectCode.value
    }
    setLoader(true)
    axios
      .post(`${baseUrl}BuyerPartMappingSubmissionstatus/`, apiBody, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        console.log('responseJson', response.data)
        if (response.data.error) {
          throw new Error(response.data.error)
        }
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: 'Part Buyer Mapping Submitted Successfully'
        })
        history.push('/')
      })
      .catch(error => {
        let errorMessage = error.response?.data?.details
          ? JSON.parse(error.response.data.details).messages
          : error.response?.data?.error || error.message
        console.error('Error in submit:', errorMessage)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Error: ${errorMessage}`
        })
      })
    setLoader(false)
  }

  const SendWelcomeEmail = submitDate => {
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')

    const payload = {
      Project_code: selectedProjectCode.value, // Use the selected project code
      submit_date: submitDate // Use the date from the input
    }

    fetch(`${baseUrl}SendEmailToAllVendors_PV_New_change/`, {
      method: 'POST',
      headers: headerss,
      body: JSON.stringify(payload) // Add payload to the request body
    })
      .then(response =>
        response.json().then(jsonRes => ({
          status: response.status,
          body: jsonRes
        }))
      )
      .then(responseJson => {
        if (responseJson?.status < 400) {
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: 'Email sent successfully!'
          })
        } else {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'Email trigger failed!'
          })
        }
      })
      .catch(error => {
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: error
        })
      })
  }

  function calculateSize () {
    console.log('File Size: ', fileInfo)

    if (fileInfo.size < 1024) {
      return `${fileInfo.size} bytes`
    }
    if (fileInfo.size < 1024 * 1024) {
      return `${(fileInfo.size / 1024).toFixed(2)} KB`
    }
    if (fileInfo.size < 50 * 1024 * 1024) {
      return `${(fileInfo.size / (1024 * 1024)).toFixed(2)} MB`
    }
    return 'File is larger than 50 MB'
  }

  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0]

    console.log('file in demo: ', file)

    const allowedTypes = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
      'application/vnd.ms-excel' // .xls
    ]

    if (file) {
      if (!file || !allowedTypes.includes(file.type)) {
        alert('Invalid file type! Please upload a CSV file.')
        return
      }
      const reader = new FileReader()

      setFileInfo({
        name: acceptedFiles[0]?.name,
        size: acceptedFiles[0].size
      })

      setFileContent(acceptedFiles[0])
      // reader.onload = event => {
      //   const text = event.target.result

      //   // Check if the file contains only pipe separators
      //   // if (!/^[^\n\r]*\|[^\n\r]*(\n[^\n\r]*\|[^\n\r]*)*$/.test(text)) {
      //   //   setError("Invalid CSV format! Only pipe '|' separator is allowed.")
      //   //   setFileContent('')
      //   //   return
      //   // }

      //   setFileContent(text)
      //   setError('') // Clear any previous error
      // }

      reader.readAsText(file)
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: '.csv',
    onDrop,
    multiple: false
  })

  const checkAllInputs = () => {
    console.log('demoForm 1', projectCodeArray);
    console.log('demoForm 2: ', demoForm?.projectCode);
    
    const isProjectCodePresent = projectCodeArray?.some(project => project?.Project_code === demoForm.projectCode)
    console.log('isProjectCodePresent: ', isProjectCodePresent);
    
    if (demoForm?.projectCode === null || demoForm?.projectCode === '') {
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: 'Project Code is Mandatory'
      })
    } else if (demoForm?.projectDescription === null || demoForm?.projectDescription === '') {
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: 'Project Description is Mandatory'
      })
    } else if (demoForm?.projectType === null || demoForm?.projectType === '') {
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: 'Project type is Mandatory'
      })
    } else if (fileContent === null || fileContent === '') {
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: 'Excel file upload is Mandatory'
      })
    } else if (isProjectCodePresent) {
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: 'Project Code already exists!'
      })
    }
    else {
      UploadDemoProjectFuntion()
    }
  }

  const renderHTML = () => (
    <div className={styles.formGroup}>
      {loading && (
        <div className={styles.gridLoader}>
          <GridLoadingSpinner />
        </div>
      )}

      <Grid
        container
        spacing={2}
        style={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center'
        }}
        columns={12}
      >
        <Grid item xs={3} className={styles.alignCenter}>
          <div
            className={clsx(styles.lableDiv)}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <label className={styles.label}>
              Project Code
              <span style={{ color: 'red' }}>*</span>
            </label>
          </div>
        </Grid>
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={styles.formDiv}>
            <ValidatingTextField
              variant='outlined'
              size='small'
              fullWidth
              value={demoForm?.projectCode}
              name='projectCode'
              inputProps={{
                'data-testid': 'remark-input'
              }}
              onChange={onChangeHandler}
              // onChange={(selectedOption) => onChangeHandler(selectedOption)}
            />
          </div>
          {/* <span className={styles.symbolCSS}>&#x20b9;</span> */}
        </Grid>
        <Grid item xs={2} className={styles.alignCenter}>
          <div
            className={clsx(styles.lableDiv)}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <label className={styles.label}>
              Project Description
              <span style={{ color: 'red' }}>*</span>
            </label>
          </div>
        </Grid>
        <Grid item xs={4} className={styles.alignCenter}>
          <div className={styles.formDiv}>
            <ValidatingTextField
              variant='outlined'
              size='small'
              fullWidth
              multiline
              value={demoForm?.projectDescription}
              name='projectDescription'
              inputProps={{
                'data-testid': 'remark-input'
              }}
              onChange={onChangeHandler}
              // onChange={(selectedOption) => onChangeHandler(selectedOption)}
            />
          </div>
        </Grid>
      </Grid>

      {/* Space for dropzone */}

      <Grid
        container
        spacing={2}
        style={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center'
        }}
        columns={12}
      >
        <Grid item xs={3} className={styles.alignCenter}>
          <div
            className={clsx(styles.lableDiv)}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <label className={styles.label}>
              NVC/QRR
              <span style={{ color: 'red' }}>*</span>
            </label>
          </div>
        </Grid>
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={styles.formDiv}>
            <CustomSelect
              name='projectType'
              options={projectTypeArray}
              className={clsx(styles.select, styles.sel1)}
              // value={demoForm?.projectType}
              value={projectTypeArray.find(option => option.value === demoForm.projectType)}
              isMulti={false}
              defaultValue={{ label: 'NVC', value: 'NVC' }}
              isClearable
              isMandatory
              onChange={selectedOption => onChangeHandler(null, selectedOption)}
              // onChange={onChangeHandler}
              // onChange={(selectedOption) => onChangeHandler(selectedOption)}
            />
          </div>
          {/* <span className={styles.symbolCSS}>&#x20b9;</span> */}
        </Grid>
        {/* <Grid item xs={2} className={styles.alignCenter}>
          <div
            className={clsx(styles.lableDiv)}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <label className={styles.label}>
              VC Description
              <span style={{ color: 'red' }}>*</span>
            </label>
          </div>
        </Grid>
        <Grid item xs={4} className={styles.alignCenter}>
          <div className={styles.formDiv}>
            <ValidatingTextField
              variant='outlined'
              size='small'
              fullWidth
              multiline
              // value={vendorCode?.relatedVendorCode}
              name='relatedVendorCode'
              inputProps={{
                'data-testid': 'remark-input'
              }}
              // eslint-disable-next-line react/jsx-boolean-value
              // isDisabled={true}
              // style={disabledTextAreaStyle}
            />
          </div>
        </Grid> */}
      </Grid>

      {/* VC and VC Description */}
      {/* <Grid
        container
        spacing={2}
        style={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center'
        }}
        columns={12}
      >
        <Grid item xs={3} className={styles.alignCenter}>
          <div
            className={clsx(styles.lableDiv)}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <label className={styles.label}>
              VC Code
              <span style={{ color: 'red' }}>*</span>
            </label>
          </div>
        </Grid>
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={styles.formDiv}>
            <ValidatingTextField
              variant='outlined'
              size='small'
              fullWidth
              name='vendorCode'
              inputProps={{
                'data-testid': 'remark-input'
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2} className={styles.alignCenter}>
          <div
            className={clsx(styles.lableDiv)}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <label className={styles.label}>
              VC Description
              <span style={{ color: 'red' }}>*</span>
            </label>
          </div>
        </Grid>
        <Grid item xs={4} className={styles.alignCenter}>
          <div className={styles.formDiv}>
            <ValidatingTextField
              variant='outlined'
              size='small'
              fullWidth
              multiline
              name='relatedVendorCode'
              inputProps={{
                'data-testid': 'remark-input'
              }}
            />
          </div>
        </Grid>
      </Grid> */}
      <Grid
        container
        spacing={2}
        style={{
          display: 'grid',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px 0px'
        }}
        columns={12}
      >
        {/* <Grid item xs={3} className={styles.alignCenter}> */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: '14px',
            color: 'brown'
          }}
        >
          <div>File type: Excel only</div>
          <div>Max size: 50MB</div>
        </div>
        <div>
          <div
            {...getRootProps()}
            style={{
              border: '1.6px dashed grey',
              padding: '0px 12px',
              borderRadius: '5px',
              borderColor: '#d2d2d2',
              color: '#7f7f7f',
              cursor: 'pointer'
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p className={styles.dropZonePara} style={{ margin: 0 }}>
                Drop the file here ...
              </p>
            ) : (
              <div
                style={{
                  display: 'grid',
                  justifyItems: 'center',
                  padding: '8px 6px'
                }}
              >
                <p className={styles.dropZonePara} style={{ margin: 0 }}>
                  Drag and drop file here, or click to select file
                </p>
                <CloudDownloadOutlinedIcon />
              </div>
            )}
          </div>

          {(fileInfo.name || fileInfo.size) && (
            <div
              className={clsx(styles.lableDivDropZone)}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                color: fileSuccessFlag ? '#59a385' : '#707070',
                alignItems: 'center'
              }}
            >
              <div>{fileInfo.name}</div>
              <div>{calculateSize(fileInfo.size)}</div>
              <DeleteOutlinedIcon
                style={{
                  color: 'red',
                  fontSize: '22px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setFileInfo({
                    name: '',
                    size: ''
                  })
                  setFileContent(null)
                }}
              />
            </div>
          )}
        </div>
        {/* </Grid> */}
      </Grid>
      <Grid
        container
        spacing={2}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px'
        }}
        columns={12}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            color: '#146DA2',
            fontSize: '12px'
          }}
        >
          <button
            type='submit'
            onClick={downloadFile}
            style={{
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              color: '#146DA2'
            }}
          >
            Download Sample Template
            <DownloadRoundedIcon />
          </button>
        </div>
        <Button
          className={clsx(styles.actionButton, styles.primaryActionButton)}
          variant='primary'
          onClick={e => {
            e.preventDefault()
            // handleCloseFreezeModal()
            // goNext()
            checkAllInputs()
          }}
        >
          Create
        </Button>
      </Grid>
    </div>
  )

  const freezeFuntionAPI = () => {
    if (
      reason?.length === 0 ||
      reason === undefined ||
      reason === '' ||
      reason === null
    ) {
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'Reason is required.'
      })
      return false
    }

    if (reason.length < 2) {
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'Reason should have at least 2 characters.'
      })
      return false
    }

    handleOpenConfirmFreezePopup()
    return true
  }

  const primaryAction = [
    {
      name: 'Freeze',
      authOperation: 'upload',
      shouldEnable: selected => true,
      actionFn: () => {
        freezeFuntionAPI()
      },
      customClass: ''
    },
    {
      name: 'Unfreeze',
      authOperation: 'download',
      shouldEnable: selected => true,
      actionFn: selected => {
        //  getDataForExcel()
        exportExcel(tableArray)
      },
      customClass: ''
    }
  ]

  const secondaryActions = [
    {
      name: 'Download',
      authOperation: 'download',
      shouldEnable: selected => true,
      actionFn: selected => {
        //  getDataForExcel()
        exportExcel(tableArray)
      },
      customClass: ''
    }
  ]

  return (
    <>
      <div className={styles.container} style={{ boxSizing: 'border-box' }}>
        <div>
          <div
            className={styles.backButtonLastRefreshedDateWrapper}
            style={{ marginTop: '10px' }}
          >
            <BackButton
              action='Back'
              handleClick={redirectToApplicationLandingPage}
              style={{ marginBottom: '0px' }}
            />
            <div
              style={{ width: '100%' }}
              // style={{ display: 'flex', flexDirection: 'row-reverse' }}
            >
              <div className={styles.topDiv}>
                <div
                  className={styles.processHeader}
                  style={{ display: 'flex', height: '3em' }}
                >
                  <div style={{ width: '100%' }}>
                    <CustomTab
                      title='Create Demo Project'
                      withOutCount
                      isSelected
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup body={renderHTML()} />
          </div>
        </div>
        {flag && (
          <div
            className={styles.tableView}
            style={{ height: '86%', padding: '0 2em' }}
          >
            <Table
              columns={
                !auth.userRole.includes('Vendor')
                  ? [...baseDefaultColumns]
                  : [...baseDefaultColumns]
              }
              defaultColumns={[...baseDefaultColumns]}
              rowHeight={38}
              rows={tableArray}
              primaryAction={primaryAction}
              secondaryActions={secondaryActions}
              setAsnCount={setCount}
              isDataLoading={loader}
              actionButtons
              onRowSelect={e => {}}
              removeRowSelection
            />
          </div>
        )}
      </div>
      {/* <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            // onClick={onDelete}
          >
            Yes
          </Button> */}
      <FreezeModal
        confirmFreezePopup={confirmFreezePopup}
        handleCloseFreezeModal={handleCloseFreezeModal}
        // BOMvalidationAPI={BOMvalidationAPI}
      />
      {/* <UnFreezeModal
        confirmFreezePopup={confirmFreezePopup}
        handleCloseFormModal={handleCloseFreezeModal}
        // BOMvalidationAPI={BOMvalidationAPI}
      /> */}
    </>
  )
}

function FreezeModal ({ confirmFreezePopup, handleCloseFreezeModal }) {
  return (
    <Dialog
      open={confirmFreezePopup}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm file Upload
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p>
              On confirmation, Project Code and its related activities will be
              freezed.
            </p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleCloseFreezeModal}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              handleCloseFreezeModal()
              // goNext()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
// function UnFreezeModal ({ confirmFreezePopup, handleCloseFreezeModal }) {
//   return (
//     <Dialog
//       open={confirmFreezePopup}
//       className={styles.popContainer}
//       classes={{
//         paper: styles.popupBox
//       }}
//       data-testid='reset-popup'
//     >
//       <>
//         <DialogTitle>
//           <span className={styles.title}>
//             <WarningIcon style={{ height: '18px', width: '18px' }} />
//             <span style={{ marginLeft: '8px' }} className={styles.txt}>
//               Confirm file Upload
//             </span>
//           </span>
//         </DialogTitle>
//         <DialogContent className={styles.content}>
//           <div>
//             <p>On confirmation, Project Code and its related activities will be unfreezed.</p>
//             <p>Are you sure ?</p>
//           </div>
//         </DialogContent>
//         <DialogActions>
//           <Button
//             className={clsx(styles.actionButton, styles.transparentButton)}
//             onClick={handleCloseFreezeModal}
//           >
//             Cancel
//           </Button>
//           <Button
//             className={clsx(styles.actionButton, styles.primaryActionButton)}
//             variant='primary'
//             onClick={e => {
//               e.preventDefault()
//               handleCloseFreezeModal()
//               // goNext()
//             }}
//           >
//             Yes
//           </Button>
//         </DialogActions>
//       </>
//     </Dialog>
//   )
// }

FreezeModal.propTypes = {
  confirmFreezePopup: PropTypes.bool.isRequired,
  handleCloseFreezeModal: PropTypes.func.isRequired
  // FreezeUnfreezeAPI: PropTypes.func.isRequired
}
// UnFreezeModal.propTypes = {
//   confirmFreezePopup: PropTypes.bool.isRequired,
//   handleCloseFreezeModal: PropTypes.func.isRequired,
//   // FreezeUnfreezeAPI: PropTypes.func.isRequired
// }

export default withAllowedOperationsProvider(
  PartBuyerMapping,
  RESOURCE_TYPE.PROJECT
)
