/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Form,
  Box,
  TextField,
  Grid,
  FormGroup,
  IconButton
} from '@material-ui/core'
import WarningIcon from '@mui/icons-material/Warning'
import FormLabel from '@mui/material/FormLabel'
// import { Grid, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import TextareaAutosize from "@mui/material/TextareaAutosize";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'

import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
// for print user in consol
import jwt_decode from 'jwt-decode'
import { useSelector } from 'react-redux'
// for print user in consol
import axios from 'axios'

import clsx from 'clsx'
import { useHistory } from 'react-router-dom'

import styles from './Annexures.module.css'
import {
  CustomFormGroup,
  CustomSelect,
  ValidatingTextField
} from '../../components/FormComponents'
import BackButton from '../../components/BackButton/BackButton'
import {
  DATE_FORMAT,
  API_RESOURCE_URLS,
  DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  MESSAGE_TYPE_CERTIFICATION,
  MESSAGE_TYPE_ANNEXURE,
  RESOURCE_TYPE,
  USER_OPERATIONS,
  Regex
} from '../../constants'
import { withAllowedOperationsProvider } from '../../hocs'
import { CustomTab, Button, AuthChecker } from '../../atomicComponents'
import { API } from '../../apis/api'
import { buildErrorMessage } from '../../apis/calls'
import { usePopupManager } from '../../providers/PopupManager/PopupManager'

import StepBar1 from '../../components/StepBar1/StepBar'
import { GridLoadingSpinner } from '../../components'
// import { error } from "jquery";

const Annexures = () => {
  const history = useHistory()
  const { showPopup } = usePopupManager()
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector(state => state.counter.baseUrl) // useSelector
  const auth = useSelector(state => state.auth) // useSelector
  // console.log('auth in Supplier: ', auth);
  const { userRole, userID, userName, vendorcode } = auth
  console.log('userRole: ', userRole, userID, userName)
  const [dynamicColumns, setDynamicColumns] = useState([])
  const [dynamicColumns1, setDynamicColumns1] = useState([])
  const [plant, setPlant] = useState(null)
  const [flag, show] = useState(false)
  const [checkDisable, setDisable] = useState(true)
  const [Bc, setBC] = useState(null)
  const [projectCode, setProjectCode] = useState(null)
  const [bcArray, setBcArray] = useState([])
  const [plantArray, setPlantArray] = useState([])
  const [tableArray, setTableArray] = useState([])
  const [excelArray, setExcelArray] = useState([])
  const [asnCount, setAsnCount] = useState(0)
  const [Fy, setFY] = useState(null)
  const [fyArray, setFyArray] = useState([
    {
      value: '2023-24',
      label: '2023-24'
    }
  ])
  const [financialYear, setFinancialYear] = useState({
    value: '2023-24',
    label: '2023-24'
  })
  const [quarter, setQuarter] = useState(null)
  const [quarterArray, setQuarterArray] = useState([])
  const [projectCodeArray, setProjectCodeArray] = useState([])
  const [latestProject, setLatestProject] = useState('')
  const [selectedProjectCode, setSelectedProjectCode] = useState(null)
  const [recentProjectCodeDescription, setRecentProjectCodeDescription] =
    useState()
  const [dvaSubmissionStatus, setDVASubmissionStatus] = useState({})

  const [loading, setLoader] = useState(false)
  const [loader, setLoading] = useState(true)
  const [vendCode, setvendCode] = useState()
  const [datesData, setDates] = useState({
    endDate: '',
    startDate: ''
  })
  const [projectDescription, setProjectDescription] = useState('')
  const [showOtherAnnexures, setShowOtherAnnexures] = useState(false)

  const [tmlSignature, setTmlSignature] = useState('');
 const [saSignature, setSaSignature] = useState('');
 const [showSignaturePopup, setShowSignaturePopup] = useState(false);

  //
  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false)
  const handleOpenFormModal = () => {
    setPopupOpenFormModal(true)
  }
  const handleCloseFormModal = () => setPopupOpenFormModal(false)

  //

  const ROW_HEIGHT = 38
  const [isValidatePopupOpen, setIsValidatePopupOpen] = useState(false)
  const handleOpenValidatePopup = () => {
    setIsValidatePopupOpen(true)
  }
  const handleCloseValidatePopup = () => {
    setIsValidatePopupOpen(false)
  }

  const [isSubmissionPopupOpen, setIsSubmissionPopupOpen] = useState(false)
  const handleOpenSubmissionPopup = () => {
    setIsSubmissionPopupOpen(true)
  }
  const handleCloseSubmissionPopup = () => {
    setIsSubmissionPopupOpen(false)
  }

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  // const handleOpen = () => setIsPopupOpen(true)
  const handleClose = () => setIsPopupOpen(false)
  // const goNext = () => {
  //   uploadFileData()
  // }

  const redirectToApplicationLandingPage = () => {
    history.push('/')
  }

  const setCount = count => {
    setAsnCount(count)
  }
  // SubmitStatusUpdate

  const isAdminUser = userRole?.includes('admin')
  const isGDCUser = userRole?.includes('TMLBSL')
  const isPurchaseUser = userRole?.includes('Purchase')
  const isVendorUser = userRole?.includes('Vendor')
  const isPMTUser = userRole?.includes('PMT')
  const isProductLineUser = userRole?.includes('Product-line')
  const isBOEUser = userRole?.includes('BOE')

  const vendorUserID = isVendorUser && userID.toUpperCase()

  useEffect(() => {
    // getData()
    getRecentProjectCode()
    getAllProjectCode()
    // getdate();
    //  Uploadcertification();
  }, [])

  useEffect(() => {
    if (selectedProjectCode) {
      getProjectDescription()
      getVcForProjectCode(selectedProjectCode)
    }
  }, [selectedProjectCode])
  const handleDropdownChange = (selectedOption, name) => {
    // console.log('selectedOption: ', selectedOption)
    if (name === 'Project_Code') {
      setSelectedProjectCode(selectedOption)
      getProjectDescription()
      // if (selectedProjectCode !== null) {
      //   getVcForProjectCode(selectedOption)
      // }
    }
    // else if (name === 'projDesc') {
    //   console.log('inside projDesc');

    //   setSelectedProjectCode(selectedOption)
    // }
  }

  const apiBody = {
    Project_code: projectCode?.value,
    Supplier_code: Bc?.value,
    // Plant_code: plant?.value,
    Po_Plant: plant?.value,
    Financial_year: Fy?.value,
    Quarter: quarter?.value
  }

  useEffect(() => {}, [bcArray])
  console.log('Supplier BC: ', Bc)

  const getRecentProjectCode = async projectCodeOptions => {
    try {
      // const { data, status } = await axios.get(`${baseUrl}LatestProjectCode/`);
      const { data, status } = await axios.get(
        `${baseUrl}LatestProjectCodeforProductline/`
      )
      if (status !== 200) {
        throw new Error('Failed to fetch the latest project code')
      }
      console.log('data in Project_code: ', data)
      const recentProj = {
        label: data?.Project_code,
        value: data?.Project_code
      }
      setLatestProject(recentProj)
      setSelectedProjectCode(recentProj) // Automatically select the latest project code
      setProjectCode(recentProj)
      // getVcForProjectCode(recentProj)
      // const recentProjectCodeDescription =
      getData(recentProj)
    } catch (error) {
      // console.error("Error fetching recent project codes:", error);
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'Could not fetch Project Code!'
      })
    }
  }

  const getAllProjectCode = async () => {
    try {
      // const { data } = await axios.get(`${baseUrl}AllProjectCode/`);
      const { data } = await axios.get(
        `${baseUrl}AllProjectCodeforProductline/`
      )
      const allProjectCodes = data.map(({ Project_code }) => Project_code)
      const uniqueProjects = [...new Set(allProjectCodes)]
      const projectCodeOptions = uniqueProjects.map(value => ({
        value,
        label: value
      }))

      setProjectCodeArray(projectCodeOptions)
      // const projectDesc = uniqueProjects?.maps((item) => ( item?.Project_code === latestProject))
      const projectDesc = uniqueProjects.map(item => {
        console.log('item: ', item)
        return item?.Project_code === latestProject
      })
      console.log('projectDesc: ', projectDesc)
      console.log('projectdesc latest: ', latestProject, data)
      // const lastElement = array[array.length - 1];
      const latestProjectCodeDesc =
        data[data?.length - 1]?.Project_code_Description
      console.log('projectDesc last: ', latestProjectCodeDesc)
      setRecentProjectCodeDescription(latestProjectCodeDesc)
      // await getRecentProjectCode(projectCodeOptions);
      // const recentProjectCodeDescription =
    } catch (error) {
      console.error('Error fetching project codes:', error)
    }
  }

  // const showTable = () => {
  //   // const { userRole } = auth
  //   if (
  //     userRole[0] === 'admin' ||
  //     userRole[0] === 'TMLBSL' ||
  //     userRole[0] === 'GDC' ||
  //     userRole[0] === 'PMT' ||
  //     userRole[0] === 'Purchase' ||
  //     isPMTUser
  //   ) {
  //     // if (projectCode === null || projectCode.value === '') {
  //     //   showPopup({
  //     //     type: MESSAGE_TYPE.INFORMATION,
  //     //     contextText: MESSAGE_TYPE.INFORMATION,
  //     //     info: 'Project Code is Mandatory!'
  //     //   })
  //     // } else if (financialYear === null || financialYear.value === '') {
  //     // } else if (!financialYear?.value) {
  //     if (!financialYear?.value) {
  //       showPopup({
  //         type: MESSAGE_TYPE.INFORMATION,
  //         contextText: MESSAGE_TYPE.INFORMATION,
  //         info: 'Financial Year is Mandatory!'
  //       })
  //     }
  //     //   else if (Bc === null || Bc.value === '') {
  //     //     showPopup({
  //     //       type: MESSAGE_TYPE.INFORMATION,
  //     //       contextText: MESSAGE_TYPE.FAILURE,
  //     //       info: 'VC is Mandatory'
  //     //     })
  //     // }
  //     else {
  //       // getSupplierDVAstatusForbutton()
  //       // getTableData()
  //       show(true)
  //     }
  //   } else if (userRole[0] === 'Vendor') {
  //     if (projectCode === null || projectCode.value === '') {
  //       showPopup({
  //         type: MESSAGE_TYPE.INFORMATION,
  //         contextText: MESSAGE_TYPE.FAILURE,
  //         info: 'Project Code is Mandatory'
  //       })
  //     } else if (Bc === null || Bc.value === '') {
  //       showPopup({
  //         type: MESSAGE_TYPE.INFORMATION,
  //         contextText: MESSAGE_TYPE.FAILURE,
  //         info: 'Supplier Code is Mandatory'
  //       })
  //     } else {
  //       getProjectDescription()
  //       // getSupplierDVAstatusForbutton()
  //       // getTableData()
  //       show(true)
  //     }
  //   } else {
  //     // getSupplierDVAstatusForbutton()
  //     // getTableData()
  //     show(true)
  //   }
  // }
  const showTable = () => {
    if (
      userRole[0] === 'admin' ||
      userRole[0] === 'TMLBSL' ||
      userRole[0] === 'GDC' ||
      userRole[0] === 'PMT' ||
      userRole[0] === 'Purchase' ||
      isPMTUser
    ) {
      if (!financialYear?.value) {
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.INFORMATION,
          info: 'Financial Year is Mandatory!',
        });
        return;
      }
  
      // Open signature popup first before showing the table
      setShowSignaturePopup(true);
    } 
    else if (userRole[0] === 'Vendor') {
      if (!projectCode?.value) {
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Project Code is Mandatory',
        });
        return;
      }
  
      if (!Bc?.value) {
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Supplier Code is Mandatory',
        });
        return;
      }
  
      // First, fetch the project description before displaying anything
      getProjectDescription();
  
      // Open signature popup first before showing the table
      setShowSignaturePopup(true);
    } 
    else {
      // Open signature popup first for other roles
      setShowSignaturePopup(true);
    }
  };
  
  // Function to confirm signatures and show annexures
  const confirmSignatures = () => {
    if (!tmlSignature.trim() || !saSignature.trim()) {
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'Both TML and SA Signatures are Mandatory!',
      });
      return;
    }
  
    // Close the popup first, then show the table
    setShowSignaturePopup(false);
  
    setTimeout(() => {
      show(true);
      setShowOtherAnnexures(Bc?.value && selectedProjectCode?.value);
    }, 0); // Ensures popup is closed before showing the table
  };
  
  
  

  const getProjectDescription = async () => {
    try {
      const headerss = { 'Content-Type': 'application/json' }
      const body = { Project_code: selectedProjectCode?.value }
      const { data } = await axios.post(
        `${baseUrl}ProjectDescriptionView/`,
        body,
        { headers: headerss }
      )
      // console.log('pro desc: ', data)
      setProjectDescription(data?.Project_Description)
    } catch (error) {
      console.error('Error fetching project description:', error)
    }
  }

  const getData = recentProj => {
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    // const url = auth.userRole[0] === 'Vendor' ? 'SupplierDvaCollection' : 'SupplierDvaCollectionAdminGDC'
    const url =
      auth.userRole[0] === 'Vendor'
        ? 'ShowVendorData'
        : 'SupplierDvaCollectionAdminGDCshow'
    const formattedBody = {
      // Project_code: latestProject?.value
      Project_code: recentProj?.value
    }
    // fetch(`${baseUrl}SupplierDvaCollection/`, {
    fetch(`${baseUrl}LoadVendorcodeandplantwrtprojectcode/`, {
      method: 'POST',
      headers: headerss,
      body: JSON.stringify(formattedBody)
      // body: formattedBody
    })
      .then(response => response.json())
      .then(responseJson => {
        console.log('responseJson: ', responseJson)
        // Extract unique values for different fields
        const uniqueSuppliers = [
          ...new Set(responseJson.map(item => item.Supplier_code))
        ].filter(Boolean)
        // const uniquePlants = [...new Set(responseJson.map(item => item.Plant_code))].filter(Boolean);
        const uniquePlants = [
          ...new Set(responseJson.map(item => item.Po_Plant))
        ].filter(Boolean)
        const uniqueQuarters = [
          ...new Set(responseJson.map(item => item.Quarter))
        ].filter(Boolean)
        const uniqueYears = [
          ...new Set(responseJson.map(item => item.Financial_year))
        ].filter(Boolean)

        // Extract unique values for different fields
        // const uniqueSuppliers = [...new Set(responseJson.map(item => item.Supplier_Code))];
        // const uniqueProjects = [...new Set(responseJson.map(item => item.Project_code))];
        // const uniquePlants = [...new Set(responseJson.map(item => item.Plant_code))];
        // const uniqueQuarters = [...new Set(responseJson.map(item => item.Quarter))];
        // const uniqueYears = [...new Set(responseJson.map(item => item.Financial_year))];
        console.log('Filtered response1: ', uniqueSuppliers)
        console.log('Filtered response2: ', uniquePlants)
        console.log('Filtered response3: ', uniqueQuarters)
        console.log('Filtered response4: ', uniqueYears)

        let temp = []
        if (bcArray.length === 0) {
          console.log(
            'MApping vendor: ',
            uniqueSuppliers.map(value => ({ value, label: value }))
          )
          temp = uniqueSuppliers.map(value => ({ value, label: value }))
          // setBcArray(uniqueSuppliers.map(value => ({ value, label: value })))
        }
        console.log('Mapping bcArray:', bcArray, 'temp: ', temp)
        // setProjectCodeArray(uniqueProjects.map(value => ({ value, label: value })));
        console.log(projectCodeArray)
        setPlantArray(uniquePlants.map(value => ({ value, label: value })))
        setQuarterArray(uniqueQuarters.map(value => ({ value, label: value })))
        setFyArray(uniqueYears.map(value => ({ value, label: value })))

        // Extract userRole and userID from auth object
        // const { userRole, userID } = auth;
        console.log(
          'User Role:',
          userRole,
          'User ID:',
          userID,
          'vendorID: ',
          vendorUserID
        )

        // Check user role to conditionally set BC state
        if (userRole.includes('Vendor-CV') || userRole.includes('Vendor')) {
          console.log('bcArray: ', bcArray)

          const singleVendor = bcArray.find(item => item.value === userID)
          const singleVendor1 = temp.find(item => item.value === userID)
          console.log('singleVendor: ', singleVendor, singleVendor1)

          if (singleVendor1) {
            setBC(singleVendor1)
            setDisable(true) // Disable UI elements
          }
        } else {
          setDisable(false) // Enable UI elements
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error)
        // Handle error (e.g., show error message)
      })
  }

  const getVcForProjectCode = async selectedCode => {
    console.log('selectedCode: ', selectedCode)
    // console.log('selectedCode ?.Project_code: ', selectedCode?.Project_code)
    console.log('selectedProjectCode', selectedProjectCode)

    const headerss = {
      'Content-Type': 'application/json'
    }

    const apiBody1 = {
      // project_code: selectedProjectCode?.value || selectedCode?.value,
      Project_code: selectedProjectCode?.value || selectedCode?.value
      // SPOC: preferredUsername
    }
    // console.log('PL body: ', apiBody1, preferredUsername)

    try {
      const response = await axios.post(
        // `${baseUrl}GetVCsByProject/`,
        // `${baseUrl}GetVCsByProjectAndSPOCForPL/`,
        `${baseUrl}GetVCsByProject/`,
        apiBody1,
        { headers: headerss }
      )

      if (response.status >= 200 && response.status < 300) {
        const resArray = response.data?.map(value => ({ value, label: value }))
        console.log('resArray: ', resArray)
        setBcArray(resArray)
      } else {
        console.error(
          `Error fetching VC: ${response.status} - ${response.statusText}`
        )
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Failed to fetch data. Error: ${response.status} - ${response.statusText}`
        })
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error(
          `Error fetching VC: ${error.response.status} - ${error.response.data}`
        )
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Failed to fetch data. Error: ${error.response.status} - ${error.response.data}`
        })
      } else if (error.request) {
        // Request was made but no response was received
        console.error('Error fetching VC: No response received', error.request)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Failed to fetch data. No response received from the server.'
        })
      } else {
        // Something else happened in setting up the request
        console.error('Error fetching VC:', error.message)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Failed to fetch data. Error: ${error.message}`
        })
      }
    }
  }



  const sendRequestToBackend = () => {
  const requestData = {
    financialYear: financialYear?.value,
    projectCode: projectCode?.value,
    supplierCode: Bc?.value,
    tmlSignature,
    saSignature
  };

  // Call the API with the requestData
  showTable(requestData);
  // if ((Bc && selectedProjectCode) || (Bc?.value && selectedProjectCode?.value)) {
  //   setShowOtherAnnexures(true);
  // } else {
  //   setShowOtherAnnexures(false);
  // }
};

  // const renderHTML = () => (
  //   <div className={styles.formGroup}>
  //     {loading && (
  //       <div className={styles.gridLoader}>
  //         <GridLoadingSpinner />
  //       </div>
  //     )}

  //     <Grid
  //       container
  //       spacing={2}
  //       style={{ display: 'flex', justifyContent: 'space-between' }}
  //       columns={12}
  //     >
  //       {/* Financial Year block */}
  //       <Grid
  //         item
  //         // sm={12}
  //         md={4}
  //         lg={4}
  //         // style={{ marginTop: '5px', marginBottom: '5px' }}
  //         className={clsx(styles.flexCenter, styles.alignCenter)}
  //       >
  //         <div className={styles.rowDiv}>
  //           <div
  //             className={clsx(styles.lableDiv)}
  //             style={{ width: '40%', display: 'flex', alignItems: 'center' }}
  //           >
  //             <label className={styles.label}>Financial Year</label>
  //           </div>
  //           <div className={styles.formDiv}>
  //             <CustomSelect
  //               name='Fy'
  //               // options={fyArray}
  //               options={[
  //                 { value: '2023-24', label: '2023-24' },
  //                 { value: '2022-23', label: '2022-23' }
  //               ]}
  //               // isDisabled={checkDisable}
  //               className={clsx(styles.select, styles.sel1)}
  //               value={financialYear}
  //               defaultValue={{
  //                 value: '2023-24',
  //                 label: '2023-24'
  //               }}
  //               isMulti={false}
  //               // isMandatory = {auth.userRole === ''}
  //               isClearable
  //               onChange={selectedOption => {
  //                 // handleSelect(e, "VC");
  //                 setFinancialYear(selectedOption)
  //               }}
  //             />
  //           </div>
  //         </div>
  //       </Grid>

  //       {/* Project Code block */}
  //       <Grid
  //         item
  //         sm={12}
  //         md={3}
  //         lg={3}
  //         // style={{ marginTop: "5px", marginBottom: '5px' }}
  //       >
  //         <div
  //           style={{
  //             marginLeft: '5px',
  //             paddingRight: '',
  //             display: 'flex',
  //             justifyContent: 'right'
  //           }}
  //         >
  //           <div className={styles.rowDiv}>
  //             <div
  //               className={clsx(styles.lableDiv)}
  //               style={{ width: '54%', display: 'flex', alignItems: 'center' }}
  //             >
  //               <label className={styles.label}>Project Code</label>
  //             </div>
  //             <div className={styles.formDiv}>
  //               <CustomSelect
  //                 name='Project Code'
  //                 options={projectCodeArray}
  //                 // isDisabled={checkDisable}
  //                 className={clsx(styles.select, styles.sel1)}
  //                 // value={projectCode}
  //                 value={selectedProjectCode}
  //                 defaultValue={latestProject}
  //                 isMulti={false}
  //                 isMandatory={
  //                   auth.userRole[0] === 'Vendor' ||
  //                   auth.userRole[0] === 'admin'
  //                 }
  //                 isClearable
  //                 onChange={selectedOption => {
  //                   // handleSelect(e, "VC");
  //                   setProjectCode(selectedOption)
  //                   setSelectedProjectCode(selectedOption)
  //                   getData(selectedOption)
  //                 }}
  //               />
  //             </div>
  //           </div>
  //         </div>
  //       </Grid>

  //       {/* VC, Vehicle configuration */}
  //       <Grid
  //         item
  //         xs={3}
  //         md={3}
  //         lg={3}
  //         // sm={12}
  //         style={{
  //           // marginTop: '5px',
  //           // marginBottom: '5px',
  //           display: 'flex',
  //           justifyContent: 'right',
  //           alignItems: 'center'
  //         }}
  //       >
  //         {/* <div className={styles.rowDiv}> */}
  //         <div
  //           className={clsx(styles.lableDiv)}
  //           style={{ width: '7%', display: 'flex', alignItems: 'center' }}
  //         >
  //           <label className={styles.label}>VC</label>
  //         </div>
  //         <div className={styles.formDiv} style={{ width: '78%' }}>
  //           <CustomSelect
  //             name='bc'
  //             options={bcArray}
  //             // isDisabled={checkDisable}
  //             className={clsx(styles.select, styles.sel1)}
  //             value={Bc}
  //             isMulti={false}
  //             isClearable
  //             onChange={selectedOption => {
  //               setBC(selectedOption)
  //             }}
  //           />
  //         </div>
  //         {/* </div> */}
  //       </Grid>
  //     </Grid>

  //     {/* Project Desc. View */}
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'space-between' }}
  //       columns={12}
  //     >
  //       <Grid
  //         item
  //         xs={5}
  //         md={5}
  //         lg={5}
  //         style={{
  //           marginTop: '5px',
  //           display: 'flex',
  //           // justifyContent: 'center',
  //           alignItems: 'center'
  //         }}
  //       >
  //         <div className={styles.rowDiv}>
  //           <div className={clsx(styles.lableDiv)} style={{ width: '29%' }}>
  //             <label className={styles.label}>Project Desc.</label>
  //           </div>
  //           {/* <div className={styles.formDiv}>
  //                   <Grid item xs={3} className={styles.alignCenter}> */}
  //           <div className={styles.formDiv}>
  //             <ValidatingTextField
  //               variant='outlined'
  //               size='small'
  //               fullWidth
  //               // value={vendorCode?.vendorCode}
  //               // value={recentProjectCodeDescription && recentProjectCodeDescription}
  //               value={projectDescription && projectDescription}
  //               name='projDesc'
  //               inputProps={{
  //                 'data-testid': 'remark-input'
  //               }}
  //               onChange={selectedOption =>
  //                 handleDropdownChange(selectedOption)
  //               }
  //               // eslint-disable-next-line react/jsx-boolean-value
  //               isDisabled={true}
  //             />
  //           </div>
  //           {/* <span className={styles.symbolCSS}>&#x20b9;</span> */}
  //           {/* </Grid> */}
  //           {/* </div> */}
  //         </div>
  //       </Grid>
  //       <Grid
  //         item
  //         md={3}
  //         lg={3}
  //         sm={3}
  //         // style={{ marginTop: '5px', marginBottom: '5px' }}
  //       >
  //         <div
  //           style={{
  //             marginLeft: '5px',
  //             paddingRight: '',
  //             marginTop: '5px',
  //             display: 'flex',
  //             justifyContent: 'right'
  //           }}
  //         >
  //           <AuthChecker operation='search'>
  //             {isAuthorized => (
  //               <Button
  //                 className={clsx(
  //                   styles.actionButton,
  //                   styles.primaryActionButton
  //                 )}
  //                 style={{ marginRight: '12px !important' }}
  //                 variant='primary'
  //                 //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
  //                 onClick={() => {
  //                   showTable()
  //                   if (
  //                     (Bc && selectedProjectCode) ||
  //                     (Bc?.value && selectedProjectCode?.value)
  //                   ) {
  //                     setShowOtherAnnexures(true)
  //                   } else {
  //                     setShowOtherAnnexures(false)
  //                   }
  //                 }}
  //                 disabled={!isAuthorized}
  //                 data-testid='confirm-action'
  //               >
  //                 Show Data
  //               </Button>
  //             )}
  //           </AuthChecker>
  //           {/* <AuthChecker operation='search'>
  //             {isAuthorized => (
  //               <Button
  //                 className={clsx(
  //                   styles.actionButton,
  //                   styles.primaryActionButton
  //                 )}
  //                 variant='primary'
  //                 //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
  //                 onClick={() => exportExcel(tableArray)}
  //                 disabled={!isAuthorized}
  //                 data-testid='confirm-action'
  //                 style={{ marginLeft: '5px' }}
  //               >
  //                 Download
  //               </Button>
  //             )}
  //           </AuthChecker> */}
  //         </div>
  //       </Grid>
  //     </Grid>
  //   </div>
  // )
  const renderHTML = () => (
    <div className={styles.formGroup}>
      {loading && (
        <div className={styles.gridLoader}>
          <GridLoadingSpinner />
        </div>
      )}
  
      {/* Signature Popup */}
      {/* {showSignaturePopup && (
        <Dialog open={showSignaturePopup} onClose={() => setShowSignaturePopup(false)}>
          <DialogTitle>Enter Signatures</DialogTitle>
          <DialogContent>
            <TextField 
              label="TML Signature" 
              fullWidth 
              margin="dense" 
              value={tmlSignature} 
              onChange={(e) => setTmlSignature(e.target.value)} 
            />
            <TextField 
              label="SA Signature" 
              fullWidth 
              margin="dense" 
              value={saSignature} 
              onChange={(e) => setSaSignature(e.target.value)} 
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowSignaturePopup(false)}>Cancel</Button>
            <Button onClick={showTable} variant="contained" color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
   */}
  {/* {showSignaturePopup && (
  <Dialog open={showSignaturePopup} onClose={() => setShowSignaturePopup(false)}>
    <DialogTitle>Enter Signatures</DialogTitle>
    <DialogContent>
      <TextField 
        label="TML Signature" 
        fullWidth 
        margin="dense" 
        value={tmlSignature} 
        onChange={(e) => setTmlSignature(e.target.value)} 
      />
      <TextField 
        label="SA Signature" 
        fullWidth 
        margin="dense" 
        value={saSignature} 
        onChange={(e) => setSaSignature(e.target.value)} 
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setShowSignaturePopup(false)}>Cancel</Button>
      <Button 
        onClick={() => { 
          confirmSignatures(); // Ensure this function executes first
          
          // Wait for showTable() to execute, then close the popup
          setTimeout(() => {
            showTable(tmlSignature, saSignature);
            setShowSignaturePopup(false);
          }, 300); // Delay slightly to ensure execution order
        }} 
        variant="contained" 
        color="primary"
      >
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
)} */}
{/* {showSignaturePopup && (
  <Dialog open={showSignaturePopup} onClose={() => setShowSignaturePopup(false)}>
    <DialogTitle>Enter Signatures</DialogTitle>
    <DialogContent>
      <TextField 
        label="TML Signature" 
        fullWidth 
        margin="dense" 
        value={tmlSignature} 
        onChange={(e) => setTmlSignature(e.target.value)} 
        variant="outlined" 
        inputProps={{
          style: { 
            border: '0px solid black', 
            borderRadius: '5px',
            padding: '5px',  // Adjusted padding to prevent second-line issue
            minHeight: '40px' // Ensures text starts properly in first row
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            confirmSignatures();
            setTimeout(() => {
              showTable(tmlSignature, saSignature);
              setShowSignaturePopup(false);
            }, 300);
          }
        }}
      />
      <TextField 
        label="SA Signature" 
        fullWidth 
        margin="dense" 
        value={saSignature} 
        onChange={(e) => setSaSignature(e.target.value)} 
        variant="outlined" 
        inputProps={{
          style: { 
            border: '0px solid black', 
            borderRadius: '5px',
            padding: '5px', 
            minHeight: '40px'
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            confirmSignatures();
            setTimeout(() => {
              showTable(tmlSignature, saSignature);
              setShowSignaturePopup(false);
            }, 300);
          }
        }}
      />
    </DialogContent>
    <DialogActions>
      <Button 
        onClick={() => setShowSignaturePopup(false)}
        variant="outlined" 
        color="secondary"
      >
        Cancel
      </Button>
      <Button 
        onClick={() => { 
          confirmSignatures();
          setTimeout(() => {
            showTable(tmlSignature, saSignature);
            setShowSignaturePopup(false);
          }, 300);
        }} 
        variant="contained" 
        color="primary"
      >
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
)} */}






{showSignaturePopup && (
  <Dialog open={showSignaturePopup} onClose={() => setShowSignaturePopup(false)}>
    <DialogTitle style={{ textAlign: "center", fontWeight: "bold",color: "#4E4E4E", border: "1px solid #ccc" }}>
      Enter Signatures
    </DialogTitle>
    <DialogContent>
      <div
        style={{
          display: "flex",
          gap: "30px",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px 20px",
        }}
      >
        {/* SA Signature - Left */}
        <div style={{ flex: 1, display: "flex", flexDirection: "column", marginRight: "25px" }}>
          <label style={{ fontWeight: "bold", marginBottom: "8px",color: "#4E4E4E" }}>SA Signature</label>
          <TextareaAutosize
            minRows={5}
            placeholder="Enter SA Signature"
            value={saSignature}
            onChange={(e) => setSaSignature(e.target.value)}
            style={{
              width: "92%",
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "12px",
              fontSize: "16px",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              resize: "none",
              color: "#4E4E4E"
            }}
          />
        </div>

        {/* TML Signature - Right */}
        <div style={{ flex: 1, display: "flex", flexDirection: "column", marginLeft: "30px" }}>
          <label style={{ fontWeight: "bold", marginBottom: "8px",color: "#4E4E4E" ,}}>TML Signature</label>
          <TextareaAutosize
            minRows={5}
            placeholder="Enter TML Signature"
            value={tmlSignature}
            onChange={(e) => setTmlSignature(e.target.value)}
            style={{
              width: "95%",
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "12px",
              fontSize: "16px",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              resize: "none",
              color: "#4E4E4E",
              
            }}
          />
        </div>
      </div>
    </DialogContent>
    <DialogActions style={{ justifyContent: "center", paddingBottom: "16px", border: "1px solid #ccc" }}>
      <Button
        onClick={() => setShowSignaturePopup(false)}
        variant="outlined"
        color="secondary"
        style={{ padding: "8px 20px", fontSize: "14px" }}
      >
        Cancel
      </Button>
      <Button
        onClick={() => {
          confirmSignatures();
          setTimeout(() => {
            showTable(tmlSignature, saSignature);
            setShowSignaturePopup(false);
          }, 300);
        }}
        variant="contained"
        color="primary"
        style={{ padding: "8px 20px", fontSize: "14px" }}
      >
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
)}

     
  
      <Grid
        container
        spacing={2}
        style={{ display: 'flex', justifyContent: 'space-between' }}
        columns={12}
      >
        {/* Financial Year Block */}
        <Grid
          item
          md={3}
          lg={3}
          className={clsx(styles.flexCenter, styles.alignCenter)}
        >
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: '61%', display: 'flex', alignItems: 'center' }}>
              <label className={styles.label}>Financial Year</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name='Fy'
                options={[
                  { value: '2023-24', label: '2023-24' },
                  { value: '2022-23', label: '2022-23' }
                ]}
                className={clsx(styles.select, styles.sel1)}
                value={financialYear}
                defaultValue={{ value: '2023-24', label: '2023-24' }}
                isMulti={false}
                isClearable
                onChange={selectedOption => setFinancialYear(selectedOption)}
              />
            </div>
          </div>
        </Grid>
  
        {/* Project Code Block */}
        <Grid item sm={12} md={4} lg={4}>
          <div style={{ marginLeft: '5px', display: 'flex', justifyContent: 'right' }}>
            <div className={styles.rowDiv}>
              <div className={clsx(styles.lableDiv)} style={{ width: '40%', display: 'flex', alignItems: 'center' }}>
                <label className={styles.label}>Project Code</label>
              </div>
              <div className={styles.formDiv}>
                <CustomSelect
                  name='Project Code'
                  options={projectCodeArray}
                  className={clsx(styles.select, styles.sel1)}
                  value={selectedProjectCode}
                  defaultValue={latestProject}
                  isMulti={false}
                  isMandatory={auth.userRole[0] === 'Vendor' || auth.userRole[0] === 'admin'}
                  isClearable
                  onChange={selectedOption => {
                    setProjectCode(selectedOption);
                    setSelectedProjectCode(selectedOption);
                    getData(selectedOption);
                  }}
                />
              </div>
            </div>
          </div>
        </Grid>
  
        {/* VC Block */}
        <Grid item xs={3} md={3} lg={3} style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
          <div className={clsx(styles.lableDiv)} style={{ width: '7%', display: 'flex', alignItems: 'center' }}>
            <label className={styles.label}>VC</label>
          </div>
          <div className={styles.formDiv} style={{ width: '78%' }}>
            <CustomSelect
              name='bc'
              options={bcArray}
              className={clsx(styles.select, styles.sel1)}
              value={Bc}
              isMulti={false}
              isClearable
              onChange={selectedOption => setBC(selectedOption)}
            />
          </div>
        </Grid>
      </Grid>
  
      {/* Project Description */}
      <Grid container spacing={1} style={{ display: 'flex', justifyContent: 'space-between' }} columns={12}>
        <Grid item xs={5} md={5} lg={5} style={{ marginTop: '5px', display: 'flex', alignItems: 'center' }}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: '28%' }}>
              <label className={styles.label}>Project Desc.</label>
            </div>
            <div className={styles.formDiv}>
              <ValidatingTextField
                variant='outlined'
                size='small'
                fullWidth
                value={projectDescription || ''}
                name='projDesc'
                inputProps={{ 'data-testid': 'remark-input' }}
                isDisabled
              />
            </div>
          </div>
        </Grid>
        
        {/* Show Data Button */}
        <Grid item md={3} lg={3} sm={3}>
          <div style={{ marginLeft: '5px', marginTop: '5px', display: 'flex', justifyContent: 'right' }}>
            <AuthChecker operation='search'>
              {isAuthorized => (
                <Button
                  className={clsx(styles.actionButton, styles.primaryActionButton)}
                  style={{ marginRight: '12px !important' }}
                  variant='primary'
                  onClick={() => {
                    showTable();
                    if ((Bc && selectedProjectCode) || (Bc?.value && selectedProjectCode?.value)) {
                      setShowOtherAnnexures(true);
                    } else {
                      setShowOtherAnnexures(false);
                    }
                  }}
                  disabled={!isAuthorized}
                  data-testid='confirm-action'
                >
                  Show Data
                </Button>
              )}
            </AuthChecker>
          </div>
        </Grid>
      </Grid>
    </div>
  );
  

  /* Common API request */
  const apiBody3 = { Financial_year: financialYear?.value }
  const apiBody5 = { Project_code: selectedProjectCode?.value, VC: Bc?.value, tmlSignature, 
    saSignature }

  const apiBody3_2 = { Financial_year: financialYear?.value, Project_code: selectedProjectCode?.value}

  const downloadPDFAPI = async num => {
    let pdf_url
    let fileName
    if (num === 3.1) {
      pdf_url = 'Annexure_3_1_Pdf'
      fileName = `Annexure_3_1_${financialYear?.value}`
    } else if (num === 3.2) {
      pdf_url = 'Annexure_3_2_Pdf'
      fileName = `Annexure_3_2_${financialYear?.value}`
    } else if (num === 5.1) {
      pdf_url = 'Annexure_5_1_Pdf'
      fileName = `Annexure_5_1_${Bc?.value}_${selectedProjectCode?.value}`
    } else if (num === 5.2) {
      pdf_url = 'Annexure_5_2_Pdf'
      fileName = `Annexure_5_2_${Bc?.value}_${selectedProjectCode?.value}`
    } else if (num === 5.3) {
      pdf_url = 'Annexure_5_3_Pdf'
      fileName = `Annexure_5_3_${Bc?.value}_${selectedProjectCode?.value}`
    } else if (num === 5.4) {
      pdf_url = 'Annexure_5_4_Pdf'
      fileName = `Annexure_5_4_${Bc?.value}_${selectedProjectCode?.value}`
    } else if (num === 6) {
      pdf_url = 'Annexure_6_Pdf'
      fileName = `Annexure_6_${Bc?.value}_${selectedProjectCode?.value}`
    } else {
      pdf_url = 'Annexure_7_Pdf'
      fileName = `Annexure_7_${Bc?.value}_${selectedProjectCode?.value}`
    }

    setLoader(true)
    try {
      const response = await axios.post(
        `${baseUrl}${pdf_url}/`,
        // num === 3.1 || num === 3.2 ? apiBody3 : apiBody5,
        num === 3.1 ? apiBody3 : num === 3.2 ? apiBody3_2 : apiBody5,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )

      const url = response?.data?.file_url
      console.log('aws res: ', response?.data?.file_url)
      console.log('response pdf: ', response)

      if (response?.error) {
        setLoader(false)
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.INFORMATION,
          info: 'Error in downloading PDF...'
        })
      } else {
        setLoader(false)
        const aws_url = url
        const link = document.createElement('a')
        link.href = aws_url
        document.body.appendChild(link)
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          })
        )
        document.body.removeChild(link)
      }
    } catch (error) {
      console.error('Error while calling DownloadSupplierAfsFile API: ', error)
      setLoader(false)
      if (error.response) {
        const { status } = error.response
        if (status === 400) {
          console.error(
            'Bad Request: Check the sent data.',
            error.response.data
          )
          showPopup({
            type: MESSAGE_TYPE.INFORMATION,
            contextText: MESSAGE_TYPE.INFORMATION,
            info: error?.response?.data?.error
          })
        } else if (status === 404) {
          console.error(
            'Not Found: The requested resource could not be found.',
            error.response.data
          )
          console.log('catch 404: ', error?.response)

          showPopup({
            type: MESSAGE_TYPE.INFORMATION,
            contextText: MESSAGE_TYPE.INFORMATION,
            info: error?.response?.data?.error || 'Resource not found...'
          })
        } else if (status === 500) {
          console.error(
            'Internal Server Error: An error occurred on the server.',
            error.response.data
          )
        } else {
          console.error('Error:', error.response.data)
        }
      } else {
        console.error(
          'Network Error or the request was made but no response was received:',
          error.message
        )
      }
      setLoading(false)
    }
  }

  const downloadExcel = async num => {
    let url
    let fileName
    if (num === 3.1) {
      url = 'Annexure_3_1'
      fileName = `Annexure_3_1_${financialYear?.value}`
    } else if (num === 3.2) {
      url = 'Annexure_3_2'
      fileName = `Annexure_3_2_${financialYear?.value}`
    } else if (num === 5.1) {
      url = 'Annexure_5_1_Excel'
      fileName = `Annexure_5_1_Excel${Bc?.value}_${selectedProjectCode?.value}`
    } else if (num === 5.2) {
      url = 'Annexure_5_2_Excel'
      fileName = `Annexure_5_2_${Bc?.value}_${selectedProjectCode?.value}`
    } else if (num === 5.3) {
      url = 'Annexure_5_3_Excel'
      fileName = `Annexure_5_3_${Bc?.value}_${selectedProjectCode?.value}`
    } else if (num === 5.4) {
      url = 'Annexure_5_4_Excel'
      fileName = `Annexure_5_4_${Bc?.value}_${selectedProjectCode?.value}`
    } else if (num === 6) {
      url = 'Annexure_6_Excel'
      fileName = `Annexure_6_${Bc?.value}_${selectedProjectCode?.value}`
    } else {
      url = 'Annexure_7_Excel'
      fileName = `Annexure_7_${Bc?.value}_${selectedProjectCode?.value}`
    }

    setLoader(true)
    try {
      const response = await axios.post(
        `${baseUrl}${url}/`,
        num === 3.1 || num === 3.2 ? apiBody3 : apiBody5,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )

      console.log('Excel data response', response.data)

      const tableData = response.data?.data
      const tableDataannexure_data = response.data?.annexure_data
      console.log('tableDataannexure_data:', tableDataannexure_data);
      console.log('tableData: ', tableData)

      // const tableData_6_7 = response.data?.records

      let tableData_6_7
      if ( num === 7 || num === 5.2 ) {
        // const tableData_6_7 = num === 6 || num === 7 && response.data?.records
        tableData_6_7 =  num === 7  || num === 5.2 ? response.data?.records : null
      }
      else if (num === 5.3) {
        tableData_6_7 = response?.data?.annexure_data
      }
      // else if (num === 6) {
      //   tableData_6_7 = response?.data?.data
      // }

      console.log('tableData_6_7: ', tableData_6_7)

      var fileTypel =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      var fileExt = '.xlsx'
      // const ws = XLSX.utils.json_to_sheet(modifiedExcelData);
      const ws = XLSX.utils.json_to_sheet(
         num === 7 ||  num === 5.2 || num === 5.3  ? tableData_6_7 : tableData
      )
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
      const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileTypel })
      // FileSaver.saveAs(data, `${Bc?.value}${fileExt}`);
      setLoader(false)
      FileSaver.saveAs(data, `${fileName}${fileExt}`)

      setLoading(false)
    } catch (error) {
      setLoader(false)
      if (error.response) {
        const { status } = error.response
        if (status === 400) {
          console.error(
            'Bad Request: Check the sent data.',
            error.response.data
          )
          showPopup({
            type: MESSAGE_TYPE.INFORMATION,
            contextText: MESSAGE_TYPE.INFORMATION,
            info: error?.response?.data?.error
          })
        } else if (status === 404) {
          console.error(
            'Not Found: The requested resource could not be found.',
            error.response.data
          )
          showPopup({
            type: MESSAGE_TYPE.INFORMATION,
            contextText: MESSAGE_TYPE.INFORMATION,
            info: error?.response?.data?.error || 'Resource not found...'
          })
        } else if (status === 500) {
          console.error(
            'Internal Server Error: An error occurred on the server.',
            error.response.data
          )
        } else {
          console.error('Error:', error.response.data)
        }
      } else {
        console.error(
          'Network Error or the request was made but no response was received:',
          error.message
        )
      }
      setLoading(false) // Ensure loading state is turned off even when an error occurs
    }
  }

  // const downloadPDF = async () => {
  //   try {
  //     const response = await fetch(`${baseUrl}/Annexure_3_1_Pdf`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({ Financial_year: '2023-24' })
  //     })

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`)
  //     }

  //     const reader = response.body.getReader()
  //     const chunks = []
  //     let receivedLength = 0

  //     // Process the stream in chunks
  //     while (true) {
  //       // eslint-disable-next-line no-await-in-loop
  //       const { done, value } = await reader.read()
  //       if (done) break

  //       chunks.push(value)
  //       receivedLength += value.length
  //     }

  //     // Combine chunks into a Blob
  //     const blob = new Blob(chunks, { type: 'application/pdf' })
  //     const url = URL.createObjectURL(blob)

  //     // Create a link to download the file
  //     const link = document.createElement('a')
  //     link.href = url
  //     link.setAttribute('download', 'Annexure.pdf')
  //     document.body.appendChild(link)
  //     link.click()

  //     // Clean up
  //     link.remove()
  //     URL.revokeObjectURL(url)
  //   } catch (error) {
  //     console.error('Error downloading the file:', error)
  //   }
  // }

  const renderOther = () => (
    <div
      className={styles.formGroup}
      style={{
        height: '350px',
        overflowY: 'scroll',
        overflowX: 'clip',
        padding: '7px 0px 0px 0px'
      }}
    >
      {/* 1st Row */}
      <Grid container spacing={2} columns={12}>
        <Grid item xs={1} className={styles.alignCenter}>
          <div className={clsx(styles.lableDiv, styles.flexCenter)}>
            <label className={styles.label}>Sr. No.</label>
          </div>
        </Grid>
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={clsx(styles.lableDiv, styles.flexLeft)}>
            <label className={styles.label}>Annexure Name</label>
          </div>
        </Grid>
        <Grid
          item
          xs={2}
          className={clsx(styles.alignCenter, styles.flexCenter)}
        >
          <div className={clsx(styles.lableDiv, styles.flexCenter)}>
            <label className={styles.label}>PDF</label>
          </div>
        </Grid>

        <Grid
          item
          xs={2}
          className={clsx(styles.alignCenter, styles.flexCenter)}
        >
          <div className={clsx(styles.lableDiv, styles.flexCenter)}>
            <label className={styles.label}>Excel</label>
          </div>
        </Grid>
      </Grid>

      {financialYear && (
        // <div>
        //   {/* 2nd Row 3.1 */}
        //   <Grid
        //     container
        //     spacing={2}
        //     columns={12}
        //     className={styles.flexLeft}
        //     style={{ marginTop: '10px' }}
        //   >
        //     <Grid item xs={1} className={styles.alignCenter}>
        //       <div className={clsx(styles.lableDiv, styles.flexCenter)}>
        //         <label>1</label>
        //       </div>
        //     </Grid>
        //     <Grid item xs={3} className={styles.alignCenter}>
        //       <div className={clsx(styles.lableDiv)}>
        //         <label>Annexure 3.1</label>
        //       </div>
        //     </Grid>
        //     <Grid item xs={2} className={styles.alignCenter}>
        //       <div
        //         className={styles.formDiv}
        //         style={{ justifyContent: 'center' }}
        //       >
        //         <Button
        //           name='downloadPDF'
        //           className={clsx(
        //             styles.actionButton,
        //             styles.primaryActionButton
        //           )}
        //           variant='primary'
        //           onClick={() => downloadPDFAPI(3.1)}
        //         >
        //           Download PDF
        //         </Button>
        //       </div>
        //     </Grid>

        //     <Grid item xs={2} className={styles.alignCenter}>
        //       <div
        //         className={styles.formDiv}
        //         style={{ justifyContent: 'center' }}
        //       >
        //         <Button
        //           name='excel'
        //           className={clsx(
        //             styles.actionButton,
        //             styles.primaryActionButton
        //           )}
        //           variant='primary'
        //           onClick={() => downloadExcel(3.1)}
        //         >
        //           Download Excel
        //         </Button>
        //       </div>
        //     </Grid>
        //   </Grid>

        //   {/* 3rd Row 3.2 */}
        //   <Grid container spacing={2} columns={12} className={styles.flexLeft}>
        //     <Grid item xs={1} className={styles.alignCenter}>
        //       <div className={clsx(styles.lableDiv, styles.flexCenter)}>
        //         <label>2</label>
        //       </div>
        //     </Grid>
        //     <Grid item xs={3} className={styles.alignCenter}>
        //       <div className={clsx(styles.lableDiv)}>
        //         <label>Annexure 3.2</label>
        //       </div>
        //     </Grid>
        //     <Grid item xs={2} className={styles.alignCenter}>
        //       <div
        //         className={styles.formDiv}
        //         style={{ justifyContent: 'center' }}
        //       >
        //         <Button
        //           name='downloadPDF'
        //           className={clsx(
        //             styles.actionButton,
        //             styles.primaryActionButton
        //           )}
        //           variant='primary'
        //           onClick={() => downloadPDFAPI(3.2)}
        //         >
        //           Download PDF
        //         </Button>
        //       </div>
        //     </Grid>

        //     <Grid item xs={2} className={styles.alignCenter}>
        //       <div
        //         className={styles.formDiv}
        //         style={{ justifyContent: 'center' }}
        //       >
        //         <Button
        //           name='excel'
        //           className={clsx(
        //             styles.actionButton,
        //             styles.primaryActionButton
        //           )}
        //           variant='primary'
        //           onClick={() => downloadExcel(3.2)}
        //         >
        //           Download Excel
        //         </Button>
        //       </div>
        //     </Grid>
        //   </Grid>
        // </div>
        <div>
  {/* 2nd Row 3.1 */}
  <Grid container spacing={2} columns={12} className={styles.flexLeft} style={{ marginTop: '10px' }}>
    <Grid item xs={1} className={styles.alignCenter}>
      <div className={clsx(styles.lableDiv, styles.flexCenter)}>
        <label>1</label>
      </div>
    </Grid>
    <Grid item xs={3} className={styles.alignCenter}>
      <div className={clsx(styles.lableDiv)}>
        <label>Annexure 3.1</label>
      </div>
    </Grid>
    <Grid item xs={2} className={styles.alignCenter}>
      <div className={styles.formDiv} style={{ justifyContent: 'center' }}>
      <Tooltip title="Download PDF 3.1" arrow>
        <IconButton color="error" onClick={() => downloadPDFAPI(3.1)}>
          <PictureAsPdfIcon fontSize="medium" />
        </IconButton>
        </Tooltip>
      </div>
    </Grid>

    <Grid item xs={2} className={styles.alignCenter}>
      <div className={styles.formDiv} style={{ justifyContent: 'center' }}>
      <Tooltip title="Download Excel 3.1" arrow>
     <IconButton  sx={{ backgroundColor: 'green', color: 'white', '&:hover': { backgroundColor: 'darkgreen' } }}   onClick={() => downloadExcel(3.1)}>
    <InsertDriveFileIcon fontSize="medium" />
     </IconButton>
      </Tooltip>
      </div>
    </Grid>
  </Grid>

  {/* 3rd Row 3.2 */}
  <Grid container spacing={2} columns={12} className={styles.flexLeft}>
    <Grid item xs={1} className={styles.alignCenter}>
      <div className={clsx(styles.lableDiv, styles.flexCenter)}>
        <label>2</label>
      </div>
    </Grid>
    <Grid item xs={3} className={styles.alignCenter}>
      <div className={clsx(styles.lableDiv)}>
        <label>Annexure 3.2</label>
      </div>
    </Grid>
    <Grid item xs={2} className={styles.alignCenter}>
      <div className={styles.formDiv} style={{ justifyContent: 'center' }}>
      <Tooltip title="Download PDF 3.2" arrow>
        <IconButton color="error" onClick={() => downloadPDFAPI(3.2)}>
          <PictureAsPdfIcon fontSize="medium" />
        </IconButton>
        </Tooltip>
      </div>
    </Grid>

    <Grid item xs={2} className={styles.alignCenter}>
      <div className={styles.formDiv} style={{ justifyContent: 'center' }}>
      <Tooltip title="Download Excel 3.2" arrow>
        <IconButton  onClick={() => downloadExcel(3.2)}>
          <InsertDriveFileIcon fontSize="medium" />
        </IconButton>
        </Tooltip>
      </div>
    </Grid>
  </Grid>
</div>
      )}

      {/* {financialYear && projectCode && Bc && ( */}
      {showOtherAnnexures && (
        <div>
          {/* 4th Row 5.1 */}
          <Grid container spacing={2} columns={12} className={styles.flexLeft}>
            <Grid item xs={1} className={styles.alignCenter}>
              <div className={clsx(styles.lableDiv, styles.flexCenter)}>
                <label>3</label>
              </div>
            </Grid>
            <Grid item xs={3} className={styles.alignCenter}>
              <div className={clsx(styles.lableDiv)}>
                <label>Annexure 5.1</label>
              </div>
            </Grid>
            <Grid item xs={2} className={styles.alignCenter}>
              <div
                className={styles.formDiv}
                style={{ justifyContent: 'center' }}
              >
                {/* <Button
                  name='downloadPDF'
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  variant='primary'
                  onClick={() => downloadPDFAPI(5.1)}
                >
                  Download PDF
                </Button> */}
                <Tooltip title="Download PDF 5.1" arrow>
                <IconButton color="error" onClick={() => downloadPDFAPI(5.1)}>
          <PictureAsPdfIcon fontSize="medium" />
        </IconButton>
        </Tooltip>
              </div>
            </Grid>

            <Grid item xs={2} className={styles.alignCenter}>
              <div
                className={styles.formDiv}
                style={{ justifyContent: 'center' }}
              >
                {/* <Button
                  name='excel'
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  variant='primary'
                  onClick={() => downloadExcel(5.1)}
                >
                  Download Excel
                </Button> */}
                <Tooltip title="Download Excel 5.1" arrow>
                 <IconButton  onClick={() => downloadExcel(5.1)}>
                  <InsertDriveFileIcon fontSize="medium" />
                 </IconButton>
                </Tooltip>
              </div>
            </Grid>
          </Grid>

          {/* 5th Row 5.2 */}
          <Grid container spacing={2} columns={12} className={styles.flexLeft}>
            <Grid item xs={1} className={styles.alignCenter}>
              <div className={clsx(styles.lableDiv, styles.flexCenter)}>
                <label>4</label>
              </div>
            </Grid>
            <Grid item xs={3} className={styles.alignCenter}>
              <div className={clsx(styles.lableDiv)}>
                <label>Annexure 5.2</label>
              </div>
            </Grid>
            <Grid item xs={2} className={styles.alignCenter}>
              <div
                className={styles.formDiv}
                style={{ justifyContent: 'center' }}
              >
                {/* <Button
                  name='downloadPDF'
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  variant='primary'
                  onClick={() => downloadPDFAPI(5.2)}
                >
                  Download PDF
                </Button> */}
                <Tooltip title="Download PDF 5.2" arrow>
                  < IconButton color="error" onClick={() => downloadPDFAPI(5.2)}>
                    <PictureAsPdfIcon fontSize="medium" />
                  </IconButton>
                </Tooltip>
              </div>
            </Grid>

            <Grid item xs={2} className={styles.alignCenter}>
              <div
                className={styles.formDiv}
                style={{ justifyContent: 'center' }}
              >
                {/* <Button
                  name='excel'
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  variant='primary'
                  onClick={() => downloadExcel(5.2)}
                >
                  Download Excel
                </Button> */}
                <Tooltip title="Download Excel 5.2" arrow>
                  <IconButton  onClick={() => downloadExcel(5.2)}>
                   <InsertDriveFileIcon fontSize="medium" />
                  </IconButton>
                </Tooltip>
              </div>
            </Grid>
          </Grid>

          {/* 6th Row 5.3 */}
          <Grid container spacing={2} columns={12} className={styles.flexLeft}>
            <Grid item xs={1} className={styles.alignCenter}>
              <div className={clsx(styles.lableDiv, styles.flexCenter)}>
                <label>5</label>
              </div>
            </Grid>
            <Grid item xs={3} className={styles.alignCenter}>
              <div className={clsx(styles.lableDiv)}>
                <label>Annexure 5.3</label>
              </div>
            </Grid>
            <Grid item xs={2} className={styles.alignCenter}>
              <div
                className={styles.formDiv}
                style={{ justifyContent: 'center' }}
              >
                {/* <Button
                  name='downloadPDF'
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  variant='primary'
                  onClick={() => downloadPDFAPI(5.3)}
                >
                  Download PDF
                </Button> */}
                <Tooltip title="Download PDF 5.3" arrow>
                 < IconButton color="error" onClick={() => downloadPDFAPI(5.3)}>
                  <PictureAsPdfIcon fontSize="medium" />
                 </IconButton>
               </Tooltip>
              </div>
            </Grid>

            <Grid item xs={2} className={styles.alignCenter}>
              <div
                className={styles.formDiv}
                style={{ justifyContent: 'center' }}
              >
                {/* <Button
                  name='excel'
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  variant='primary'
                  onClick={() => downloadExcel(5.3)}
                >
                  Download Excel
                </Button> */}
                <Tooltip title="Download Excel 5.3" arrow>
                 <IconButton  onClick={() => downloadExcel(5.3)}>
                  <InsertDriveFileIcon fontSize="medium" />
                 </IconButton>
                </Tooltip>
              </div>
            </Grid>
          </Grid>

          {/* 7th Row 5.4 */}
          <Grid container spacing={2} columns={12} className={styles.flexLeft}>
            <Grid item xs={1} className={styles.alignCenter}>
              <div className={clsx(styles.lableDiv, styles.flexCenter)}>
                <label>5</label>
              </div>
            </Grid>
            <Grid item xs={3} className={styles.alignCenter}>
              <div className={clsx(styles.lableDiv)}>
                <label>Annexure 5.4</label>
              </div>
            </Grid>
            <Grid item xs={2} className={styles.alignCenter}>
              <div
                className={styles.formDiv}
                style={{ justifyContent: 'center' }}
              >
                {/* <Button
                  name='downloadPDF'
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  variant='primary'
                  onClick={() => downloadPDFAPI(5.4)}
                >
                  Download PDF
                </Button> */}
                <Tooltip title="Download PDF 5.4" arrow>
                 < IconButton color="error" onClick={() => downloadPDFAPI(5.4)}>
                  <PictureAsPdfIcon fontSize="medium" />
                 </IconButton>
                </Tooltip>
              </div>
            </Grid>

            <Grid item xs={2} className={styles.alignCenter}>
              <div
                className={styles.formDiv}
                style={{ justifyContent: 'center' }}
              >
                {/* <Button
                  name='excel'
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  variant='primary'
                  onClick={() => downloadExcel(5.4)}
                >
                  Download Excel
                </Button> */}
                <Tooltip title="Download Excel 5.4" arrow>
                 <IconButton  onClick={() => downloadExcel(5.4)}>
                  <InsertDriveFileIcon fontSize="medium" />
                 </IconButton>
                </Tooltip>
              </div>
            </Grid>
          </Grid>

          {/* 8th Row 6 */}
          <Grid container spacing={2} columns={12} className={styles.flexLeft}>
            <Grid item xs={1} className={styles.alignCenter}>
              <div className={clsx(styles.lableDiv, styles.flexCenter)}>
                <label>6</label>
              </div>
            </Grid>
            <Grid item xs={3} className={styles.alignCenter}>
              <div className={clsx(styles.lableDiv)}>
                <label>Annexure 6</label>
              </div>
            </Grid>
            <Grid item xs={2} className={styles.alignCenter}>
              <div
                className={styles.formDiv}
                style={{ justifyContent: 'center' }}
              >
                {/* <Button
                  name='downloadPDF'
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  variant='primary'
                  onClick={() => downloadPDFAPI(6)}
                >
                  Download PDFdownloadPDFAPI(6)
                </Button> */}
                <Tooltip title="Download PDF 6" arrow>
                 < IconButton color="error" onClick={() => downloadPDFAPI(6)}>
                  <PictureAsPdfIcon fontSize="medium" />
                 </IconButton>
               </Tooltip>
              </div>
            </Grid>

            <Grid item xs={2} className={styles.alignCenter}>
              <div
                className={styles.formDiv}
                style={{ justifyContent: 'center' }}
              >
                {/* <Button
                  name='excel'
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  variant='primary'
                  onClick={() => downloadExcel(6)}
                >
                  Download Excel
                </Button> */}
                <Tooltip title="Download Excel 6" arrow>
                 <IconButton  onClick={() => downloadExcel(6)}>
                  <InsertDriveFileIcon fontSize="medium" />
                 </IconButton>
                </Tooltip>
              </div>
            </Grid>
          </Grid>

          {/* 9th Row 7 */}
          <Grid container spacing={2} columns={12} className={styles.flexLeft}>
            <Grid item xs={1} className={styles.alignCenter}>
              <div className={clsx(styles.lableDiv, styles.flexCenter)}>
                <label>7</label>
              </div>
            </Grid>
            <Grid item xs={3} className={styles.alignCenter}>
              <div className={clsx(styles.lableDiv)}>
                <label>Annexure 7</label>
              </div>
            </Grid>
            <Grid item xs={2} className={styles.alignCenter}>
              <div
                className={styles.formDiv}
                style={{ justifyContent: 'center' }}
              >
                {/* <Button
                  name='downloadPDF'
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  variant='primary'
                  onClick={() => downloadPDFAPI(7)}
                >
                  Download PDF
                </Button> */}
                <Tooltip title="Download PDF 7" arrow>
                 < IconButton color="error" onClick={() => downloadPDFAPI(7)}>
                  <PictureAsPdfIcon fontSize="medium" />
                 </IconButton>
               </Tooltip>
              </div>
            </Grid>

            <Grid item xs={2} className={styles.alignCenter}>
              <div
                className={styles.formDiv}
                style={{ justifyContent: 'center' }}
              >
                {/* <Button
                  name='excel'
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  variant='primary'
                  onClick={() => downloadExcel(7)}
                >
                  Download ExceldownloadExcel(7)
                </Button> */}
                <Tooltip title="Download Excel 7" arrow>
                 <IconButton  onClick={() => downloadExcel(7)}>
                  <InsertDriveFileIcon fontSize="medium" />
                 </IconButton>
               </Tooltip>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  )

  return (
    <>
      <div className={styles.container} style={{ boxSizing: 'border-box' }}>
        <div>
          <div
            className={styles.backButtonLastRefreshedDateWrapper}
            style={{ marginTop: '10px' }}
          >
            <BackButton
              action='Back'
              handleClick={redirectToApplicationLandingPage}
              style={{ marginBottom: '0px' }}
            />
            <div
              style={{ width: '100%' }}
              // style={{ display: 'flex', flexDirection: 'row-reverse' }}
            >
              <div className={styles.topDiv}>
                <div
                  className={styles.processHeader}
                  style={{ display: 'flex', height: '3em' }}
                >
                  <div style={{ width: '100%' }}>
                    <CustomTab title='Annexures' withOutCount isSelected />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup body={renderHTML()} />
          </div>
        </div>
        {flag && (
          <div
            className={styles.tableView}
            style={
              {
                // height: '86%',
                // padding: '0 2em'
              }
            }
          >
            <div className={styles.formContainer}>
              <CustomFormGroup body={renderOther()} />
            </div>
          </div>
        )}
      </div>
      {/* <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            // onClick={onDelete}
          >
            Yes
          </Button> */}
    </>
  )
}

export default withAllowedOperationsProvider(Annexures, RESOURCE_TYPE.PROJECT)
