/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Form,
  Box,
  TextField,
  Grid,
  FormGroup,
  IconButton,
} from "@material-ui/core";
import WarningIcon from "@mui/icons-material/Warning";
import FormLabel from "@mui/material/FormLabel";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
// for print user in consol
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";
// for print user in consol
import axios from "axios";

import clsx from "clsx";
// import $ from 'jquery';
import { useHistory } from "react-router-dom";
import Table from "../../components/Table/Table";
import styles from "./VendorMaster.module.css";
import { CustomFormGroup, CustomSelect } from "../../components/FormComponents";
import BackButton from "../../components/BackButton/BackButton";
import {
  DATE_FORMAT,
  API_RESOURCE_URLS,
  DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  MESSAGE_TYPE_CERTIFICATION,
  MESSAGE_TYPE_ANNEXURE,
  RESOURCE_TYPE,
  USER_OPERATIONS,
  Regex,
} from "../../constants";
import { withAllowedOperationsProvider } from "../../hocs";
import { CustomTab, Button, AuthChecker } from "../../atomicComponents";
import { API } from "../../apis/api";
import { buildErrorMessage } from "../../apis/calls";
import { usePopupManager } from "../../providers/PopupManager/PopupManager";

import StepBar1 from "../../components/StepBar1/StepBar";

const MHIDataSubmission = () => {
  const history = useHistory();
  const { showPopup } = usePopupManager();
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector((state) => state.counter.baseUrl); // useSelector
  const auth = useSelector((state) => state.auth); // useSelector
  console.log("auth in Supplier: ", auth);
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [dynamicColumns1, setDynamicColumns1] = useState([]);
  const [plant, setPlant] = useState(null);
  const [flag, show] = useState(false);
  const [checkDisable, setDisable] = useState(true);
  const [projectCode, setProjectCode] = useState(null);
  const [projectCodeArray, setProjectCodeArray] = useState([]);
  const [isLatestProjectSelected, setIsLatestProjectSelected] = useState(false); // New state for button enable/disable
  // const [projectCodeArray, setProjectCodeArray] = useState([]);
  const [selectedProjectCode, setSelectedProjectCode] = useState(null);
  console.log("Project state: ", selectedProjectCode);
  const [latestProject, setLatestProject] = useState("");
  const [Bc, setBC] = useState(null);
  const [bcArray, setBcArray] = useState([]);
  const [plantArray, setPlantArray] = useState([]);
  const [tableArray, setTableArray] = useState([]);
  const [fyArray, setFyArray] = useState([]);
  const [Fy, setFY] = useState(null);
  const [quarterArray, setQuarterArray] = useState([]);
  const [quarter, setQuarter] = useState(null);
  const [asnCount, setAsnCount] = useState(0);
  const [loading, setLoader] = useState(false);
  const [loader, setLoading] = useState(true);
  const [vendCode, setvendCode] = useState();
  const [datesData, setDates] = useState({
    endDate: "",
    startDate: "",
  });

  //
  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false);
  const handleOpenFormModal = () => {
    setPopupOpenFormModal(true);
  };
  const handleCloseFormModal = () => setPopupOpenFormModal(false);

  //

  const ROW_HEIGHT = 38;

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleOpen = () => setIsPopupOpen(true);
  const handleClose = () => setIsPopupOpen(false);
  const goNext = () => {
    uploadFileData();
  };

  const redirectToApplicationLandingPage = () => {
    history.push("/");
  };

  const setCount = (count) => {
    setAsnCount(count);
  };



  // const formatDate = (dateString) => {
  //   if (!dateString) return ""; // Handle null or undefined date values
  
  //   const date = new Date(dateString);
  //   if (Number.isNaN(date.getTime())) {
  //     // Attempt to parse the date in a different format
  //     const parts = dateString.split(/[-T:.]/);
  //     if (parts.length >= 3) {
  //       const day = parseInt(parts[2], 10);
  //       const month = parseInt(parts[1], 10) - 1; // Month is 0-based in JS Date
  //       const year = parseInt(parts[0], 10);
  //       const parsedDate = new Date(day, month , year);
  //       if (!Number.isNaN(parsedDate.getTime())) {
  //         return parsedDate.toLocaleDateString(undefined, { day: '2-digit',month: '2-digit', year: 'numeric'  });
  //       }
  //     }
  //     return ""; 
  //   }
  
  //   const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  //   return date.toLocaleDateString(undefined, options);
  // };
  



  
  // const getTableData = async (param) => {
  //   let headerss = new Headers();
  //   headerss.append('Content-Type', 'application/json');
  
  //   const payload = JSON.stringify({ Project_code: param });
  
  //   try {
  //     const response = await fetch(`${baseUrl}GetMHIDataByProjectCode/`, {
  //       method: 'POST',
  //       headers: headerss,
  //       body: payload,
  //     });
  
  //     if (!response.ok) {
  //       const errorResponse = await response.json();
  //       throw new Error(errorResponse.error || 'Failed to fetch data');
  //     }
  
  //     const responseJson = await response.json();
  //     console.log("Response JSON:", responseJson); 
  
  //     if (Array.isArray(responseJson)) {
  //       const formattedData = responseJson.map((item) => ({
  //         ...item,
  //         From_Date: formatDate(item.From_Date),
  //         To_Date: formatDate(item.To_Date),
  //       }));
  //       setTableArray(formattedData);
  //     } else {
  //       console.error('Response is not an array:', responseJson);
  //       setTableArray([]);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching table data:', error);
  //     setTableArray([]);
  //   }
  // };
  // const getTableData = async (param) => {
  //   let headerss = new Headers();
  //   headerss.append('Content-Type', 'application/json');
  
  //   const payload = JSON.stringify({ Project_code: param });
  
  //   try {
  //     const response = await fetch(`${baseUrl}MPFDShowData/`, {
  //       method: 'POST',
  //       headers: headerss,
  //       body: payload,
  //     });
  
  //     if (!response.ok) {
  //       const errorResponse = await response.json();
  //       throw new Error(errorResponse.error || 'Failed to fetch data');
  //     }
  
  //     const responseJson = await response.json();
  //     console.log("Response JSON:", responseJson); // Add logging
  
  //     if (Array.isArray(responseJson)) {
  //       setTableArray(responseJson);
  //     } else {
  //       console.error('Response is not an array:', responseJson);
  //       setTableArray([]);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching table data:', error);
  //     setTableArray([]);
  //   }
  // };


  const getTableData = (param) => {
    const apiFormatedData = {
      Project_code: param || selectedProjectCode?.value
    };
    const apiFormatedDataJson = JSON.stringify(apiFormatedData);
    let headerss = new Headers();
    headerss.append('Content-Type', 'application/json');
  
    fetch(`${baseUrl}VendorPlantAddressShowData/`, {
      method: 'POST',
      headers: headerss,
      body: apiFormatedDataJson
    })
      .then(response => response.json())
      .then(responseJson => {
        console.log("Response JSON:", responseJson); // Logging for debugging
  
        if (responseJson?.error) {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: responseJson?.error,
          });
        } else {
          const tableData = responseJson?.[0] || [];
  
          const excelData = tableData.map(
            ({
              // Project_code,
              Supplier_code,
              Supplier_name,
              // PAN,
              
              Supplier_plant_location_address,
              GSTIN,
              PAN,
              Financial_year,
              po_plant
            }, id) => ({
              id,
              // Project_code,
              Supplier_code,
              Supplier_name,
              // PAN,
              Supplier_plant_location_address,
              GSTIN,
              PAN,
              Financial_year,
              po_plant
            })
          );
  
          setTableArray(excelData);
        }
      })
      .catch(error => {
        console.error("Error fetching table data:", error);
        setTableArray([]); // Clear the table array in case of an error
      });
  };
  

  // const getAllProjectCode = async () => {
  //   try {
  //     const { data } = await axios.get(`${baseUrl}AllProjectCodeSubmitMHIData/`);
  //     const allProjectCodes = data.map(({ Project_code }) => Project_code);
  //     const uniqueProjects = [...new Set(allProjectCodes)];
  //     const projectCodeOptions = uniqueProjects.map((value) => ({
  //       value,
  //       label: value,
  //     }));

  //     setProjectCodeArray(projectCodeOptions);
  //     return projectCodeOptions;
  //   } catch (error) {
  //     console.error("Error fetching project codes:", error);
  //     return [];
  //   }
  // };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     await getAllProjectCode();
  //   };

  //   fetchData();
  // }, []);

  // useEffect(() => {
  //   if (selectedProjectCode) {
  //     getTableData(selectedProjectCode.value);
  //   }
  // }, [selectedProjectCode]);
  
  const getAllProjectCode = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}AllProjectCodeforProductline/`);
      const allProjectCodes = data.map(({ Project_code }) => Project_code);
      const uniqueProjects = [...new Set(allProjectCodes)];
      const projectCodeOptions = uniqueProjects.map((value) => ({
        value,
        label: value,
      }));

      setProjectCodeArray(projectCodeOptions);
      await getRecentProjectCode(projectCodeOptions);
    } catch (error) {
      console.error("Error fetching project codes:", error);
    }
  };

  const getRecentProjectCode = async (projectCodeOptions) => {
    try {
      const { data, status } = await axios.get(`${baseUrl}LatestProjectCodeforProductline/`);
      if (status !== 200) {
        throw new Error("Failed to fetch the latest project code");
      }
      const recentProj = {
        label: data?.Project_code,
        value: data?.Project_code,
      };
      setLatestProject(recentProj);
      setSelectedProjectCode(recentProj); // latest project code
    } catch (error) {
      console.error("Error fetching recent project codes:", error);
    }
  };

  useEffect(() => {
    getAllProjectCode();
  }, []);

  useEffect(() => {
    if (latestProject) {
      setSelectedProjectCode(latestProject);
    }
  }, [latestProject]);

  // Check if the selected project code is the latest project code
  useEffect(() => {
    if (selectedProjectCode && latestProject) {
      setIsLatestProjectSelected(selectedProjectCode.value === latestProject.value);
    }
  }, [selectedProjectCode, latestProject]);

  const showTable = () => {
    if (selectedProjectCode === null || selectedProjectCode.value === "") {
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: "Project Code is Mandatory",
      });
    } else {
      getTableData(selectedProjectCode.value);
      show(true);
      setLoading(false);
    }
  };

  
  // const getAllData = () => {
  //   let headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");
  
  //   fetch(`${baseUrl}GetAllSubmitMHIData/`, {
  //     method: "GET",
  //     headers: headerss,
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! status: ${response.status}`);
  //       }
  //       return response.json();
  //     })
  //     .then((responseJson) => {
  //       if (!responseJson || responseJson.length === 0) {
  //         showPopup({
  //           type: MESSAGE_TYPE.FAILURE,
  //           contextText: "No data available",
  //           info: "No data available",
  //         });
  //       } else {
  //         const formattedData = responseJson.map((item) => ({
  //           ...item,
  //           From_Date: formatDate(item.From_Date),
  //           To_Date: formatDate(item.To_Date),
  //         }));
  //         setTableArray(formattedData);
  //         show(true);
  //         setLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //       showPopup({
  //         type: MESSAGE_TYPE.FAILURE,
  //         contextText: "Error",
  //         info: 'Error while fetching data.',
  //       });
  //     });
  // };
  
  const uploadFileData = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.id = "file";
    input.setAttribute(
      "accept",
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    );
    input.click();
    input.onchange = (e) => {
      setLoader(true);
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      fetch(`${baseUrl}VendorPlantAddressuploadData/`, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          // eslint-disable-next-line radix
          let status1 = parseInt(response.status);
          if (status1 == "201") {
            //   document.removeChild(input);
            getTableData();
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded sucessfully",
            });
          } else {
            showPopup({
              type: MESSAGE_TYPE.FAILURE,
              contextText: MESSAGE_TYPE.FAILURE,
              info: "file uploaded failed",
            });
          }
        })

        .catch((error) => {});
    };
  };

  const exportExcel = async (excelData) => {
    var fileTypel =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    var fileExt = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { booktype: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileTypel });
    FileSaver.saveAs(data, `VendorMaster${fileExt}`);
  };

  const uploadDocument = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.id = 'file';
    input.setAttribute('accept', '.xlsx');
    input.click();
  
    input.onchange = async e => {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      formData.append("Project_code", selectedProjectCode?.value);
  
      // Always use 'MPFDUploadDataAdmin' as the endpoint
      const url = 'VendorPlantAddressuploadData';
  
      try {
        const response = await axios.post(`${baseUrl}${url}/`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
  
        console.log('Upload Response:', response);
  
        if (response.status === 200 || response.status === 201) {
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: 'Document Uploaded Successfully'
          });
          getTableData(); // Refresh the table data
        }
      } catch (error) {
        if (error.response) {
          const { status, data } = error.response;
          console.log('Error Response:', data);
  
          let errorMessage = 'An error occurred during the document upload.';
  
          if (status === 400) {
            errorMessage = data?.status || 'Bad Request. Please check the document format.';
          } else if (status === 500) {
            errorMessage = data?.status || 'Server encountered an issue.';
          } else {
            errorMessage = `Unexpected error: ${data?.statusText || error.message}`;
          }
  
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: errorMessage
          });
        } else {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: `Error: ${error.message || 'Document upload failed'}`
          });
        }
      }
    };
  };
  

  const baseDefaultColumns = [
    // {
    //   width: 130,
    //   title: 'Project Code',
    //   field: 'Project_code',
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 150,
      title: 'Supplier code',
      field: 'Supplier_code',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 250,
      title: 'Supplier Name',
      field: 'Supplier_name',
      enableSearch: true,
      enableFilter: true,
    },
    // {
    //   width: 200,
    //   title: 'PAN',
    //   field: 'PAN',
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 200,
      title: 'Supplier Plant Address',
      field: 'Supplier_plant_location_address',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 150,
      title: 'PAN',
      field: 'PAN',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: 'GSTIN',
      field: 'GSTIN',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 150,
      title: 'Financial year',
      field: 'Financial_year',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 150,
      title: 'PO Plant',
      field: 'po_plant',
      enableSearch: true,
      enableFilter: true,
    },
  ];

  

  // const BOMvalidationAPI = () => {
  //   // BOMvalidationAPIView
  //   console.log('inside submit api: ', Bc?.value);
  //   const apiBody = {
  //     project_code: selectedProjectCode?.value,
  //     VC: Bc?.value,
  //   }
  //   var body = JSON.stringify(apiBody);
  //   let headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");
  //   fetch(`${baseUrl}SendMHIData/`, {
  //     method: 'POST',
  //     headers: headerss,
  //     body,
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       console.log('responseJson', responseJson);
  //       showPopup({
  //         type: MESSAGE_TYPE.SUCCESS,
  //         contextText: MESSAGE_TYPE.SUCCESS,
  //         info: "MHI Data Submitted!",
  //       });
  //       history.push("/");
  //     })
  //     .catch((error) => {
  //       console.log('error in submit: ', error);
  //     });
  // }

  
  // const BOMvalidationAPI = () => {
  //   console.log('inside submit api: ', selectedProjectCode?.value);
  
  //   if (!selectedProjectCode?.value) {
  //     console.error("Project code is missing.");
  //     showPopup({
  //       type: MESSAGE_TYPE.FAILURE,
  //       contextText: MESSAGE_TYPE.FAILURE,
  //       info: "Project code is required.",
  //     });
  //     return;
  //   }
  
  //   const apiBody = {
  //     Project_code: selectedProjectCode.value,
  //   };
  
  //   const body = JSON.stringify(apiBody);
  //   let headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");
  
  //   fetch(`${baseUrl}SendMHIData/`, {
  //     method: 'POST',
  //     headers: headerss,
  //     body,
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! status: ${response.status}`);
  //       }
  //       return response.json();
  //     })
  //     .then((responseJson) => {
  //       console.log('responseJson', responseJson);
  //       if (responseJson.error) {
  //         throw new Error(responseJson.error);
  //       }
  //       showPopup({
  //         type: MESSAGE_TYPE.SUCCESS,
  //         contextText: MESSAGE_TYPE.SUCCESS,
  //         info: "MHI Data Submitted!",
  //       });
  //       history.push("/");
  //     })
  //     .catch((error) => {
  //       console.error('Error in submit:', error.message);
  //       showPopup({
  //         type: MESSAGE_TYPE.FAILURE,
  //         contextText: MESSAGE_TYPE.FAILURE,
  //         info: `Error: ${error.message}`,
  //       });
  //     });
  // };



  const BOMvalidationAPI = () => {
    console.log('inside submit api: ', selectedProjectCode?.value);
  
    if (!selectedProjectCode?.value) {
      console.error("Project code is missing.");
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: "Project code is required.",
      });
      return;
    }
  
    const apiBody = {
      Project_code: selectedProjectCode.value,
    };
  
    const body = JSON.stringify(apiBody);
    let headerss = new Headers();
    headerss.append("Content-Type", "application/json");
  
    fetch(`${baseUrl}SendMHIData/`, {
      method: 'POST',
      headers: headerss,
      body,
    })
      .then((response) => {
        if (!response.ok) {
           response.json().then(err => {
            // throw new Error(`HTTP error! status: ${response.status}, error: ${err.details || err.error}`);
            let errorMessage = err.details ? JSON.parse(err.details).messages : err.error;
            console.log('err: ', JSON.parse(err.details).messages)
            console.log('err msg: ', errorMessage);
            // throw new Error(errorMessage);
            showPopup({
              type: MESSAGE_TYPE.INFORMATION,
              contextText: MESSAGE_TYPE.INFORMATION,
              info: errorMessage,
              // info:  err.details && JSON.parse(err.details).messages,
            });
            
          });
        }
        return response.json();
      })
      .then((responseJson) => {
        console.log('responseJson', responseJson);
        if (responseJson.error) {
          throw new Error(responseJson.error);
        }
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: "MHI Data Submitted!",
        });
        history.push("/");
      })
      .catch((error) => {
        console.error('Error in submit:', error.message);
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Error: ${error.message}`,
        });
      });
  };
  


  const renderHTML = () => (
    <div className={styles.formGroup} >
      <Grid
        container
        spacing={2}
        style={{ display: "flex", justifyContent: "left" }}
        columns={12}
      >
        <Grid
          item
          // sm={12}
          md={4}
          lg={4}
          // style={{ marginTop: "5px", marginBottom: "5px" }}
        >
          <div className={styles.rowDiv} style={{ display: 'flex', alignItems: 'center' }}>
            <div className={clsx(styles.lableDiv)} style={{ width: "40%", display: 'flex', alignItems: 'center' }}>
              <label className={styles.label}>Project Code</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name="Project Code"
                options={projectCodeArray}
                className={clsx(styles.select, styles.sel1)}
                value={selectedProjectCode}
                isMulti={false}
                defaultValue={latestProject}
                isClearable
                isMandatory
                onChange={(selectedOption) => {
                  setSelectedProjectCode(selectedOption);
                //   getVcForProjectCode(selectedOption); // Fetch VC for selected project code
                }}
              />
            </div>
          </div>
        </Grid>

        {/* <Grid
          item
          md={5}
          lg={3}
          sm={12}
          style={{ marginTop: "5px", marginBottom: "5px" }}
        >
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: "30%" }}>
              <label className={styles.label}>VC</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name="bc"
                options={bcArray}
                isDisabled={checkDisable}
                className={clsx(styles.select, styles.sel1)}
                value={Bc}
                isMulti={false}
                isClearable
                onChange={(selectedOption) => {
                  setBC(selectedOption);
                }}
              />
            </div>
          </div>
        </Grid> */}

        {/* <Grid item md={2} lg={2} sm={12}>
         */}

        <Grid
          item
          md={3}
          lg={3}
          // sm={12}
          // style={{ marginTop: "5px", marginBottom: "5px" }}
        >
          <div
            style={{
              marginLeft: "5px",
              paddingRight: "10px",
              marginTop: "1px",
              display: "flex",
              justifyContent: "left",
            }}
          >
            <AuthChecker operation="search">
              {(isAuthorized) => (
                <Button
        className={clsx(styles.actionButton, styles.primaryActionButton)}
        style={{ marginRight: "12px !important" }}
        variant="primary"
        onClick={showTable}
        disabled={!isAuthorized}
        data-testid="confirm-action"
      >
        Show Data
      </Button>
              )}
            </AuthChecker>

            {/* {!auth.userRole.includes("Vendor") ? (
              <AuthChecker operation="search">
                {(isAuthorized) => (
                  <Button
                    className={clsx(
                      styles.actionButton,
                      styles.primaryActionButton
                    )}
                    style={{
                      marginRight: "12px !important",
                      marginLeft: "5px",
                    }}
                    variant="primary"
                    onClick={() => {
                      getAllData();
                    }}
                    disabled={!isAuthorized}
                    data-testid="confirm-action"
                  >
                    Show All
                  </Button>
                )}
              </AuthChecker>
            ) : null} */}

            {/* {
      loader==false&&
  
        <>
      <AuthChecker operation="download">

        {isAuthorized => (
             <Button
               className={clsx(
                 styles.actionButton,
                 styles.primaryActionButton,
               )}
               variant="primary"
               //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
               onClick={()=>{

                if(tableArray.length>=1){exportExcel(tableArray);}
               }}
                disabled={!isAuthorized}
               data-testid="confirm-action"
               style={{marginLeft:"5px"}}

             >
               download
             </Button>
           )}
 
         </AuthChecker>
     
     </> 
  } */}
          </div>
        </Grid>
      </Grid>
    </div>
  );

 

  const secondaryActions = [
    {
      name: "Upload",
      authOperation: "upload",
      shouldEnable: (selected) => true,
      // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor')  ,
      actionFn: (selected) => {
        // handleOpen();
        uploadDocument()
      },
      customClass: "",
    },
    // {
    //   name: "Upload",
    //   authOperation: "upload",
    //   shouldEnable: (selected) => true,
    //   actionFn: () => {
    //     uploadFileData();
    //   },
    //   customClass: "",
    // },

    {
      name: "Download",
      authOperation: "download",
      shouldEnable: (selected) => true,
      actionFn: (selected) => {
       //  getDataForExcel()
        exportExcel(tableArray);
      },
      customClass: "",
    }
  ];

  // const handleSecondaryActionOnRole = () => {
  //   if (auth.userRole.includes("admin")) {
  //     return secondaryActions;
  //   }
  //   if (auth.userRole.includes("GDC") || auth.userRole.includes("TMLBSL")) {
  //     return secondaryActions.filter(
  //       (item) =>
  //         item.name === "Download Documents" ||
  //         item.name === "Download Certificate" ||
  //         item.name === "Upload"
  //     );
  //   }
  //   if (auth.userRole.includes("Vendor")) {
  //     return secondaryActions.filter(
  //       (item) =>
  //         item.name !== "Download Documents" &&
  //         item.name !== "Download Certificate"
  //     );
  //   }
  //   if (auth.userRole.includes("Purchase")) {
  //     return secondaryActions.filter(
  //       (item) =>
  //         item.name === "Download Documents" ||
  //         item.name === "Download Certificate"
  //     );
  //   }
  //   return secondaryActions;
  // };

  return (
    <>
      <div className={styles.container} style={{ boxSizing: "border-box" }}>
        <div>
          <div
            className={styles.backButtonLastRefreshedDateWrapper}
            style={{ marginTop: "10px" }}
          >
            <BackButton
              action="Back"
              handleClick={redirectToApplicationLandingPage}
              style={{ marginBottom: "0px" }}
            />
            <div
              style={{ width: "100%" }}
              // style={{ display: 'flex', flexDirection: 'row-reverse' }}
            >
              <div className={styles.topDiv}>
                <div
                  className={styles.processHeader}
                  style={{ display: "flex", height: "3em" }}
                >
                  <div style={{ width: "100%" }}>
                    <CustomTab title="Vendor Master" withOutCount isSelected />
                  </div>

                  {/* <StepBar1 enable={5} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup body={renderHTML()} />
          </div>
        </div>
        {flag && (
          <div
            className={styles.tableView}
            style={{ height: "86%", padding: "0 2em" }}
          >
             <Table
        columns={
          !auth.userRole.includes("Vendor")
            ? [...baseDefaultColumns]
            : [...baseDefaultColumns]
        }
        defaultColumns={[...baseDefaultColumns]}
        rowHeight={38}
        rows={tableArray}
        // primaryAction={{
        //   name: "Send Data",
        //   authOperation: "submit",
        //   // shouldEnable: () => isLatestProjectSelected, // Enable only if the latest project is selected
        //   shouldEnable: (selected) => true,
        //   actionFn: handleOpenFormModal,
        // }}
        secondaryActions={secondaryActions}
        setAsnCount={setCount}
        isDataLoading={loader}
        actionButtons
        onRowSelect={(e) => {}}
        removeRowSelection
      />

          </div>
        )}
      </div>
      {/* <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            // onClick={onDelete}
          >
            Yes
          </Button> */}
      <ResetModalF
        isPopupOpen={isPopupOpenFormModal}
        handleCloseFormModal={handleCloseFormModal}
        BOMvalidationAPI={BOMvalidationAPI}
      />

      <ResetModal
        isPopupOpen={isPopupOpen}
        handleClose={handleClose}
        goNext={goNext}
      />
    </>
  );
};
function ResetModalF({ isPopupOpen, handleCloseFormModal, BOMvalidationAPI }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: "18px", width: "18px" }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>
              Confirm Validation
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation, MHI Data Will be Submitted</p>

            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleCloseFormModal}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant="primary"
            onClick={(e) => {
              e.preventDefault()
              handleCloseFormModal()
              BOMvalidationAPI()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}
function ResetModal({ isPopupOpen, handleClose, goNext }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: "18px", width: "18px" }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>
              Confirm file Upload
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p> On confirmation ,Records will be appended.</p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
              goNext();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}
ResetModalF.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleCloseFormModal: PropTypes.func.isRequired,
  BOMvalidationAPI: PropTypes.func.isRequired,
};

ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
};

export default withAllowedOperationsProvider(MHIDataSubmission, RESOURCE_TYPE.PROJECT);


