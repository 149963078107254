// eslint-disable-next-line import/prefer-default-export
/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */

export const ENV_TYPES = {
  TEST: 'TEST',
  PRODUCTION: 'PRODUCTION',
};
export const SORT_STATE = {
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
  UNSORTED: 'unsorted',
};
export const MESSAGE_TYPE = {
  SUCCESS: 'Success',
  FAILURE: 'Fail',
  INFORMATION: 'Information',
  INTERNAL_ERROR: 'Internal Error',
  LOADING: 'Loading',
};
export const MESSAGE_TYPE_CERTIFICATION = {
  SUCCESS: 'Certificate Uploaded Successfully',
  FAILURE: 'Fail',
  INFORMATION: 'Information',
  INTERNAL_ERROR: 'Internal Error',
  LOADING: 'Loading',
};
export const MESSAGE_TYPE_ANNEXURE = {
  SUCCESS: 'AnnexureFile Uploaded Successfully',
  FAILURE: 'Fail',
  INFORMATION: 'Information',
  INTERNAL_ERROR: 'Internal Error',
  LOADING: 'Loading',
};
export const PERSONAS = {
  BUYER: 'BUYER',
  VENDOR: 'VENDOR',
  FBV_VENDOR: 'VENDOR_WITH_FBV',
  FBV: 'FBV',
};

export const ASN_STATUS = {
  READY_FOR_DROP: 'READY_FOR_DROP',
  LINE_OUT: 'LINE_OUT',
  PLANNED: 'PLANNED',
  LINE_ON: 'LINE_ON',
  DROPPED: 'DROPPED',
  ROLLED: 'ROLLED',
  WTCL: 'WTCL',
  SFLT: 'SFLT',
};

export const ASN_STATUS_SORT_ORDER = {
  [ASN_STATUS.DROPPED]: 0,
  [ASN_STATUS.ROLLED]: 1,
  [ASN_STATUS.WTCL]: 2,
};



export const DISPLAY_MESSAGES = {
  PROJECT_CREATION_FAILURE: 'Project Creation failed. Pls try again.',
  PROJECT_UPDATE_FAILURE: 'Project Update failed. Pls try again.',
  PROJECT_DETAILS_FAILURE: 'Project Details not found. Pls try again.',
  PPAP_INITIATION_FAILURE: 'Unable to Initiate PPAP. Pls try again.',
  PPAP_TERMINATION_FAILURE: 'Unable to Terminate PPAP. Pls try again.',
  APQP_SUBMISSION_FAILURE: 'Unable to Submit APQP Timing Chart. Pls try again.',
  APQP_APPROVAL_FAILURE: 'Unable to Approve APQP Timing Chart. Pls try again.',
  PIST_APPROVAL_FAILURE: 'Unable to Approve PIST stage. Pls try again.',
  STAGE_REQUIREMENT_SUBMIT_FAILURE: 'Unable to Submit Stage Requirements. Pls try again.',
  AUTHORISATION_ERROR: 'Authorisation error. Please contact IT team for more information.',
  NETWORK_ERROR: 'Please check your network and Try again.',
  PRIMARY_ERROR: 'oops looks like something has gone wrong please try again',
  DELETE_FILE: 'Unable to delete file. Pls try again.',
  PPAP_REVISION_FAILURE: 'Unable to Revise PPAP. Pls try again.',
  PPAP_APPROVAL_FAILURE: 'Unable to Approve PPAP. Pls try again.',
  DOCUMENT_SUBMIT_FAILURE: 'Unable to Submit. Pls try again.',
  DOWNLOAD_FAIL: 'Unable to download document. Pls try again.'
}

export const LOG_LEVEL = {
  INFO: { name: 'INFO', value: 1 },
  WARN: { name: 'WARN', value: 2 },
  ERROR: { name: 'ERROR', value: 3 },
};

export const API_RESOURCE_URLS = {
  LOG_ERROR: 'logs',
  AUTH_BULK_RESOURCE: 'authorization/bulk-allowed-resources',
  PROJECTS: 'ppap/projects',
  PROCESS: 'ppap/ppap',
  PURCHASE_ORDER: 'ppap/purchaseOrders',
  TASKS: '/ppap/tasks',
  PPAP_TASKS: '/ppap/ppapTasks',
  PPAP: '/ppap/ppap',
  SUGGESTED_DOCUMENTS: '/ppap/suggestedDocuments',
  STAGE_DOCUMENTS: '/ppap/ppapStageDocuments',
  PART_CATEGORIES: '/ppap/partCategories',
  PPAP_SUBMISSIONLEVELS: '/ppap/ppapSubmissionLevels',
  PPAP_REASONS: '/ppap/ppapReasons',
  ALL_DOCUMENTS: '/ppap/documents',
  APQP_TIMING_CHART_ACTIVITY_GROUP: '/ppap/apqpTimingChartActivityGroups',
  PPAP_STAGES: '/ppap/ppapStages',
  PPAP_STAGE_DOCUMENT: '/ppap/ppapStageDocuments',
  AUTH_ALLOWED_RESOURCE: 'authorization/allowed-resources',
  getSupplierListOfPpap: (supplierId) => `/ppap/supplier/${supplierId}/ppap`,
  getAllBusinessUnits: () => 'ppap/businessUnits',
  getAllPlantsForBusinessUnit: () => 'ppap/plants',
  getAllVehicleLines: () => 'ppap/vehicleLines',
  getAllProjectMilestones: () => 'ppap/projectMilestones',
  getAllPlants: () => 'ppap/purchaseOrderCriteria/plants',
  getAllParts: () => 'ppap/purchaseOrderCriteria/parts',
  getAqCommodity: () => 'ppap/aqCommodityGroups',
  getPurchaseCommodity: () => 'ppap/commodityGroups',
  getSuppliers: () => 'ppap/purchaseOrderCriteria/suppliers',
  getPpapStageDocumentDownload: (id) => `/ppap/ppapStageDocuments/${id}/template/download`,
  getProject: (id) => `ppap/projects/${id}`,
  getPpap: (id) => `/ppap/ppap/${id}`,
  getPresignedUrl: (id) => `ppap/documents/${id}/template/upload`
};

export const DATE_FORMAT = {
  ISO: 'YYYY-MM-DD',
  DD_MM_YYYY: 'DD-MM-YYYY',
};

export const APPLICATION_NAME = 'Template Application';

export const TOOLS = {
  MASTER_DATA_MANAGEMENT: 'Supply Chain Master Data',
  ASN_OVERVIEW: 'Asn Overview',
};

export const USER_STATUS = {
  SQ_ENGINEER: 'sq-engineer',
  SUPPLIER: 'vendor'
};

export const USER_OPERATIONS = {
  CREATE_PPAP: "create-ppap",
  TERMINATE_PPAP: "terminate-ppap",
  INITIATE_PPAP: "initiate-ppap",
  CREATE_PROJECT: "create-project",
  CREATE_PPAP_STAGE_DOCUMENT: "create-ppap-stage-document",
  LIST_PROJECTS: "list-projects",
  LIST_AQ_COMMODITY_GROUPS: "list-aq-commodity-groups",
  LIST_BUSINESS_UNITS: "list-business-units",
  LIST_PLANTS: "list-plants",
  LIST_PROJECT_MILESTONES: "list-project-milestones",
  LIST_PURCHASE_ORDERS: "list-purchase-orders",
  LIST_PURCHASE_ORDER_CRITERIA: "list-purchase-order-criteria",
  LIST_VEHICLE_LINES: "list-vehicle-lines",
  LIST_PART_CATEGORIES: "list-part-categories",
  LIST_PPAP_SUBMISSION_LEVELS: "list-ppap-submission-levels",
  LIST_PPAP_REASONS: "list-ppap-reasons",
  LIST_DOCUMENTS: "list-documents",
  LIST_PPAP_STAGES: "list-ppap-stages",
  LIST_SUGGESTED_DOCUMENTS: "list-suggested-documents"
}

export const RESOURCE_TYPE = {
  PPAP: 'ppap',
  PROJECT: 'project',
  AQ_COMMODITY_GROUP: 'aq-commodity-group',
  BUSINESS_UNIT: 'business-unit',
  PLANT: 'plant',
  PROJECT_MILESTONE: 'project-milestone',
  PURCHASE_ORDER: 'purchase-order',
  PURCHASE_ORDER_CRITERIA: 'purchase-order-criteria',
  VEHICLE_LINE: 'vehicle-line',
  PART_CATEGORY: 'part-category',
  PPAP_SUBMISSION_LEVEL: 'ppap-submission-level',
  PPAP_REASON: 'ppap-reason',
  PPAP_STAGE_DOCUMENT: 'ppap-stage-document'
}

export const HATEAOS_LINKS = {
  PPAP: 'ppap'
}

export const Regex = {
  email: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  name: /^[a-zA-Z ]+$/,
  phone: /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/
}

export const EDIT_STATUS = {
  DRAFT: 'DRAFT',
  COMPLETE: 'COMPLETE'
}

export const PPAP_STATE = {
  INITIATE: 'INITIATE',
  APQP: 'APQP',
  PIST: 'PIST',
  PIPC: 'PIPC',
  RUN_AT_RATE: 'RUN_AT_RATE',
  PSW: 'PSW',
  COMPLETE: 'COMPLETE',
  TERMINATE: 'TERMINATE'
}

export const stateStringMap = {
  [PPAP_STATE.INITIATE]: 'INITIATE',
  [PPAP_STATE.APQP]: 'APQP',
  [PPAP_STATE.PIST]: 'PIST',
  [PPAP_STATE.PIPC]: 'PIPC',
  [PPAP_STATE.RUN_AT_RATE]: 'Run at rate',
  [PPAP_STATE.PSW]: 'PSW',
  [PPAP_STATE.COMPLETE]: 'COMPLETE',
  [PPAP_STATE.TERMINATE]: 'TERMINATE'
};

export const PPAP_COMPLETE_STATE = ['PIST', 'PIPC', 'RUN_AT_RATE', 'PSW']

export const PPAP_APP_NAME = 'ppap';

export const PPAP_APP_RESOURCES = {
  'org:tml:application:ppap': PPAP_APP_NAME,
};

export const PROJECT_TYPE = {
  EDIT: 'edit',
  CREATE: 'create'
};

export const MIS_Supplier_data_for_Same_PAN = 'MIS_Supplier_data_for_Same_PAN'
export const MIS_TMLBSL_data_for_PAN_for_both = 'MIS_TMLBSL_data_for_PAN_for_both'
export const MIS_Purchase_data_for_PAN = 'MIS_Purchase_data_for_PAN'
export const MIS_TMLBSL_data_for_PAN_for_both_admin = 'MIS_TMLBSL_data_for_PAN_for_both_admin'
export const MIS_Purchase_data_for_PAN_Admin = 'MIS_Purchase_data_for_PAN_Admin'

export const ACCESSRIGHT = [
  {
    rolePLI: "admin",
    pageDetails: {
      url: '/',
      label: "home",
      resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm", "sendWelcomeEmail", "sendSummaryEmail"]

    },
  },

  // {
  //   rolePLI: "TMLBSL",
  //   pageDetails: {
  //     url: '/',
  //     label: "home",
  //     resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]

  //   },
  // },

    /* Role: PMT */
    {
      rolePLI: 'PMT',
      pageDetails: [
        {
          url: '/mhi-data-pmt',
          label: 'MHI PMT Data',
          resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
        },
        {
          url: '/freeze-unfreeze',
          label: 'Freeze Unfreeze',
          resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
        },
        {
          url: '/new-demo-project',
          label: 'Create Demo Project',
          resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
        },
        {
          url: '/annual_financial_statement',
          label: "Annual Financial Details Form",
          resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
        },
        {
          url: '/AFSMIS',
          label: "AFS Report",
          resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
        },
        {
          url: '/afs_master_data-pv',
          label: "AFS Master Data",
          resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
        },
        {
          // url: '/bom-validation',
          url: '/bom-validation_2',
          label: "BOM Validation 2",
          resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
        },
        {
          url: '/pl-report',
          label: "PL Report",
          resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
        },
        {
          url: '/pl2-report',
          // label: "PL2 Report",
          label: "Unique Part Selection Report",
          resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
        },
        {
          url: '/pmt-bom-report',
          label: "PMT BOM Report",
          resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
        },
        {
          url: '/unique-part-selection',
          label: "Unique Part Selection",
          resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
        }, 
        {
          // url: '/Supplier-DVA-Collection-CV-Purchase',
          url: '/supplier-dva-collection',
          label: "Supplier DVA",
          // resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "manualForm", "uploadPdfDocument"]
          resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
        },
        {
          url: '/bom-master-data',
          label: "DVA Master Sheet Data",
          resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
        },
        {
          url: '/Supplier-DVA-Collection-CV-Purchase-Page3',
          label: "Supplier DVA Page3",
          // resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "manualForm", "uploadPdfDocument"]
          resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
        },
        {
          url: '/Supplier-DVA-Collection-PV-Purchase',
          label: "Supplier DVA",
          // resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "manualForm", "uploadPdfDocument"]
          resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
        },
        {
          url: '/supplier-dva-declaration-for-pli_pmt',
          label: "Supplier Under PLI Declaration",
          resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
        }, 
        {
          // component: SupplierImportDetails,
          url: '/supplier_import_details-cv',
          label: "Supplier Import Details",
          resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "manualForm", "uploadPdfDocument"]
        },
        {
          url: '/part-buyer-mapping',
          label: "Part Buyer Mapping",
          resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search","sendWelcomeEmail", "sendSummaryEmail"]
        },
        {
          url: '/vc-spoc-mapping',
          label: "VC SPOC Mapping",
          resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
        },
        {
          url: '/supplier-pli-declaration-mis',
          label: "Supplier Under PLI Declaration MIS",
          resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
        },
        {
          url: '/supplier-dva-declaration-mis',
          label: "DVA Declaration MIS",
          resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
        },
        {
          url: '/vcwise-dva-calculation',
          label: "VC Wise DVA Calculation",
          resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
        },
        {
          url: '/finance-master',
          label: "Finance Master",
          resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
        },
        {
          url: '/annexures',
          label: "Annexures",
          resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
        },
        {
          url: '/exempted-data',
          label: "Exempted Data",
          resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
        },
        {
          url: '/mpfd-purchase-tmlbsl',
          label: "MPFD Download",
          resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
        },
        {
          url: '/mpfd',
          label: "MPFD Upload",
          resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
        },
        {
          url: '/audit-file',
          label: "Audit File",
          resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
        },
        {
          url: '/dashboard',
          label: "Dashboard",
          resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
        },
        {
          url: '/vendor-master',
          label: "Vendor Master",
          resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
        },
        {
          url: '/invoice-upload',
          label: "Invoice Upload",
          resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
        },
        {
          url: '/boe-upload',
          label: "BOE Upload",
          resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
        },
      ]
    },

    {
      rolePLI: "Dashboard",
      pageDetails: [
        {
          url: '/dashboard',
          label: "Dashboard",
          resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
        },
        {
          url: '/AFSMIS',
          label: "AFS MIS",
          resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
        },
        {
          url: '/pl-report',
          label: "PL Report",
          resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
        },
        {
          url: '/pl2-report',
          // label: "PL2 Report",
          label: "Unique Part Selection Report",
          resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
        },
        {
          url: '/pmt-bom-report',
          label: "PMT BOM Report",
          resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
        },
        {
          url: '/supplier-dva-declaration-mis',
          label: "DVA Declaration MIS",
          resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
        },
        {
          url: '/supplier-pli-declaration-mis',
          label: "Supplier Under PLI Declaration MIS",
          resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
        },
        {
          url: '/afs_master_data-pv',
          label: "AFS Master Data",
          resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
        },
        {
          url: '/bom-master-data',
          label: "DVA Master Sheet Data",
          resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
        },

      ]},


  // Annual Financial Statment MIS Block
  {
    rolePLI: "TMLBSL",
    pageDetails: [
      {
        url: '/AFSMIS',
        label: "AFS MIS",
        resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      },
      {
        url: '/AFSpurchase',
        label: "AFS Purchase",
        resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      },
      {
        url: '/annual_financial_statement',
        label: "Annual Financial Details Form",
        resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      },
      {
        url: '/afs_master_data-pv',
        label: "AFS Master Data",
        resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      },
      {
        url: '/supplier-dva-collection',
        label: "Supplier DVA Collection",
        // resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "manualForm", "uploadPdfDocument"]
        resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
      },
      {
        url: '/supplier-dva-report',
        label: "Supplier DVA Report",
        // resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "manualForm", "uploadPdfDocument"]
        resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
      },

      /* SupplierUnderPLIDeclarationTMLBSL */
      {
        url: '/supplier-dva-declaration-for-pli_tmlbsl',
        label: 'Supplier Under PLI Declaration',
        resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
      },
      {
        url: '/Supplier-DVA-Collection-PV-Purchase',
        label: "Supplier DVA",
        resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
      },
      {
        // url: '/Supplier-DVA-Collection-PV-Purchase-Page3',
        url: '/supplier-dva-declaration-for-pli',
        label: "Supplier DVA Page3",
        // resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "manualForm", "uploadPdfDocument"]
        resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
      },
      {
        url: '/supplier-pli-declaration-mis',
        label: "Supplier Under PLI Declaration MIS",
        resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      },
      {
        url: '/supplier-dva-declaration-mis',
        label: "DVA Declaration MIS",
        resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      },
      {
        url: '/bom-master-data',
        label: "DVA Master Sheet Data",
        resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
      },
      {
        url: '/mpfd-purchase-tmlbsl',
        label: "MPFD",
        resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      },
      {
        url: '/audit-file',
        label: "Audit File",
        resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      },
      {
        url: '/dashboard',
        label: "Dashboard",
        resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      },
      {
        url: '/invoice-upload',
        label: "Invoice Upload",
        resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      },
      {
        url: '/vendor-master',
        label: "Vendor Master",
        resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      },
      {
        url: '/bom-boe-data',
        label: "BOM BOE Data",
        resourceAccess: ["delete", "upload", "download", "downloadCertificate", "downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
      },
      {
        url: '/bom-boe-report',
        label: "BOM BOE Report",
        resourceAccess: ["delete", "upload", "download", "downloadCertificate", "downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
      },
      {
        url: '/boe-upload',
        label: "BOE Upload",
        resourceAccess: ["delete", "upload", "download", "downloadCertificate", "downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
      },
   
    ]
  },


  {
    rolePLI: "Vendor-CV",
    pageDetails: [
      {
        url: '/vendor-data',
        label: "Vendor DVA Declaration",
        resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]

      },
      {
        url: '/Supplier-DVA-Collection-CV-Purchase',
        label: "Supplier-DVA-Collection",
        resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      }, 


    ]
  },
  {
    rolePLI: "Vendor-PV",
    pageDetails: [
      {
        url: '/vendor-data-pv',
        label: "Vendor DVA Declaration",
        resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]

      },
      {
        url: '/Supplier-DVA-Collection-PV-Purchase',
        label: "Supplier-DVA-Collection",
        resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      }, 



    ]
  },
  {
    rolePLI: "Vendor",
    pageDetails: [
      // {
      //   url: '/vendor-data-pv',
      //   label: "Vendor DVA Declaration",
      //   resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]

      // },
      // {
      //   url: '/vendor-data',
      //   label: "Vendor DVA Declaration",
      //   resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]

      // },
      // {
      //   url: '/Supplier-DVA-Collection-PV-Purchase',
      //   label: "Supplier-DVA-Collection",
      //   resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      // }, 

      {
        
        // url: '/Supplier-DVA-Collection-CV-Purchase',
        url: '/supplier-dva-collection',
        label: "Supplier DVA",
        // resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "manualForm", "uploadPdfDocument"]
        resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]

      },
      {
        
        url: '/Supplier-DVA-Collection-CV-Purchase-Page3',
        label: "Supplier DVA Page3",
        // resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "manualForm", "uploadPdfDocument"]
        resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]

      },
      {
        
        url: '/Supplier-DVA-Collection-PV-Purchase',
        label: "Supplier DVA",
        // resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "manualForm", "uploadPdfDocument"]
        resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]

      },
      {
        
        // url: '/Supplier-DVA-Collection-PV-Purchase-Page3',
        url: '/supplier-dva-declaration-for-pli',
        label: "Supplier DVA Page3",
        // resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "manualForm", "uploadPdfDocument"]
        resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]

      }, 
      {
        // component: SupplierImportDetails,
        url: '/supplier_import_details-cv',
        label: "Supplier Import Details",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "manualForm", "uploadPdfDocument"]
      },
      {
        // component: SupplierImportDetails,
        url: '/annual_financial_statement',
        label: "Annual Financial Statement",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "manualForm", "uploadPdfDocument"]
      }
      ,
      {
        
        url: '/mpfd-supplier',
        label: "MPFD",
        // resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "manualForm", "uploadPdfDocument"]
        resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]

      },
      {
        url: '/invoice-upload',
        label: "Invoice Upload",
        resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      },
      {
        url: '/user-manual',
        label: "User Manuals and General Guidelines",
        resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      },
    ]
  },

  {
    rolePLI: "GDC",
    pageDetails: [
      {
        url: '/milestones-calendar-cv',
        label: "Milestone Calendar",
        resourceAccess: ['create-project']
      }, 
      {
        url: '/milestones-calendar-pv',
        label: "Milestone Calendar",
        resourceAccess: ['create-project']

      },
      {
        url: '/milestones-calendar-ev',
        label: "Milestone Calendar",
        resourceAccess: ["create-project"]
      },
      {
        url: '/vcbc-list-cv',
        label: "Mass VC Declaration",
        resourceAccess: ['create-project']
      },
      {
        url: '/vcbc-list-pv',
        label: "Mass VC Declaration",
        resourceAccess: ['create-project']
      },
      {
        url: '/vcbc-list-ev',
        label: "Mass VC Declaration",
        resourceAccess: ['create-project']
      },
      {
        url: '/vcbom-data',
        label: "VC BoM Data",
        resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]

      },

      {
        url: '/vcbom-data-pv',
        label: "VC BoM Data",
        resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]

      },
      {
        url: '/vcbom-data-ev',
        label: "VC BoM Data",
        resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]

      },


      {
        url: '/vendor-certificate',
        label: "Vendor DVA Declaration",
        resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]

      },
      {
        url: '/vendor-certificate-pv',
        label: "Vendor DVA Declaration",
        resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]

      }, 

    ]
  }
  ,
  {
    rolePLI: "Product-line",
    pageDetails: [
      {
        url: '/bom-validation',
        label: "BOM Validation",
        resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
      },
      // {
      //   url: '/milestones-calendar-cv',
      //   label: "Milestone Calendar",
      //   resourceAccess: ['create-project']  
      // }, 
      // {
      //   url: '/milestones-calendar-pv',
      //   label: "Milestone Calendar",
      //   resourceAccess: ['create-project']

      // },
      // {
      //   url: '/milestones-calendar-ev',
      //   label: "Milestone Calendar",
      //   resourceAccess: ["create-project"] 
      // },
      // {
      //   url: '/add-vcbc-cv',
      //   label: "VC Declaration",
      //   resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]

      // },

      // {
      //   url: '/add-vcbc-pv',
      //   label: "VC Declaration",
      //   resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]

      // },
      // {
      //   url: '/add-vcbc-ev',
      //   label: "VC Declaration",
      //   resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]

      // },
      // {
      //   url: '/vcbc-list-cv',
      //   label: "Mass VC Declaration",
      //   resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]

      // },
      // {
      //   url: '/vcbc-list-pv',
      //   label: "Mass VC Declaration",
      //   resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]

      // },
      // {
      //   url: '/vcbc-list-ev',
      //   label: "Mass VC Declaration",
      //   resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]

      // },
      // {
      //   url: '/vcbom-data',
      //   label: "VC BoM Data",
      //   resourceAccess: ["search"]

      // },

      // {
      //   url: '/vcbom-data-pv',
      //   label: "VC BoM Data",
      //   resourceAccess: ["search"]

      // },
      // {
      //   url: '/vcbom-data-ev',
      //   label: "VC BoM Data",
      //   resourceAccess: ["search"]

      // },
      // {
      //   url: '/dva-calculation-cv',
      //   label: "dva-calculation",
      //   resourceAccess: ["search"]

      // },
      // {
      //   url: '/dva-calculation-pv',
      //   label: "dva-calculation",
      //   resourceAccess: ["search"]

      // },
      // {
      //   url: '/mhi-data-cv',
      //   label: "mhi-data",
      //   resourceAccess: ["submit","create-project"]

      // },

      {
        // component: MHIDataFormation,
        url: '/mhi-data-formation',
        label: "MHI Data Formation",
        resourceAccess: ["submit","create-project"]
      },
      {
        // component: MHIDataSubmission,
        url: '/mhi-data-submission',
        label: "MHI Data Submission",
        resourceAccess: ["submit","create-project"]
      },

      // {
      //   url: '/mhi-data-pv',
      //   label: "mhi-data",
      //   resourceAccess: ["submit","create-project"]

      // },
      // {
      //   url: '/mhi-form-cv',
      //   label: "mhi-form-cv",
      //   resourceAccess: ["submit","create-project"]
      // },

    ]
  },
  /* BOE Role */
  {
    rolePLI: "BOE",
    pageDetails: [
      {
        url: '/bom-boe-data',
        label: "BOM BOE Data",
        resourceAccess: ["delete", "upload", "download", "downloadCertificate", "downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
      },
      {
        url: '/bom-boe-report',
        label: "BOM BOE Report",
        resourceAccess: ["delete", "upload", "download", "downloadCertificate", "downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
      },
      {
        url: '/boe-upload',
        label: "BOE Upload",
        resourceAccess: ["delete", "upload", "download", "downloadCertificate", "downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
      },
    ]
  },
  /* GPA Role */
  {
    rolePLI: "GPA",
    pageDetails: [
      {
        url: '/mhi-data-formation',
        label: "MHI Data",
        resourceAccess: ["delete", "upload", "download", "downloadCertificate", "downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
      },
      {
        url: '/mhi-data-submission',
        label: "MHI Data",
        resourceAccess: ["delete", "upload", "download", "downloadCertificate", "downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
      },
      {
        url: '/finance-master',
        label: "Finance Master",
        resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      },
    ]
  },

  /* Finance Role */
  {
    rolePLI: "Finance",
    pageDetails: [
      {
        url: '/finance-master',
        label: "Finance Master",
        resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      },
    ]
  },

  /* Role: Purchase */
  {
    rolePLI: "Purchase",
    pageDetails: [
      {
        url: '/annual_financial_statement',
        label: "Annual Financial Details Form",
        resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      },
      {
        url: '/AFSMIS',
        label: "AFS Report",
        resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      },
      {
        url: '/afs_master_data-pv',
        label: "AFS Master Data",
        resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      },
      {
        url: '/bom-validation',
        label: "BOM Validation",
        resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
      },
      {
        url: '/unique-part-selection',
        label: "Unique Part Selection",
        resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      }, 
      {
        // url: '/Supplier-DVA-Collection-CV-Purchase',
        url: '/supplier-dva-collection',
        label: "Supplier DVA",
        // resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "manualForm", "uploadPdfDocument"]
        resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
      },
      // {
      //   url: '/bom-master-data',
      //   label: "DVA Master Sheet Data",
      //   resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
      // },
      {
        url: '/Supplier-DVA-Collection-CV-Purchase-Page3',
        label: "Supplier DVA Page3",
        // resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "manualForm", "uploadPdfDocument"]
        resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
      },
      {
        url: '/Supplier-DVA-Collection-PV-Purchase',
        label: "Supplier DVA",
        // resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "manualForm", "uploadPdfDocument"]
        resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
      },
      // {
      //   url: '/supplier-dva-declaration-for-pli_purchase',
      //   // label: "Supplier DVA Page3",
      //   label: "Supplier Under PLI Declaration Purchase",
      //   resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
      // }, 
      {
        // component: SupplierImportDetails,
        url: '/supplier_import_details-cv',
        label: "Supplier Import Details",
        resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "manualForm", "uploadPdfDocument"]
      },




      /* Previous objects Date: 02-08-2024 */
      // Annual Financial Statment MIS Block
      // {
      //   url: '/AFSMIS',
      //   label: "AFS MIS",
      //   resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      // },
      // {
      //   url: '/AFSpurchase',
      //   label: "AFS Purchase",
      //   resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      // },
      // {
      //   url: '/annual_financial_statement',
      //   label: "Annual Financial Details Form",
      //   resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      // },
      // {
      //   url: '/afs_master_data-pv',
      //   label: "AFS Master Data",
      //   resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      // },
      // {
      //   url: '/Supplier-DVA-Collection-CV-Purchase',
      //   label: "Supplier DVA",
      //   // resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "manualForm", "uploadPdfDocument"]
      //   resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
      // },
      // {
      //   url: '/supplier-dva-report',
      //   label: "Supplier DVA Report",
      //   // resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "manualForm", "uploadPdfDocument"]
      //   resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]
      // },
      // {
        
      //   url: '/Supplier-DVA-Collection-CV-Purchase-Page3',
      //   label: "Supplier DVA Page3",
      //   // resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "manualForm", "uploadPdfDocument"]
      //   resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]

      // },
      // {
        
      //   url: '/Supplier-DVA-Collection-PV-Purchase',
      //   label: "Supplier DVA",
      //   // resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "manualForm", "uploadPdfDocument"]
      //   resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]

      // },
      // {
        
      //   url: '/Supplier-DVA-Collection-PV-Purchase-Page3',
      //   label: "Supplier DVA Page3",
      //   // resourceAccess: ["delete", "upload", "download", "uploadCertificate", "uploadAnnexture", "submit", "serach", "manualForm", "uploadPdfDocument"]
      //   resourceAccess: ["delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search", "uploadPdfDocument", "downloadAfsCertificate", "manualForm"]

      // },

      {
        url: '/supplier-pli-declaration-mis',
        label: "Supplier Under PLI Declaration MIS",
        resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      },
      {
        url: '/supplier-dva-declaration-mis',
        label: "DVA Declaration MIS",
        resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]
      },
    ]
  },
  {
    rolePLI: "Purchase-CV",
    pageDetails: [
      {
        url: '/milestones-calendar-cv',
        label: "Milestone Calendar",
        resourceAccess: ['create-project','alldata']
      }, 
      {
        url: '/Supplier-DVA-Collection-CV-Purchase',
        label: "Milestone Calendar",
        resourceAccess: ['create-project','upload']
      }, 
   
      {
        url: '/vcbc-list-cv',
        label: "Mass VC Declaration",
        resourceAccess: ['create-project','alldata']
      },
  
  
      {
        url: '/vcbom-data',
        label: "VC BoM Data",
        resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit" ,"search"]

      },
 

      {
        url: '/vendor-certificate',
        label: "Vendor DVA Declaration",
        resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure",  "search"]

      },
   

    ]
  },
  {
    rolePLI: "Purchase-PV",
    pageDetails: [
  
      {
        url: '/milestones-calendar-pv',
        label: "Milestone Calendar",
        resourceAccess: ["alldata",'create-project','alldata']

      },
      {
        url: '/Supplier-DVA-Collection-PV-Purchase',
        label: "Milestone Calendar",
        resourceAccess: ['create-project','upload']
      }, 

   
      {
        url: '/vcbc-list-pv',
        label: "Mass VC Declaration",
        resourceAccess: ['alldata','create-project','alldata']
      },
 
 

      {
        url: '/vcbom-data-pv',
        label: "VC BoM Data",
        resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]

      },
  
 
      {
        url: '/vendor-certificate-pv',
        label: "Vendor DVA Declaration",
        resourceAccess: ["alldata","delete", "upload", "download", "downloadCertificate","downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search"]

      }, 

    ]
  }

]



